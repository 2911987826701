import { AssignmentListWrapper } from '../models/assigments/assignment-list-wrapper'
import ApiResponse from '../models/api/api-response';

import {invokeApi} from '../utils/helpers/invoke-api';
import { AcademyService } from './academy-service';
import { AssignmentSubmissionAttribute } from '../models/assigments/assignment-submission-attribute';
import { AssignmentSubmissionPostData } from '../models/assigments/assignment-submission-post-data';
import { AssignmentSubmissionsListWrapper } from '../models/assigments/assignment-submissions-list-wrapper';
import { AssignmentSubmissionPostDataWrapper } from '../models/assigments/assignment-submission-post-data-wrapper';
import { BadgesListWrapper } from '../models/badges/badges-list-wrapper';


export class AssignmentService {
    private static _instance: AssignmentService;
    private constructor() {}

    public static get instance(){
        return this._instance ?? (this._instance = new AssignmentService());
    }

    public getAssignmentOfStudentForClass =  async (classId: number,studentId: number): Promise<ApiResponse<AssignmentListWrapper>> => {
        return invokeApi<null, AssignmentListWrapper>({
            route: `regular-assignments?populate=*&filters[classes]=${classId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getRegularAssignmentsOfStudent =  async (studentId: number): Promise<ApiResponse<AssignmentListWrapper>> => {
        return invokeApi<null, AssignmentListWrapper>({
            route: `regular-assignments?populate=file&filters[studentsAssignedTo]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAssignmentOfStudentAi =  async (classId: number,studentId: number): Promise<ApiResponse<getAssignmentOfStudentAiType>> => {
        return invokeApi<null, getAssignmentOfStudentAiType>({
            route: `regular-assignments?populate=technique&filters[students][id]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAssignmentSubmissionsOfStudent =  async (studentId: number): Promise<ApiResponse<AssignmentSubmissionsListWrapper>> => {
        return invokeApi<null, AssignmentSubmissionsListWrapper>({
            route: `assignment-submissions?populate[0]=fileSubmission&populate[1]=assignment&populate[2]=badges.badgeImage&filters[student]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postAssignmentSubmission = async (data: AssignmentSubmissionPostData) => {
        return invokeApi<AssignmentSubmissionPostDataWrapper, AssignmentSubmissionAttribute>({
            route: `assignment-submissions`,
            method: 'POST',
            privateRoute: true,
            data: {data},
        });
    };
    
    public getAllBadges = async (): Promise<ApiResponse<BadgesListWrapper>> => {
        return invokeApi<null, BadgesListWrapper>({
            route: `student-badges?populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    }
}