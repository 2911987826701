import React from 'react'
import Layout from '../../components/common/layout'
import DragCloseDrawer from '../../components/common/drag-close-drawer'
import FitnessTestsPageViewModel from '../../view-models/fitness-test-viewmodel'
import FullScreenLoader from '../../components/common/full-sreen-loader'
import { getFormattedDate } from '../../../utils/helpers/helpers'

const FitnessTestsPage = () => {
    const {
        isLoading,
        fitnessTestRecords,
        isFitnessTestInfoOpen,
        setisFitnessTestInfoOpen,
        handleOpenFitnessTestInfo,
        activeFitnessTestInfoOpen,
        calculateBmiScore,
        getUnitShortName,
    } = FitnessTestsPageViewModel();

    return (
        <Layout isProfileSwitchTabVisible={true}>
            {isLoading && <FullScreenLoader/>}
            <span className="sm:text-4xl text-2xl font-light sm:mt-1 ml-2 md:hidden">
                Fitness Tests
            </span>
            <hr className='my-2 border-2 shadow-md rounded-xl mx-2 mb-4 md:hidden'/>
            <div className='flex flex-col gap-4 px-2'>
                {fitnessTestRecords?.map(test => (
                    <div 
                        className='w-full rounded shadow-sm shadow-gray-300 p-2 py-3 bg-slate-50 flex items-center justify-between'
                    >
                        <div>
                            <div className='text-xl font-semibold'>{test?.attributes?.testInfo?.data?.attributes?.template?.data?.attributes?.name}</div>
                            <div className='text-sm text-slate-600'>Conducted on {getFormattedDate(test?.attributes?.createdAt)}</div>
                        </div>
                        <div 
                            className=' p-2 active:scale-95 transition-all'
                            onClick={() => handleOpenFitnessTestInfo(test?.id)}
                        >
                            <img 
                                src='/assets/images/expand.png'
                                alt='expand'
                                className='w-6 h-6 opacity-50 cursor-pointer'
                            />
                        </div>
                    </div>
                ))}
            </div>

            <DragCloseDrawer
                open={isFitnessTestInfoOpen}
                setOpen={setisFitnessTestInfoOpen}
            >
                <div className='text-center font-semibold text-white text-2xl'>{activeFitnessTestInfoOpen?.attributes?.testInfo?.data?.attributes?.template?.data?.attributes?.name}</div>
                <div className='text-center text-slate-500 text-sm'>Conducted on {getFormattedDate(activeFitnessTestInfoOpen?.attributes?.createdAt ?? '')}</div>
                <hr className='border-2 border-slate-800 my-2'/>
                {activeFitnessTestInfoOpen?.attributes?.testResults?.find(result => result?.label === 'Weight') &&
                activeFitnessTestInfoOpen?.attributes?.testResults?.find(result => result?.label === 'Height') && (
                    <div className='w-full rounded-lg grid grid-cols-2 p-4 bg-slate-700 font-semibold text-xl text-gray-300'>
                        <div className='flex flex-col items-center border-r border-slate-800 '>
                            <div>Height: {activeFitnessTestInfoOpen?.attributes?.testResults?.find(result => result?.label === 'Height')?.value} m</div>
                            <div>Weight: {activeFitnessTestInfoOpen?.attributes?.testResults?.find(result => result?.label === 'Weight')?.value} kg</div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <div>BMI</div>
                            <div>
                                {calculateBmiScore(
                                    activeFitnessTestInfoOpen?.attributes?.testResults?.find(result => result?.label === 'Height')?.value ?? 0,
                                    activeFitnessTestInfoOpen?.attributes?.testResults?.find(result => result?.label === 'Weight')?.value ?? 0
                                )} kg/m2
                            </div>
                        </div>
                    </div>
                )}
                <div className="w-full mt-4 rounded-md overflow-hidden">
                    <table className="w-full table-auto border-collapse bg-gray-200 rounded-lg shadow-md">
                        <thead className="bg-gray-800">
                        <tr>
                            <th className="p-4 text-left border-b font-semibold text-gray-300">Test</th>
                            <th className="p-4 text-left border-b font-semibold text-gray-300">Result</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activeFitnessTestInfoOpen?.attributes?.testResults
                            ?.filter(result => result?.label !== 'Height' && result?.label !== 'Weight')
                            ?.filter(result => result?.value !== 0 && result?.value !== undefined && result?.value !== null)
                            ?.map((result, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="p-4 border-b text-gray-600">{result?.label}</td>
                                <td className="p-4 border-b text-gray-600">{result?.value} {getUnitShortName(result?.unit)}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='mt-4'>
                    {activeFitnessTestInfoOpen?.attributes?.report?.data ? (
                        <a
                            href={activeFitnessTestInfoOpen?.attributes?.report?.data?.attributes?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className='py-3 mt-1 text-xl font-semibold text-white rounded-lg bg-[#3AB7FC] text-center'>
                                View Report
                            </div>
                        </a>
                        // <div>
                        //     <iframe 
                        //         src={activeFitnessTestInfoOpen?.attributes?.report?.data?.attributes?.url}
                        //         style={{
                        //             width: '100%',
                        //             height: '100vh',
                        //             border: 'none',
                        //         }}
                        //         scrolling="auto"
                        //         frameBorder="0"
                        //     >
                        //         Device does not support this feature.
                        //     </iframe>
                        // </div>
                    ) : (
                        <div className='text-center font-semibold text-white text-2xl mt-6 border-2 rounded-md py-2'>Not published yet</div>
                    )}
                </div>
            </DragCloseDrawer>
        </Layout>
    )
}

export default FitnessTestsPage