import { EnrollmentDataListWrapper } from "../models/enrollment-data/enrollment-data-list-wrapper";
import { EnrolmentPostDataWrapper } from "../models/enrollment-data/enrolment-post-data-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class StudentEnrollmentService {
    private static _instance: StudentEnrollmentService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new StudentEnrollmentService());
    }

    public postEnrolmentData = async (data: EnrolmentPostDataWrapper) => {
        return invokeApi<EnrolmentPostDataWrapper, EnrollmentDataListWrapper>({
            route: `students-enrolement-data`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public getEnrollmentsAddedByParent = async (email: string) => {
        return invokeApi<null, EnrollmentDataListWrapper>({
            route: `students-enrolement-data?filters[email]=${email}`,
            method: 'GET',
            privateRoute: true,
        });
    };

}