import React from 'react'
import { motion } from "framer-motion";
import PwaInstallPromptViewModel from '../../../view-models/pwa-install-prompt-viewmodel';


const PwaInstallPrompt = ({delay, message}: {delay: number, message?: string}) => {
    const {
        isInstallPromptVisible,
        setIsInstallPromptVisible,
        installApp,
        iosInstallPrompt,
        setIosInstallPrompt,
        isInstalledOnIos,
    } = PwaInstallPromptViewModel();

    return (
        <div>
            {isInstallPromptVisible && (
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: delay } }}
                    exit={{ opacity: 0, y: 100 }}
                    className='fixed bottom-0 left-0 md:left-auto md:w-[480px] right-0 p-2 z-[999999999999]'
                >
                    <div className='bg-orange-200 p-2 px-2 rounded-lg z-[99] shadow-2xl'>
                        <button className='flex justify-between items-center w-full' >
                            <img 
                                src='/assets/images/logo.png'
                                className='p-2 h-12'
                            />
                            <img 
                                src='/assets/images/close-box.png'
                                className='h-6 w-6 cursor-pointer'
                                onClick={() => setIsInstallPromptVisible(false)}
                            />
                        </button>
                        <div className='flex items-end justify-between p-4 gap-4'>
                            <div className='text-md text-slate-700'>
                                {message ||
                                'Get the app now'}
                            </div>
                            <button
                                className='py-[2px] cursor-pointer px-[22px] bg-yellow-400 text-lg rounded-lg font-semibold '
                                id='installApp'
                                onClick={installApp}
                            >
                                Install
                            </button>
                        </div>
                    </div>
                </motion.div>
            )}

            {(iosInstallPrompt && !isInstalledOnIos()) && 
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: delay, type: "tween" } }}
                    exit={{ opacity: 0, y: 100 }}
                    className='fixed bottom-0 left-0 w-full md:hidden right-0 z-[999999999999]'
                >
                    <div className='bg-[#1c1d20] p-2 px-4 rounded-t-xl z-[99] shadow-2xl'>
                        <div className='flex items-center justify-between'>
                            <div className='flex items-center gap-2'>
                                <img 
                                    src='/assets/icons/1024.png'
                                    className='p-2 h-14 rounded-xl'
                                />
                                <div className='text-white text-[24px] font-medium'>Install SpArts</div>
                            </div>
                            <div className='text-blue-500' onClick={() => setIosInstallPrompt(false)}>Cancel</div>
                        </div>
                        <hr className='border-[#2c2d31]'/>
                        <div className='my-3 mx-4'>
                            <div className='text-[#525356] text-[14px] font-medium text-center'>
                                {message ||
                                    'This website has app functionality. Add it to your homescreen to use it in fullscreen and while offline.'
                                }
                            </div>
                        </div>
                        <hr className='border-[#2c2d31] mx-4'/>
                        <div className='my-4 mx-2 flex flex-col gap-4'>
                            <div className='flex items-center text-[#525356] gap-4'>
                                <img 
                                    src='/assets/icons/ios-share.png'
                                    className='h-8 w-9'
                                />
                                1) Press the 'Share' button
                            </div>
                            <div className='flex items-center text-[#525356] gap-4'>
                                <img 
                                    src='/assets/icons/ios-plus.png'
                                    className='h-8 w-8 ml-[2px]'
                                />
                                2) Press 'Add to Home Screen'
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </div>
    )
}

export default PwaInstallPrompt