import React from 'react';

function StarRating({ ratings }: { ratings: number }) {
    // Calculate the number of full stars and the clip percentage for the filled star
    const fullStars = Math.floor(ratings); // Full stars (e.g., 4 for 4.3)
    const clipPercentage = (ratings % 1) * 100; // Clip percentage (e.g., 0.3 => 30%)

    const totalStars = 5; // Total number of stars

    return (
        <div className="flex justify-center items-center relative w-full h-[10%]">
            {/* Background gray stars */}
            <span className="absolute flex justify-center items-center left-1/2 transform -translate-x-1/2 mb-[5px]">
                {[...Array(totalStars)].map((_, index) => (
                    <img key={index} src='/assets/images/star-gray.png' className="h-8 w-8" alt="gray star" />
                ))}
            </span>

            {/* Foreground green stars */}
            <span className="absolute flex justify-center items-center left-1/2 transform -translate-x-1/2 mb-[5px]">
                {/* Render full stars */}
                {[...Array(fullStars)].map((_, index) => (
                    <img key={index} src='/assets/images/star-green.png' className="h-8 w-8" alt="green star" />
                ))}
                {/* Render half star with clipping based on the decimal value */}
                {clipPercentage > 0 && (
                    <img 
                        src='/assets/images/star-green.png' 
                        className="h-8 w-8" 
                        alt="partial star" 
                        style={{ clipPath: `inset(0 ${100 - clipPercentage}% 0 0)` }} // Clips the star based on the decimal value
                    />
                )}
                {/* Render remaining empty stars */}
                {[...Array(totalStars - fullStars - (clipPercentage > 0 ? 1 : 0))].map((_, index) => (
                    <img key={index + fullStars + (clipPercentage > 0 ? 1 : 0)} src='/assets/images/star-gray.png' className="h-8 w-8" alt="gray star" />
                ))}
            </span>
        </div>
    );
}

export default StarRating;
