
import ApiResponse from "../models/api/api-response";
import { SupplierDataWrapper } from "../models/supplier/supplier-data-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";


export class SupplierService {
    private static _instance: SupplierService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new SupplierService());
    }
    public getSupplierDetails = async (supplierId: number): Promise<ApiResponse<SupplierDataWrapper>> => {
        return invokeApi<null, SupplierDataWrapper>({
            route: `supplier-details/${supplierId}?populate=cashfreeCredentials`,
            method: 'GET',
            privateRoute: true,
        });
    }

}