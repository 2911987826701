import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { StudentService } from '../../services/student-service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { STUDENT_PROFILE_DATA_QUERY } from '../../utils/constants/globals';
import { UploadService } from '../../services/upload-service';
import { StudentPostDataWrapper } from '../../models/student/student-post-data-wrapper';
import { StudentData } from '../../models/student/student-data';

export interface StudentFormDataType {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  school: string;
  grade: string;
  board: string;
  photoId: number | null;
  photoUrl: string;  
}


const ProfilePageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const profile = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const location = useLocation();
    const { studentId } = location.state || {};

    const {
        isSuccess: isStudentFetchSuccess,
        isLoading: isStudentFetching,
        data: studentData,
    } = useQuery({
        queryKey: [STUDENT_PROFILE_DATA_QUERY],
        queryFn: () => StudentService.instance.getStudentData(studentId ?? 0),
        refetchOnWindowFocus: false,
        enabled: studentId && studentId > 0,
    });

    const initialFormState = {
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      school: '',
      grade: '',
      board: '',
      photoId: null,
      photoUrl: '', 
    }

    const [isEditModeOn, setIsEditModeOn] = React.useState(false);
    const [studentFormData,setStudentFormData] = React.useState<StudentFormDataType>(initialFormState);

    useEffect(() => {
      if(studentData?.data?.data) {
        setFormData();
      }
    },[studentData])

    const setFormData = () => {
      setStudentFormData({
        firstName: studentData?.data?.data?.attributes?.firstName ?? '',
        middleName: studentData?.data?.data?.attributes?.middleName ?? '',
        lastName: studentData?.data?.data?.attributes?.lastName ?? '',
        gender: studentData?.data?.data?.attributes?.gender ?? '',
        school: studentData?.data?.data?.attributes?.school ?? '',
        grade: studentData?.data?.data?.attributes?.grade ?? '',
        board: studentData?.data?.data?.attributes?.board ?? '',
        photoId: studentData?.data?.data?.attributes?.photo?.data?.id ?? null,
        photoUrl: studentData?.data?.data?.attributes?.photo?.data?.attributes?.url ?? '',
      })
    }

    const toggleEditMode = () => {
        setFormData();
        setIsEditModeOn(prev => !prev);
    }

    const handleInputChange = (field: string, value: string) => {
      setStudentFormData(prev => {
        return {
          ...prev,
          [field]: value
        }
      })
    }

    const postImage = async (data: FormData) => {
      try {
          const response = await UploadService.instance.uploadImage(data);
          if (response.success) {
              if (response.data && response.data[0]) {
                  const imageData = response.data[0];
                  if (imageData.url && imageData.id) {
                      setStudentFormData(prev => ({
                          ...prev,
                          photoId: imageData.id,
                          photoUrl: imageData.url
                      }));
                  }
              }
              return response.data;
          } else {
              throw new Error(response.error?.message || 'Upload failed');
          }
      } catch (error) {
          console.error(`Error in postImage: ${error}`);
          throw error;
      }
    };

    const {
        mutate: uploadImage,
        isLoading: isImagePosting,
        error: isImagePostingError,
    } = useMutation(postImage, {
        onSuccess: data => {
            console.log("image uploaded!");
        },
        onError: error => {
            alert('Failed to Upload Image! Please try again.');
        },
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
    
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to kilobytes
    
                if (fileSizeInKB > 10240) {
                    alert("Image size exceeds 10MB. Please choose a smaller file.");
                    return;
                }
            }
    
            const formData = new FormData();
    
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }
    
            const data = formData;
            uploadImage(data);
        }
    };

    const updateStudentData = async (
        data: StudentPostDataWrapper
    ): Promise<StudentData | undefined> => {
        const response = await StudentService.instance.updateStudent(studentId ?? 0, data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateStudent,
        isLoading: isStudentUpdating,
        isSuccess: isStudentUpdated,
    } = useMutation(updateStudentData, {
        onSuccess: data => {
            // alert(`Update Successfull!`);
            setIsEditModeOn(false);
            queryClient.invalidateQueries(STUDENT_PROFILE_DATA_QUERY);
        },
        onError: error => {
            alert(`Failed to update! Please try again`);
        },
    });

   const handleStudentUpdateFormSubmit = () => {
      const body: StudentPostDataWrapper = {
        data: {
          photo: studentFormData.photoId,
          school: studentFormData.school === '' ? null : studentFormData.school,
          grade: studentFormData.grade === '' ? null : studentFormData.grade,
          board: studentFormData.board === '' ? null : studentFormData.board,
        }
      }
      updateStudent(body);
   }

  return {
    isLoading: isStudentFetching || isImagePosting || isStudentUpdating,
    isStudentFetchSuccess,
    toggleEditMode,
    isEditModeOn,
    studentData: studentData?.data?.data,
    studentFormData,
    handleInputChange,
    handleFileChange,
    handleStudentUpdateFormSubmit,
  }
}

export default ProfilePageViewModel
