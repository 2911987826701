import React, { useState } from 'react';

const StarRatingInput = ({ value, onChange, disabled }: { value: number; onChange: (value: number) => void; disabled: boolean }) => {
    const [hoveredRating, setHoveredRating] = useState(0); // State for storing the hovered rating

    // Function to handle mouse over event
    const handleMouseOver = (rating: number) => {
        setHoveredRating(rating);
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
        setHoveredRating(0);
    };

    // Function to handle click event
    const handleClick = (rating: number) => {
        onChange(rating); // Update the rating
    };

    return (
        <div className="flex">
            {[1, 2, 3, 4, 5].map((star) => (
                <div
                    key={star}
                    className={`${disabled ? '' : 'cursor-pointer'} h-6 md:h-8 w-6 md:w-8 transition duration-200 text-3xl md:text-4xl ${
                        star <= (hoveredRating || value) ? 'text-yellow-500' : 'text-gray-300'
                    }`}
                    onMouseOver={() => disabled ? null : handleMouseOver(star)} // Show highlight on hover
                    onMouseLeave={handleMouseLeave} // Remove highlight on leave
                    onClick={() => disabled ? null : handleClick(star)} // Set the rating on click
                >
                    ★ 
                </div>
            ))}
        </div>
    );
};

export default StarRatingInput;
