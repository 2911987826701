import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { StudentService } from '../../services/student-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { setActiveStudentProfile, setAllClaimableProfiles, setAllClasses, setAllStudentsData } from '../../utils/redux/active-profile';
import { StudentData } from '../../models/student/student-data';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { ALL_CLASSES_OF_ALL_STUDENTS_QUERY, CLAIMABLE_STUDENTS_LIST, ENROLMENT_REQUESTS_LIST_QUERY, STUDENTS_PROFILES_QUERY } from '../../utils/constants/globals';
import { ClassService } from '../../services/class-service';
import { StudentEnrollmentService } from '../../services/student-enrolment-service';
import { useQueryParams } from '../../utils/helpers/helpers';

const SelectProfilePageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const student = useSelector((state: RootState) => state.activeProfile);
    const academyId = useQueryParams('academy');

    useEffect(() => {
      if(academyId) {
        navigate(`${Routes.enrolment}?academy=${academyId}`, { replace: true });
      }
    },[academyId])

    const {
        isSuccess: isStudentsFetchSuccess,
        isLoading: isStudentFetching,
        data: studentsData,
    } = useQuery({
        queryKey: [STUDENTS_PROFILES_QUERY],
        queryFn: () => StudentService.instance.getStudentsOfParent(userState.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
        onSuccess(data) {
          dispatch(setAllStudentsData(data?.data?.data ?? []))
        },
    });

    const {
      isSuccess: isAllClassFetchSuccess,
      isLoading: isAllClassFetching,
      data: allClasses,
    } = useQuery({
        queryKey: [ALL_CLASSES_OF_ALL_STUDENTS_QUERY],
        queryFn: () => ClassService.instance.getAllClassesOfAllStudents(studentsData?.data?.data.map(student => student.id) ?? []),
        refetchOnWindowFocus: false,
        enabled: studentsData?.data?.data && studentsData?.data?.data?.length > 0,
        onSuccess(data) {
          dispatch(setAllClasses(data?.data?.data ?? []))
        },
    });

    const {
      isSuccess: isEnrollRequestsFetchSuccess,
      isLoading: isEnrollRequestsFetching,
      data: enrollRequests,
    } = useQuery({
        queryKey: [ENROLMENT_REQUESTS_LIST_QUERY],
        queryFn: () => StudentEnrollmentService.instance.getEnrollmentsAddedByParent(userState?.user?.email ?? ''),
        refetchOnWindowFocus: false,
        enabled: (userState.user?.id ?? 0) > 0,
        onSuccess(data) {
          // console.log(data);
        },
    });

    const {
      isSuccess: isclaimableStudentsFetchSuccess,
      isLoading: isclaimableStudentsFetching,
      data: claimableStudents,
    } = useQuery({
        queryKey: [CLAIMABLE_STUDENTS_LIST, student.parentUserDetails],
        queryFn: () => StudentService.instance.getClaimableStudents(userState?.user?.email ?? '',student?.parentUserDetails?.attributes?.phoneNumber),
        refetchOnWindowFocus: false,
        enabled: userState?.user?.email !== undefined,
        onSuccess(data) {
          const claimedProfilesId = student.allStudentsData.map(student => student.id)
          dispatch(setAllClaimableProfiles(data?.data?.data.filter(student => !claimedProfilesId.includes(student.id)) ?? []))
        },
    });


    const handleSelectStudentProfile = (student: StudentData) => {
      dispatch(setActiveStudentProfile(student))
      navigate(Routes.selectClass, {replace: false});
    }

    const handleStudentViewClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, id: number) => {
      e.stopPropagation();
      navigate(Routes.Profile, {replace: false, state: {studentId: id}});
    }

  return {
    isLoading: isStudentFetching || isEnrollRequestsFetching || isAllClassFetching || isclaimableStudentsFetching,
    studentsData: studentsData?.data?.data ?? [],
    handleSelectStudentProfile,
    enrollRequests: enrollRequests?.data?.data ?? [],
    isClaimableProfilesAvailable: claimableStudents?.data && claimableStudents?.data?.data?.length > 0,
    handleStudentViewClick,
  }
}

export default SelectProfilePageViewModel
