import {NormalizedLandmark, NormalizedLandmarkList, POSE_LANDMARKS} from '@mediapipe/pose';
import {POSE_LANDMARKS_RIGHT, POSE_LANDMARKS_LEFT} from '@mediapipe/pose';
import { stopSpeech, t2s } from './t2s';
// import FitnessPageViewModel from '../../view/view-models/fitness-viewmodel';




export interface TechniqueScoresType {
    Pushup: number;
    Squats: number;
    Flamingo: number;
    Lunges: number;
    Plank: number;
    // SideLunges: number;
}

const calculateAngle = (a: any, b: any, c: any): number => {
    const radians =
        Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x);
    let angle = Math.abs((radians * 180.0) / Math.PI);
    if (angle > 180.0) {
        angle = 360 - angle;
    }
    return angle;
};

function calculateDistance(
    point1: NormalizedLandmark,
    point2: NormalizedLandmark,
) {
    return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
    );
}



// export const calculatePushUpCount = (
//     landmarks: NormalizedLandmarkList,
//     isDown: boolean,
//     setIsDown: React.Dispatch<React.SetStateAction<boolean>>,
//     setTechniqueScores: React.Dispatch<
//         React.SetStateAction<TechniqueScoresType>
//     >,
//     pushupScore:number
// ) => {
//     // const {techniqueScores} = FitnessPageViewModel()
//     console.log("check puahup")

//     const shoulder = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER];
//     const elbow = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ELBOW];
//     const wrist = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_WRIST];

//     const angle = calculateAngle(shoulder, elbow, wrist);

//     // console.log(angle)
//     const hip = landmarks[POSE_LANDMARKS.RIGHT_HIP];
//     // const shoulder = landmarks[POSE_LANDMARKS.RIGHT_SHOULDER];
//     const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];

//     const hipToShoulderVector = {
//         x: shoulder.x - hip.x,
//         y: shoulder.y - hip.y,
//     };

//     const hipToKneeVector = {
//         x: knee.x - hip.x,
//         y: knee.y - hip.y,
//     };

//     // Calculate dot product
//     const dotProduct =
//         hipToShoulderVector.x * hipToKneeVector.x +
//         hipToShoulderVector.y * hipToKneeVector.y;

//     // Calculate magnitudes
//     const hipToShoulderMagnitude = Math.sqrt(
//         hipToShoulderVector.x ** 2 + hipToShoulderVector.y ** 2,
//     );
//     const hipToKneeMagnitude = Math.sqrt(
//         hipToKneeVector.x ** 2 + hipToKneeVector.y ** 2,
//     );

//     // Calculate angle in radians
//     let angle2 = Math.acos(
//         dotProduct / (hipToShoulderMagnitude * hipToKneeMagnitude),
//     );
//     angle2 = angle2 * (180 / Math.PI); 

//     console.log(angle2)

//     if (angle < 130 && angle2 >160 && angle2 <200) {
//         // Arm angle is less than 90 degrees
//         if (!isDown) {
//             setIsDown(true); // Set to down position
//         }
//     } else if (angle > 160 && angle2 >160 && angle2 <200) {
//         // Arm angle is greater than 160 degrees
//         if (isDown) {
//             setIsDown(false); // Set to up position 
//             setTechniqueScores(prevScores => ({
//                 ...prevScores,
//                 Pushup: prevScores.Pushup + 1, // Increment pushup count 
//             }));
//             t2s(`Pushup done ${pushupScore +1}`, 1)
//         }
//     }
// };




// export const calculatePushUpCount = (
//     landmarks: NormalizedLandmarkList,
//     isDown: boolean,
//     setIsDown: React.Dispatch<React.SetStateAction<boolean>>,
//     setTechniqueScores: React.Dispatch<
//         React.SetStateAction<TechniqueScoresType>
//     >,
//     pushupScore: number
// ) => {
//     console.log("Calculating pushup...");

//     const shoulder = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER];
//     const elbow = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ELBOW];
//     const wrist = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_WRIST];

//     const angle = calculateAngle(shoulder, elbow, wrist);

//     const hip = landmarks[POSE_LANDMARKS.RIGHT_HIP];
//     const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];

//     const hipToShoulderVector = {
//         x: shoulder.x - hip.x,
//         y: shoulder.y - hip.y,
//     };

//     const hipToKneeVector = {
//         x: knee.x - hip.x,
//         y: knee.y - hip.y,
//     };

//     // Calculate dot product
//     const dotProduct =
//         hipToShoulderVector.x * hipToKneeVector.x +
//         hipToShoulderVector.y * hipToKneeVector.y;

//     // Calculate magnitudes
//     const hipToShoulderMagnitude = Math.sqrt(
//         hipToShoulderVector.x ** 2 + hipToShoulderVector.y ** 2
//     );
//     const hipToKneeMagnitude = Math.sqrt(
//         hipToKneeVector.x ** 2 + hipToKneeVector.y ** 2
//     );

//     // Calculate angle in radians
//     let angle2 = Math.acos(
//         dotProduct / (hipToShoulderMagnitude * hipToKneeMagnitude)
//     );
//     angle2 = angle2 * (180 / Math.PI);

//     console.log(`Elbow angle: ${angle}`);
//     console.log(`Hip-Knee-Shoulder angle: ${angle2}`);

//     if (angle < 130 && angle2 > 160 && angle2 < 200) {
//         // Arm angle is less than 130 degrees and hip-knee-shoulder angle is between 160 and 200 degrees
//         if (!isDown) {
//             setIsDown(true); // Set to down position
//         }
//     } else if (angle > 160 && angle2 > 160 && angle2 < 200) {
//         // Arm angle is greater than 160 degrees and hip-knee-shoulder angle is between 160 and 200 degrees
//         if (isDown) {
//             setIsDown(false); // Set to up position
//             setTechniqueScores(prevScores => ({
//                 ...prevScores,
//                 Pushup: prevScores.Pushup + 1, // Increment pushup count
//             }));
//             t2s(`Pushup done ${pushupScore + 1}`, 1);
//         }
//     }
// };




export const calculatePushUpCount = (
    landmarks: NormalizedLandmarkList,
    isDown: boolean,
    setIsDown: React.Dispatch<React.SetStateAction<boolean>>,
    setTechniqueScores: React.Dispatch<
        React.SetStateAction<TechniqueScoresType>
    >,
    pushupScore: number
) => {

    const shoulder = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER];
    const elbow = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ELBOW];
    const wrist = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_WRIST];

    const elbowAngle = calculateAngle(shoulder, elbow, wrist);

    const hip = landmarks[POSE_LANDMARKS.RIGHT_HIP];
    const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];

    const hipToShoulderVector = {
        x: shoulder.x - hip.x,
        y: shoulder.y - hip.y,
    };

    const hipToKneeVector = {
        x: knee.x - hip.x,
        y: knee.y - hip.y,
    };

    const dotProduct =
        hipToShoulderVector.x * hipToKneeVector.x +
        hipToShoulderVector.y * hipToKneeVector.y;

    const hipToShoulderMagnitude = Math.sqrt(
        hipToShoulderVector.x ** 2 + hipToShoulderVector.y ** 2
    );
    const hipToKneeMagnitude = Math.sqrt(
        hipToKneeVector.x ** 2 + hipToKneeVector.y ** 2
    );

    let hipKneeShoulderAngle = Math.acos(
        dotProduct / (hipToShoulderMagnitude * hipToKneeMagnitude)
    );
    hipKneeShoulderAngle = hipKneeShoulderAngle * (180 / Math.PI);

    // Checking if the elbow angle indicates a down position (< 130 degrees)
    if (elbowAngle < 130 && hipKneeShoulderAngle > 160 && hipKneeShoulderAngle < 200) {
        if (!isDown) {
            setIsDown(true); // Set to down position
        }
    } 
    // Checking if the elbow angle indicates an up position (> 160 degrees)
    else if (elbowAngle > 160 && hipKneeShoulderAngle > 160 && hipKneeShoulderAngle < 200) {
        if (isDown) {
            setIsDown(false); // Set to up position
            setTechniqueScores(prevScores => ({
                ...prevScores,
                Pushup: prevScores.Pushup + 1, // Increment pushup count 
            }));
            t2s(`Pushup done ${pushupScore + 1}`, 1);
        }
    }
    // else {
    //     console.log('correct your position');
    //     t2s(`correct your position`, 1);
    // }
};


export const calculateSquatCount = (
    landmarks: NormalizedLandmarkList,
    isDown: boolean,
    setIsDown: React.Dispatch<React.SetStateAction<boolean>>,
    setTechniqueScores: React.Dispatch<
        React.SetStateAction<TechniqueScoresType>
    >,
    Score: number
) => {
    const hip = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_HIP];
    const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];
    const ankle = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ANKLE];

    const l_hip = landmarks[POSE_LANDMARKS_LEFT.LEFT_HIP];
    const l_knee = landmarks[POSE_LANDMARKS_LEFT.LEFT_KNEE];
    const l_ankle = landmarks[POSE_LANDMARKS_LEFT.LEFT_ANKLE];

    const angle = calculateAngle(hip, knee, ankle);
    const angle2 = calculateAngle(l_hip, l_knee, l_ankle);

    const hipsAligned = Math.abs(hip.x - l_hip.x) < 0.05 && Math.abs(hip.y - l_hip.y) < 0.05;
    const kneesAligned = Math.abs(knee.x - l_knee.x) < 0.05 && Math.abs(knee.y - l_knee.y) < 0.05;
    const anklesAligned = Math.abs(ankle.x - l_ankle.x) < 0.05 && Math.abs(ankle.y - l_ankle.y) < 0.05;

    if (angle < 90 && angle2 < 90 && hipsAligned && kneesAligned && anklesAligned) {
        if (!isDown) {
            setIsDown(true);
        }
    } else if (angle > 160 && angle2 > 160 && hipsAligned && kneesAligned && anklesAligned) {
        if (isDown) {
            setIsDown(false);
            setTechniqueScores(prevScores => ({
                ...prevScores,
                Squats: prevScores.Squats + 1,
            }));
            t2s(`Squats done ${Score + 1}`, 1);
        }
    }
};

export const calculateFlamingoBalanceCount = (
    landmarks: NormalizedLandmarkList,
    isBalancing: boolean,
    setIsBalancing: React.Dispatch<React.SetStateAction<boolean>>,
    setTechniqueScores: React.Dispatch<React.SetStateAction<TechniqueScoresType>>,
    balanceScore: number,
    techniqueTiming: number | null
) => {
    // Define key landmarks for the Flamingo Balance Test
    const hip = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_HIP];
    const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];
    const ankle = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ANKLE];
    const otherFoot = landmarks[POSE_LANDMARKS_LEFT.LEFT_ANKLE];

    // Calculate the angle of the balancing leg (hip-knee-ankle)
    const balanceLegAngle = calculateAngle(hip, knee, ankle);

    // Calculate the distance between the balancing ankle and the other foot
    const distanceToOtherFoot = Math.sqrt(
        (otherFoot.x - ankle.x) ** 2 + (otherFoot.y - ankle.y) ** 2
    );
    
    // Define thresholds: 
    // - The leg should be relatively straight to be considered balanced.
    // - The other foot should be at a reasonable distance (indicating it's raised).

    if (balanceLegAngle > 150 && distanceToOtherFoot > 0.1) {
        if (!isBalancing) {
            setIsBalancing(true);
        }
    } else if (balanceLegAngle < 150 || distanceToOtherFoot < 0.1) {
        if (isBalancing) {
            setIsBalancing(false);
            if (techniqueTiming && techniqueTiming > 10 && balanceScore > 2) {
                t2s(`Balance lost many times`);
            }
            setTechniqueScores(prevScores => ({
                ...prevScores,
                Flamingo: prevScores.Flamingo + 1, 
            }));
            t2s(`Balance loss ${balanceScore + 1}`, 1); 
        }
    } else {
        t2s(`Maintain balance`, 1);
    }
};




// export const calculateSquatCount = (
//     landmarks: NormalizedLandmarkList,
//     isDown: boolean,
//     setIsDown: React.Dispatch<React.SetStateAction<boolean>>,
//     setTechniqueScores: React.Dispatch<
//         React.SetStateAction<TechniqueScoresType>
//     >,
//     Score:number
// ) => {
//     const hip = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_HIP];
//     const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];
//     const ankle = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ANKLE];

//     const l_hip = landmarks[POSE_LANDMARKS_LEFT.LEFT_HIP];
//     const l_knee = landmarks[POSE_LANDMARKS_LEFT.LEFT_KNEE];
//     const l_ankle = landmarks[POSE_LANDMARKS_LEFT.LEFT_ANKLE];

//     const angle = calculateAngle(hip, knee, ankle);
//     console.log(angle);

//     const angle2 = calculateAngle(l_hip, l_knee, l_ankle);
//     console.log(angle);


//     if (angle < 100 && angle2 < 100 && Math.abs(ankle.x - l_ankle.x) < 0.03) {
//         if (!isDown) {
//             setIsDown(true);
//         }
//     } else if (angle > 170 && angle2 > 170) {
//         if (isDown) {
//             setIsDown(false);
//             setTechniqueScores(prevScores => ({
//                 ...prevScores,
//                 Squats: prevScores.Squats + 1,
//             }));
//             t2s(`Squats done ${Score +1}`, 1)
//         }
//     }
// };





// export const calculatePlankTime = (
//     landmarks: NormalizedLandmarkList,
//     isPlanking: boolean,
//     setIsPlanking: React.Dispatch<React.SetStateAction<boolean>>,
//     setTechniqueScores: React.Dispatch<React.SetStateAction<TechniqueScoresType>>,
//     totalPlankTimeRef: React.MutableRefObject<number>, // Mutable ref to keep track of total plank time,
//     timeReaming:number
// ) => {
//     const hip = landmarks[POSE_LANDMARKS.RIGHT_HIP];
//     const shoulder = landmarks[POSE_LANDMARKS.RIGHT_SHOULDER];
//     const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];

//     const hipToShoulderVector = {
//         x: shoulder.x - hip.x,
//         y: shoulder.y - hip.y,
//     };

//     const hipToKneeVector = {
//         x: knee.x - hip.x,
//         y: knee.y - hip.y,
//     };

//     // Calculate dot product
//     const dotProduct =
//         hipToShoulderVector.x * hipToKneeVector.x +
//         hipToShoulderVector.y * hipToKneeVector.y;

//     // Calculate magnitudes
//     const hipToShoulderMagnitude = Math.sqrt(
//         hipToShoulderVector.x ** 2 + hipToShoulderVector.y ** 2,
//     );
//     const hipToKneeMagnitude = Math.sqrt(
//         hipToKneeVector.x ** 2 + hipToKneeVector.y ** 2,
//     );

//     // Calculate angle in radians
//     let angle = Math.acos(
//         dotProduct / (hipToShoulderMagnitude * hipToKneeMagnitude),
//     );
//     angle = angle * (180 / Math.PI); // Convert radians to degrees

//     const currentTime = Date.now(); // Get the current time in milliseconds
//     if (timeReaming=== 10){
//         t2s("last 10 second remaining", 1)
//     }
//     if (timeReaming <= 9) {
//         stopSpeech()
//     }

//     if (angle < 160 || angle > 200) {
//         if (!isPlanking) {
//             setIsPlanking(true); // Set state indicating plank position is held
//             totalPlankTimeRef.current = currentTime; // Record the start time
//         }
//     } else {
//         if (isPlanking) {
//             setIsPlanking(false); // Set state indicating plank position is not held
//             const plankDuration = (currentTime - totalPlankTimeRef.current) / 1000; // Calculate plank duration in seconds
//             totalPlankTimeRef.current = 0; // Reset the start time
//             setTechniqueScores(prevScores => ({
//                 ...prevScores,
//                 Plank: prevScores.Plank + plankDuration, // Add plank duration to total plank time
//             }));
            
            
//         }
//     }
// };


function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export const calculatePlankTime = (
    landmarks: NormalizedLandmarkList,
    isPlanking: boolean,
    setIsPlanking: React.Dispatch<React.SetStateAction<boolean>>,
    setTechniqueScores: React.Dispatch<React.SetStateAction<TechniqueScoresType>>,
    totalPlankTimeRef: React.MutableRefObject<number>, // Mutable ref to keep track of total plank time
    timeRemaining: number
) => {
    const shoulder = landmarks[POSE_LANDMARKS.RIGHT_SHOULDER];
    const elbow = landmarks[POSE_LANDMARKS.RIGHT_ELBOW];
    const wrist = landmarks[POSE_LANDMARKS.RIGHT_WRIST];
    const hip = landmarks[POSE_LANDMARKS.RIGHT_HIP];
    const knee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];

    const angle1 = calculateAngle(shoulder, elbow, wrist);
    const angle2 = calculateAngle(shoulder, hip, knee);

    console.log(`Angle1 (Elbow-Wrist): ${angle1}`);
    console.log(`Angle2 (Hip-Knee): ${angle2}`);

    const currentTime = performance.now(); //  Date.now();  Get the current time in millisecond
    if (timeRemaining === 10  && isIOS()) {
        t2s("Last 10 seconds remaining", 1);
    }
    if (timeRemaining < 9) {
        stopSpeech();
    }

    if (angle1 > 80 && angle1 < 100 && angle2 > 170 && angle2 < 190) {
        if (!isPlanking) {
            setIsPlanking(true); // Set state indicating plank position is held
            totalPlankTimeRef.current = currentTime; // Record the start time
        }
    } else {
        if (isPlanking) {
            setIsPlanking(false); // Set state indicating plank position is not held
            const plankDuration = (currentTime - totalPlankTimeRef.current) / 1000; // Calculate plank duration in seconds
            totalPlankTimeRef.current = 0; // Reset the start time
            setTechniqueScores(prevScores => ({
                ...prevScores,
                Plank: prevScores.Plank + plankDuration, // Add plank duration to plank total time 
            }));
        }
    }
};




// export const calculateLungeCount = (
//     landmarks: NormalizedLandmarkList,
//     isDown: boolean,
//     setIsDown: React.Dispatch<React.SetStateAction<boolean>>,
//     setTechniqueScores: React.Dispatch<
//         React.SetStateAction<TechniqueScoresType>
//     >,
//     Score: number
// ) => {
//     const rightHip = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_HIP];
//     const rightKnee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];
//     const rightAnkle = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ANKLE];
//     const leftHip = landmarks[POSE_LANDMARKS_LEFT.LEFT_HIP];
//     const leftKnee = landmarks[POSE_LANDMARKS_LEFT.LEFT_KNEE];
//     const leftAnkle = landmarks[POSE_LANDMARKS_LEFT.LEFT_ANKLE];

//     const rightKneeAngle = calculateAngle(rightHip, rightKnee, rightAnkle);
//     const leftKneeAngle = calculateAngle(leftHip, leftKnee, leftAnkle);
//     const rightHipAngle = calculateAngle(
//         landmarks[POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER],
//         rightHip,
//         rightKnee
//     );
//     const leftHipAngle = calculateAngle(
//         landmarks[POSE_LANDMARKS_LEFT.LEFT_SHOULDER],
//         leftHip,
//         leftKnee
//     );

//     const ankle_dist = Math.abs(leftAnkle.x -rightAnkle.x)

//     const kneeeAngleThres = 120;
//     const minHipAngle = 150;
//     const maxHipAngle = 180;

//     const rightLunge =
//         rightKneeAngle < kneeeAngleThres &&
//         rightHipAngle >= minHipAngle &&
//         rightHipAngle <= maxHipAngle &&
//         ankle_dist > .2  && 
//         (rightKnee.y >= (rightHip.y -.2) && rightKnee.y <= rightHip.y + .2) ;
//     const leftLunge =
//         leftKneeAngle < kneeeAngleThres &&
//         leftHipAngle >= minHipAngle &&
//         leftHipAngle <= maxHipAngle&&
//         ankle_dist > .2 && 
//         (leftKnee.y >= (leftHip.y -.2) && leftKnee.y <= leftHip.y + .2) ;

//     console.log({
//         rightKneeAngle: rightKneeAngle,
//         rightHipAngle: rightHipAngle,
//         leftKneeAngle: leftKneeAngle,
//         leftHipAngle: leftHipAngle,
//         kneeeAngleThres: kneeeAngleThres,
//         minHipAngle: minHipAngle,
//         maxHipAngle: maxHipAngle
//     });

//     if (rightLunge || leftLunge) {
//         if (!isDown) {
//             setIsDown(true); 
//         }
//     } else {
//         if (isDown) {
//             setIsDown(false); // Set to up position
//             setTechniqueScores(prevScores => ({
//                 ...prevScores,
//                 Lunges: prevScores.Lunges + 1
//             }));
//             t2s(`Lunges done ${Score + 1}`, 1);
//         }
//     }
// };


export const calculateLungeCount = (
    landmarks: NormalizedLandmarkList,
    isDown: boolean,
    setIsDown: React.Dispatch<React.SetStateAction<boolean>>,
    setTechniqueScores: React.Dispatch<
        React.SetStateAction<TechniqueScoresType>
    >,
    Score: number
) => {
    const rightHip = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_HIP];
    const rightKnee = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_KNEE];
    const rightAnkle = landmarks[POSE_LANDMARKS_RIGHT.RIGHT_ANKLE];
    const leftHip = landmarks[POSE_LANDMARKS_LEFT.LEFT_HIP];
    const leftKnee = landmarks[POSE_LANDMARKS_LEFT.LEFT_KNEE];
    const leftAnkle = landmarks[POSE_LANDMARKS_LEFT.LEFT_ANKLE];

    const rightKneeAngle = calculateAngle(rightHip, rightKnee, rightAnkle);
    const leftKneeAngle = calculateAngle(leftHip, leftKnee, leftAnkle);
    const rightHipAngle = calculateAngle(
        landmarks[POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER],
        rightHip,
        rightKnee
    );
    const leftHipAngle = calculateAngle(
        landmarks[POSE_LANDMARKS_LEFT.LEFT_SHOULDER],
        leftHip,
        leftKnee
    );

    const ankle_dist = Math.abs(leftAnkle.x - rightAnkle.x);
    const kneeAngleThreshold = 120;
    const minHipAngle = 150;
    const maxHipAngle = 180;
    const minAnkleDistance = 0.2; // Adjust based on the expected distance

    const rightLunge =
        rightKneeAngle < kneeAngleThreshold &&
        rightHipAngle >= minHipAngle &&
        rightHipAngle <= maxHipAngle &&
        ankle_dist > minAnkleDistance &&
        rightKnee.y >= rightHip.y - 0.2 &&
        rightKnee.y <= rightHip.y + 0.2 &&
        rightAnkle.y > rightKnee.y;

    const leftLunge =
        leftKneeAngle < kneeAngleThreshold &&
        leftHipAngle >= minHipAngle &&
        leftHipAngle <= maxHipAngle &&
        ankle_dist > minAnkleDistance &&
        leftKnee.y >= leftHip.y - 0.2 &&
        leftKnee.y <= leftHip.y + 0.2 &&
        leftAnkle.y > leftKnee.y;

    console.log({
        rightKneeAngle: rightKneeAngle,
        rightHipAngle: rightHipAngle,
        leftKneeAngle: leftKneeAngle,
        leftHipAngle: leftHipAngle,
        kneeAngleThreshold: kneeAngleThreshold,
        minHipAngle: minHipAngle,
        maxHipAngle: maxHipAngle,
        ankle_dist: ankle_dist
    });

    if (rightLunge || leftLunge) {
        if (!isDown) {
            setIsDown(true);
        }
    } else {
        if (isDown) {
            setIsDown(false);
            setTechniqueScores(prevScores => ({
                ...prevScores,
                Lunges: prevScores.Lunges + 1
            }));
            t2s(`Lunges done ${Score + 1}`, 1);
        }
    }
};


