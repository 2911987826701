import React from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useNavigate } from 'react-router-dom';
import { FeeRemindersSent } from '../../models/fee/fee-reminders-sent';
import { UtilityService } from '../../services/utility-service';
import { SupplierService } from '../../services/supplier-service';

export interface TransactionData {
    id: number;
    fee_date: Date;
    dueDate: Date;
    startDate: Date;
    accReceivedIn: string;
    amount: number;
    payment_mode: string;
    message: string;
    feeRemindersSent: FeeRemindersSent[];
    schedule: string;
    class_fees: number;
    balance: number;
}

const FeeTransactionsPageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const student = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
      isSuccess: isClassOwnerFetchSuccess,
      isLoading: isClassOwnerFetching,
      data: classOwner,
    } = useQuery({
        queryKey: [student?.activeClassData?.id],
        queryFn: () => UtilityService.instance.getOwnerOfClass(Number(student?.activeClassData?.id)),
        refetchOnWindowFocus: false,
        enabled:  (Number(student?.activeClassData?.id) ?? 0) > 0,
    });

    const {
      isSuccess: isOwnerCashfreeInfoSuccess,
      isLoading: isOwnerCashfreeInfoFetching,
      data: ownerCashfreeInfo,
    } = useQuery({
        queryKey: [classOwner],
        queryFn: () => SupplierService.instance.getSupplierDetails(classOwner?.data?.data?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled:  (classOwner?.data?.data?.id ?? 0) > 0,
    });


    const transactions: TransactionData[] = student?.activeClassFeeData?.attributes?.fee_dates?.map(item => {
        const schedule =student?.activeClassFeeData?.attributes?.payment_schedule;
        const class_fees =student?.activeClassFeeData?.attributes?.amount;
        const balance =student?.activeClassFeeData?.attributes?.balance;
        return {
            ...item,
            schedule,
            class_fees,
            balance
        }
    })

  return {
    isLoading: isClassOwnerFetching || isOwnerCashfreeInfoFetching,
    transactions,
    student,
    isCashfreeAvailable: ownerCashfreeInfo?.data?.data?.attributes?.cashfreeCredentials?.status === 'ACTIVE'
  }
}

export default FeeTransactionsPageViewModel
