import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ACADEMY_FEATURED_QUERY, SUBSCIBED_USER_QUERY, USER_STATE } from '../../utils/constants/globals';
import { AcademyService } from '../../services/academy-service';
import { Routes } from '../navigation/routes';
import AnimateHeroSection from '../../utils/animate-hero-section';
import { LandingPageService } from '../../services/landing-page-services';
import { setActiveStudentProfile } from '../../utils/redux/active-profile';
import { ActivityFinderRequestPostData } from '../../models/activity-finder-requests/activity-finder-requests-post-data';
import { ActivityFinderRequestsDataWrapper } from '../../models/activity-finder-requests/activity-finder-requests-data-wrapper';
import { ActivityFinderResponseData } from '../../models/activity-finder-requests/activity-finder-response-attributes';
import { Swiper as SwiperClass } from 'swiper';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';

type Discipline = {
  academies:    ActivityFinderResponseData[]; 
  discipline: string;
};

const LandingPageViewModel = () => {
    AnimateHeroSection();
    const navigate = useNavigate();
    const [isUserSubscribed,setUserSubscribed] = useState<boolean>(false);
    const [isUserAlreadySubscribed,setUserAlreadySubscribed] = useState<boolean>(false);
    const [subscribeUserEmail,setSubscribeUserEmail] = useState<string>('');
    const [closedSubscribeSuccesPrompt, setClosedSubscribeSuccesPrompt] = useState<boolean>(false);
    const [isActivityFinderOpen, setIsActivityFinderOpen] = useState(false);
    const [activityFindPage, setActivityFindPage] = useState(0);
    const [recommendations, setRecommendations] = useState<Record<string, any>>({});
    const [activeFirstSlideIndex, setActiveFirstSlideIndex] = useState(0);
    const [activeSecondSlideIndex, setActiveSecondSlideIndex] = useState(0);
    const isLoggedIn = !!localStorage.getItem(USER_STATE);
    const user = useSelector((state: RootState) => state.user);
    
    const handleFirstSlideChange = (swiper:SwiperClass) => {
    setActiveFirstSlideIndex(swiper.realIndex); // Update index when slide changes
    };
    const handleSecondSlideChange = (swiper:SwiperClass) => {
    setActiveSecondSlideIndex(swiper.realIndex); // Update index when slide changes
    };

    const info = Object.values(recommendations)
    let firstdisciplines:Discipline[]=[];
    let seconddisciplines:Discipline[]=[];
    if (info) {
        firstdisciplines=Object.values(info[0]??{})
        seconddisciplines=Object.values(info[1]??{})
    }

    const [currentFirstDisciplineIndex, setCurrentFirstDisciplineIndex] = useState(0);
    const [currentSecondDisciplineIndex, setCurrentSecondDisciplineIndex] = useState(0);
    const [currentDiscipline,setCurrentDiscipline] = useState(firstdisciplines[0]);
    const [currentSecondDiscipline, setcurrentSecondDiscipline] = useState(seconddisciplines[0]);
    const [showMoreDisciplinesModal, setShowMoreDisciplinesModal] = useState<number | null>(null);

    useEffect(() => {
  // Update currentDiscipline when currentFirstDisciplineIndex changes
    setCurrentDiscipline(firstdisciplines[currentFirstDisciplineIndex]);
    }, [currentFirstDisciplineIndex, firstdisciplines]);
    
    useEffect(() => {
  // Update currentDiscipline when currentFirstDisciplineIndex changes
    setcurrentSecondDiscipline(seconddisciplines[currentSecondDisciplineIndex]);
    }, [currentSecondDisciplineIndex, firstdisciplines]);
  
  const handleShowMoreDisciplines = (index: number | null) => {
    setShowMoreDisciplinesModal(index);
  }

  // Function to go to the previous discipline
  const prevDiscipline = () => {
    setCurrentFirstDisciplineIndex((prevIndex) =>
      prevIndex === 0 ? firstdisciplines.length - 1 : prevIndex - 1
      );
  };

  // Function to go to the next discipline
  const nextDiscipline = () => {
      setCurrentFirstDisciplineIndex((prevIndex) =>
          prevIndex === firstdisciplines.length - 1 ? 0 : prevIndex + 1
    );
  };
  // Function to go to the previous discipline
  const prevSecondDiscipline = () => {
    setCurrentSecondDisciplineIndex((prevIndex) =>
      prevIndex === 0 ? seconddisciplines.length - 1 : prevIndex - 1
    );
  };

  // Function to go to the next discipline
  const nextSecondDiscipline = () => {
    setCurrentSecondDisciplineIndex((prevIndex) =>
      prevIndex === seconddisciplines.length - 1 ? 0 : prevIndex + 1
    );
    };

    const handleNavigateToSignUpPage = () => {
        navigate(Routes.SignUp)
    }

    const handleOpenActivityFinder = () => {
        setIsActivityFinderOpen(prev => !prev);
    }

    const handleReload = () => {
        setActivityFindPage(0);
    }

    const handleNextPage = () => {
        setActivityFindPage(prev => prev + 1);
    }

    const handlePreviousPage = () => {
        setActivityFindPage(prev => prev - 1);
    }

    const {
        isSuccess: isAcademiesToFeatureFetched,
        isLoading: isAcademiesToFeatureFetching,
        data: academiesToFeature,
    } = useQuery({
        queryKey: [ACADEMY_FEATURED_QUERY],
        queryFn: () => AcademyService.instance.getAllAcademiesToBeFeatured(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

  const queryClient = useQueryClient();

  const postNewSubscriber = async (
    data: {email: string},
  ): Promise<{data: {email: string}} | undefined> => {
      const response =
          await LandingPageService.instance.postNewSubscriber(data);
      if (response.success) {
          setUserSubscribed(true);
          return response.data;
      } else {
          if(response.error?.message === 'This attribute must be unique'){
            setUserAlreadySubscribed(true)
          }
          throw new Error(response.error?.message);
      }
  };

  const {
      mutate: subscibeUser,
      isLoading: isUserSubscribing,
      isSuccess: isUserSubscribeSuccess,
  } = useMutation(postNewSubscriber, {
      onSuccess: data => {
          queryClient.invalidateQueries(SUBSCIBED_USER_QUERY)
      },
      onError: error => {
          // alert('Failed to add Enrolment Form. Please try again!');
      },
  });

  const handleSubscibeUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    subscibeUser({email: subscribeUserEmail});
  }

  const handlePromptClose = () => {
    setClosedSubscribeSuccesPrompt(true)
  }

  const postActivityFinder = async (
    data: ActivityFinderRequestPostData,
  ): Promise<ActivityFinderRequestsDataWrapper | undefined> => {
      
      const response = await LandingPageService.instance.postActivityFinder(data);
        if (response.success) {
          setRecommendations((response?.data?.data?.attributes?.recommendations) || {});
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
  };

  const {
      mutate: getActivityFinderResponse,
      isLoading: isActivityFinderResponseFetching,
      isSuccess: isActivityFinderResponseSuccess,
  } = useMutation(postActivityFinder, {
      onSuccess: data => {
        //   queryClient.invalidateQueries(SUBSCIBED_USER_QUERY)
      },
      onError: error => {
          // alert('Failed to add Enrolment Form. Please try again!');
      },
  });

  const initialActivityFinderFormData = {
    gender: '',
    age: '',
    fitnessLevel: '',
    improvementArea: '',
    pincode: 0,
    user: user?.user?.id? user?.user?.id : null
  }

  const [activityFinderFormData, setActivityFinderFormData] = useState<ActivityFinderRequestPostData>(initialActivityFinderFormData);

  const handleActivityFinderInputChange = (field: string, value: string | number) => {
        setActivityFinderFormData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        })
  }

    const handleActivityFinderFormSubmit = () => {
        getActivityFinderResponse(activityFinderFormData);
    }

    return {
        isLoading: isUserSubscribing,
        academiesToFeature,
        isAcademiesToFeatureFetching,
        handleNavigateToSignUpPage,
        handleSubscibeUser,
        isUserSubscribed,
        setUserSubscribed,
        setSubscribeUserEmail,
        subscribeUserEmail,
        isUserAlreadySubscribed,
        closedSubscribeSuccesPrompt,
        handlePromptClose,
        isActivityFinderOpen,
        handleOpenActivityFinder,
        handleNextPage,
        handlePreviousPage,
        activityFindPage,
        handleReload,
        activityFinderFormData,
        handleActivityFinderInputChange,
        handleActivityFinderFormSubmit,
        recommendations,
        currentDiscipline,
        prevDiscipline,
        nextDiscipline,
        currentSecondDiscipline,
        prevSecondDiscipline,
        nextSecondDiscipline,
        activeFirstSlideIndex,
        activeSecondSlideIndex,
        handleFirstSlideChange,
        handleSecondSlideChange,
        isLoggedIn,
        handleShowMoreDisciplines,
        showMoreDisciplinesModal
    };
};

export default LandingPageViewModel;