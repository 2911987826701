import ApiResponse from "../models/api/api-response";
import { StudentFitnessRecordListWrapper } from "../models/fitness-tests/student-fitness-record-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";


export class FitnessTestsService {
    private static _instance: FitnessTestsService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new FitnessTestsService());
    }

    public getStudentFitnessRecords = async (
        studentId: number
    ): Promise<ApiResponse<StudentFitnessRecordListWrapper>> => {
        return invokeApi<null, StudentFitnessRecordListWrapper>({
            route: `student-fitness-test-records?populate[0]=testInfo.template&populate[1]=testResults&populate[2]=report&filters[student][id]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };
    
}