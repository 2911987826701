import React from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';

import { ClassService } from '../../services/class-service';
import { STUDENTS_CLASSES_QUERY } from '../../utils/constants/globals';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { setActiveClassData } from '../../utils/redux/active-profile';
import { ClassData } from '../../models/class/class-data';

const ClassSelectPageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const parentProfile = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        isSuccess: isClassesFetchSuccess,
        isLoading: isClassesFetching,
        data: classesData,
    } = useQuery({
        queryKey: [STUDENTS_CLASSES_QUERY],
        queryFn: () =>
            ClassService.instance.getClassesOfStudent(
                parentProfile.activeStudent.id,
            ),
        refetchOnWindowFocus: false,
        enabled: (parentProfile.activeStudent.id ?? 0) > 0,
    });

    const handleSelectClass = (classItem: ClassData) => {
      dispatch(setActiveClassData(classItem))
      navigate(Routes.class+`/${classItem.id}`, {replace: false});
    }

  return {
    isLoading: isClassesFetching,
    classesData: classesData?.data?.data ?? [],
    handleSelectClass,
    parentProfile
  }
}

export default ClassSelectPageViewModel
