
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/all'


const AnimateHeroSection=()=>{
    gsap.registerPlugin(useGSAP)
    gsap.registerPlugin(ScrollTrigger)
  
    useGSAP(()=>{
      gsap.to(".soccer",{
        scale:1,
        duration:2,
        opacity:1,
        ease:"sine.in",
        delay:0
    
      })
      gsap.to(".basketball",{
        right:0,
        duration:3,
        opacity:1,
        delay:1,
        ease:"sine.in"
      })
      gsap.to(".taekwondo",{
        left:0,
        duration:3,
        opacity:1,
        delay:1,
        ease:"sine.in"
      })
      gsap.to(".violin",{
        top:0,
        duration:2,
        opacity:1,
        delay:1.2,
        ease:"sine.in"
        })
        gsap.to(".kick",{
          bottom:0,
          duration:2,
          opacity:1,
          delay:1.2,
          ease:"sine.in"
        })
        gsap.to(".blob",{
          bottom:"-20%",
          right:0,
          duration:2,
          delay:2
  
        })
        gsap.to(".blue",{
          top:"-5%",
          left:0,
          duration:2,
          delay:2
        })
        gsap.from(".nav",{
          top:"-100%",
          duration:3,
        })
        gsap.to(".heading",{
          opacity:1,
          duration:4,
          ease:"sine.in",
          stagger:1,
          delay:1.1
        })
        gsap.to(".button",{
          bottom:90,
          duration:4,
          delay:0,
          ease:"sine.in"
        })
        gsap.from('.carousel',{
          left:-1000,
          duration:2,
          opacity:0,
       
          scrollTrigger:{
            trigger:'.academy',
            start: 'top center',
  
          }
        })
        gsap.from('.academy-text',{
          opacity:0,
          scale:0,
          duration:2,
          scrollTrigger:{
            trigger:'.academy',
            start:'top center'
          }
        })
        gsap.to('.testimomialtext',{
          opacity:1,
          duration:2,
          scrollTrigger:{
            trigger:'.testimonials',
            start:'top center'
          }
        })
        gsap.to('.testimonial-carousel',{
          right:0,
          duration:2,
          scrollTrigger:{
            trigger:'.testimonials',
            start:'top center'
          }
        })
        gsap.from('.news',{
          opacity:0,
          duration:2,
          scrollTrigger:{
            trigger:'.newsletter',
            start:'top center'
          }
        })
        gsap.to(".preloader",{
          top:-1000,
          duration:2,
          delay:0,
        })
    },[])
}

export default AnimateHeroSection