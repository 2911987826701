import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { UsersPostDataWrapper } from '../../models/user/users-post-data-wrapper';
import { UsersListWrapper } from '../../models/user/users-list-wrapper';
import { LoginService } from '../../services/login-service';
import { useMutation, useQuery } from 'react-query';
import { ALL_USERS_LIST, USER_ROLES_LIST } from '../../utils/constants/globals';
import { PARENT } from '../../utils/constants/user-roles';
import { User } from '../../models/user/user';
import { useDispatch } from 'react-redux';
import { setUser } from '../../utils/redux/user';
import { setJWTToken, useCurrentQueryParams } from '../../utils/helpers/helpers';
import { Routes } from '../navigation/routes';

const SignUpPageViewModel = () => {
  const navigate = useNavigate();
  const queries = useCurrentQueryParams().toString();

  const handleLoginClick = () => {
      navigate(`${Routes.Login}?${queries}`, { replace: true });
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isLoading,setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)

  const handlePasswordEyeClick = () => {
      setIsPasswordVisible(!isPasswordVisible);
  };
  const handleConfirmPasswordEyeClick = () => {
      setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const {
      isSuccess: isAllROlesFetched,
      isLoading: isAllRolesFetching,
      data: allRoles,
  } = useQuery({
      queryKey: [USER_ROLES_LIST],
      queryFn: () => LoginService.instance.getUserRoles(),
      refetchOnWindowFocus: false,
      enabled: true,
  });

  const {
    isSuccess: isAllUsersFetched,
    isLoading: isAllUsersFetching,
    data: allUsers,
  } = useQuery({
      queryKey: [ALL_USERS_LIST],
      queryFn: () => LoginService.instance.getUserNameAndEmailsList(),
      refetchOnWindowFocus: false,
      enabled: true,
  });

  const intialRegistrationFormData = {
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  }
  
  const postNewRegistrationData = async (
    data: UsersPostDataWrapper,
  ): Promise<User | undefined> => {
    const response = await LoginService.instance.postUserData(data);
    if(response.success) {
      const body = {
        userInfo: response?.data?.id ?? 0,
        firstName: registrationFormData.firstName,
        lastName: registrationFormData.lastName,
        phoneNumber: Number(registrationFormData.phoneNumber)
      }
      LoginService.instance.postParentDetails(body)
          .then(response => {
              if (response.success) {
                // LoginService.instance.userLogin(registrationFormData.userName, registrationFormData.password)
                //   .then(res => {
                //       if (res.success && res?.data?.user) {
                //           setIsLoading(false)
                //           setRegistrationFormData(intialRegistrationFormData)
                //           dispatch(setUser(res?.data?.user))
                //           setJWTToken(res.data?.jwt ?? '');
                //           navigate(Routes.SelectStudentProfile, {replace: false});
                //       } else {
                //           setIsLoading(false)
                //       }
                //   })
                //   .catch(error => {
                //     setIsLoading(false)
                //   });
                
                setIsRegisterSuccess(true)
                // setTimeout(() => {
                //     navigate(Routes.Login);
                // }, 5000)
              } else {
                  setIsLoading(false)
              }
          })
          .catch(error => {
            setIsLoading(false)
          });
      return response.data;
    } else {
      setIsLoading(false)
      throw new Error(response.error?.message);
    }
  }

  const {
    mutate: addNewUser,
    isLoading: isNewUserAdding,
    isSuccess: isNewUserAdded,
  } = useMutation(postNewRegistrationData, {
    onSuccess: data => {
      
    },
    onError: error => {
      alert('Failed to add new user. Please try again.')
    }
  })
  const [registrationFormData,setRegistrationFormData] = useState(intialRegistrationFormData)

  const handleFormInputChange = (field: string,value: string) => {
    setRegistrationFormData(prev => ({
        ...prev,
        [field]: value
    }))
  }

  const [noContactInfo,setNoContactInfo] = useState(false)

  const registerSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      
      if(registrationFormData.email === '' && registrationFormData.phoneNumber === ''){
        setNoContactInfo(true)
      }else{
          setIsLoading(true)
          await addNewUser({
            username: registrationFormData.email ? registrationFormData.email : registrationFormData.phoneNumber,
            email: registrationFormData.email ? registrationFormData.email : (`${registrationFormData.phoneNumber}@sparts.user`),
            // email: registrationFormData.email === '' ? `${registrationFormData.userName.replace(/\s/g, '')}@sparts.user` : registrationFormData.email,
            password: registrationFormData.password,
            phoneNumber: Number(registrationFormData.phoneNumber) ?? null,
            provider: 'local',
            confirmed: true,
            // confirmed: registrationFormData.email ? false : true,               automatically confirm mobile number users
            blocked: false,
            role: allRoles?.data?.roles?.filter(role => role.type === PARENT)[0].id ?? 0,
        }); 
      }
  };

  useEffect(() => {
    setNoContactInfo(false)
  },[registrationFormData.email,registrationFormData.phoneNumber])

  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (isRegisterSuccess) {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        const timeout = setTimeout(() => {
            clearInterval(timer);
            navigate(`${Routes.Login}?${queries}`, { replace: true });
        }, 3000);

        return () => {
            clearInterval(timer);
            clearTimeout(timeout);
        };
    }
}, [isRegisterSuccess, navigate]);

  return {
      isLoading: isLoading,
      countdown,
      isRegisterSuccess,
      setIsRegisterSuccess,
      handleLoginClick,
      isPasswordVisible,
      isConfirmPasswordVisible,
      handlePasswordEyeClick,
      handleConfirmPasswordEyeClick,
      registrationFormData,
      handleFormInputChange,
      registerSubmit,
      isUserNameTaken: allUsers?.data?.some(item => item.username.toLowerCase() === registrationFormData.userName.toLowerCase()),
      isEmailTaken: allUsers?.data?.some(item => item.email.toLowerCase() === registrationFormData.email.toLowerCase()),
      isPasswordMatching: registrationFormData.password === registrationFormData.confirmPassword || registrationFormData.confirmPassword === '',
      noContactInfo,
      isPhoneNumberTaken : allUsers?.data?.some(item => item.username === registrationFormData.phoneNumber)
  };
}

export default SignUpPageViewModel