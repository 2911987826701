export const testimonialsData:ITestimonial[]=[
    {
        img:"",
        title:"Vishruti Mishra",
        subtitle: "Mother of Aaryav(10 years old)",
        summary:"Must say the swimming classes are being conducted very nicely. The coach is really patient with the kids. There is a perfect balance between strictness and friendliness that a coach needs to have with the kids. My son is a complete beginner in swimming and now after just a few classes I am seeing that confidence in him when he is in the pool. Thank you Team SpArts for the wonderful classes."
    },{
        img: `/assets/images/testimotial-three.jpeg`,
        title:"Meghna Bhujwala",
        subtitle:"Mother of Aadhya(7 years old)",
        summary:"Aadhya has been attending swimming with SpArts and is completely in love with water now. This was the first time she tried swimming and has no fear of apprehension of water. The coach is patient and skilled and is able to help kids navigate through their fear of water."
    },{
        img: `/assets/images/testimotial-four.jpeg`,
        title:"Swati Somani",
        subtitle: "Mother of Advik(7 years old)",
        summary:"Advik is enjoying both Arts & Badminton classes. You guys are doing a great job."
    },{
        img:"",
        title:"Preethi",
        subtitle:"Mother of Praneetha(7 years old)",
        summary:"We are happy with how SpArts program is conducted. A combination of Sports and Arts is a great idea and kudos to Mayur and Daisy in coming up with this. They are doing a wonderful job in ensuring every kid in the class is given equal attention in respective programs"
    },{
        img:`/assets/images/testimotial-one.jpeg`,
        title:"Dipak Kumar",
        subtitle:"Father of Arsh(10 years old)",
        summary:"We are really happy with the swimming classes and Arsh is enjoying a lot. Thanks to the coach and your dedication"
    },{
        img:`/assets/images/testimotial-two.webp`,
        title:"Deepti Singh",
        subtitle:"Mother of Vriddhi(9 years old)",
        summary:"SpArts has given a platform to my daughter towards more creativity, more risk taking and more experimenting. They have set up kids friendly environment where.my daughter is very softly exploring avenues where her head doesn't go. Her own style is encouraged. Looking forward for more"
    }
]

interface ITestimonial {
    title: string;
    subtitle:string;
    summary: string;
    img: string;
};