import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';

import { ALL_STUDENT_BADGES_LIST, ASSIGNMENT_SUBMISSIONS_OF_STUDENT, ASSIGNMENTS_OF_ACTIVE_STUDENT_FOR_CLASS, REGULAR_ASSIGNMENTS_OF_STUDENT } from '../../utils/constants/globals';
import { AssignmentService } from '../../services/assignment-services';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { getJWTToken } from '../../utils/helpers/helpers';
import axios from 'axios';
import { UploadService } from '../../services/upload-service';
import { AssignmentSubmissionPostData } from '../../models/assigments/assignment-submission-post-data';
import { AssignmentSubmissionAttribute } from '../../models/assigments/assignment-submission-attribute';
import { BRONZE, GOLD, PUNCTUAL_PRO, SILVER } from '../../utils/constants/badges';

interface Result {
  Tips: string[];
  Score: string;
  Comments: string[];
  Findings: string[];
  Student_Image: string;
  Instructor_Image: string;
}

interface TechniqueResult {
  id: number;
  attributes: {
      email: string;
      name: string;
      techniqueName: string;
      timestamp: string;
      results: {
          data: Result[];
      };
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
  };
}

interface ResponseData {
  data: TechniqueResult[];
  meta: {
      pagination: {
          page: number;
          pageSize: number;
          pageCount: number;
          total: number;
      };
  };
}

interface AssignmentSubmissionData {
  fileId: number;
  fileUrl: string;
  fileType: string;
  text: string;
}

const AssignmentPageViewModel = () => {
  const student = useSelector((state: RootState) => state.activeProfile);

  // const {
  //   isSuccess: isAssignmentDetailsFetchSuccess,
  //   isLoading: isAssignmentDetailsFetching,
  //   data: assignmentData,
  // } = useQuery({
  //   queryKey: [ASSIGNMENTS_OF_ACTIVE_STUDENT_FOR_CLASS],
  //   queryFn: () => AssignmentService.instance.getAssignmentOfStudentForClass(student.activeClassData.id, student.activeStudent.id),
  //   refetchOnWindowFocus: false,
  //   enabled: true,
  // });

  // const {
  //   isSuccess: isAssignmentStudentDetailsFetchSuccess,
  //   isLoading: isAssignmentStudentDetailsFetching,
  //   data: assignmentStudentData,
  // } = useQuery({
  //   queryKey: [ASSIGNMENTS_OF_ACTIVE_STUDENT_FOR_CLASS, student.activeStudent.id],
  //   queryFn: () => AssignmentService.instance.getAssignmentOfStudentAi(student.activeClassData.id, student.activeStudent.id),
  //   refetchOnWindowFocus: false,
  //   enabled: true,
  // });

  // const [uniqueCount, setUniqueCount] = useState<number>(0);

  // useEffect(() => {
  //   if (assignmentData && assignmentStudentData) {
  //     const assignmentIds = assignmentData?.data?.data.map(item => item.id);
  //     const studentAssignmentIds = assignmentStudentData?.data?.data.map(item => item.id);

  //     if (assignmentIds && studentAssignmentIds) {
  //       const combinedIds = [...assignmentIds, ...studentAssignmentIds];
  //       const uniqueIds = new Set(combinedIds);
  //       setUniqueCount(uniqueIds.size);
  //     }
  //   }
  //   else if (assignmentData && assignmentData?.data?.data) {
  //     setUniqueCount(assignmentData?.data?.data.length)
  //   }
  //   else if (assignmentStudentData && assignmentStudentData?.data?.data) {
  //     setUniqueCount(assignmentStudentData?.data?.data.length)
  //   }
  // }, [assignmentData, assignmentStudentData]);

  // const [techniqueResults, setTechniqueResults] = useState<TechniqueResult[]>([]);
  // const [loading, setLoading] = useState<boolean>(true);

  // useEffect(() => {
  //     const fetchTechniqueResults = async () => {
  //         try {
  //             const response = await axios.get<ResponseData>(
  //                 `https://strapiqa.sparts.app/api/technique-results?filters[student][id]=${student.activeStudent.id}`,
  //                 {
  //                     headers: {
  //                         Authorization: `Bearer ${getJWTToken()}`,                        },
  //                 }
  //             );
  //             setTechniqueResults(response.data.data);
  //         } catch (error) {
  //             console.error('Error fetching data:', error);
  //         } finally {
  //             setLoading(false);
  //         }
  //     };

  //     fetchTechniqueResults();
  // }, [student]);

//   useEffect(() => {
//     if (assignmentData && assignmentStudentData) {
//         const combinedData = [
//             ...assignmentData.map(item => ({ ...item, source: "assignmentData" })),
//             ...assignmentStudentData
//                 .filter(e => e.attributes.type === "Ai")
//                 .map(item => ({ ...item, source: "assignmentStudentData" }))
//         ];
//         const sortedData = combinedData.sort((a, b) => {
//             return new Date(b.attributes.createdAt).getTime() - new Date(a.attributes.createdAt).getTime();
//         });

//         setsortedData(sortedData)
//     }
//     else if (assignmentData) {
//         setsortedData(assignmentData)
//     }
//     else if (assignmentStudentData) {
//         setsortedData(assignmentStudentData)
//     }

// }, [assignmentData, assignmentStudentData])

  const {
    isSuccess: isRegularAssignmentsFetchSuccess,
    isLoading: isRegularAssignmentsFetching,
    data: regularAssignments,
  } = useQuery({
    queryKey: [REGULAR_ASSIGNMENTS_OF_STUDENT, student.activeStudent.id],
    queryFn: () => AssignmentService.instance.getRegularAssignmentsOfStudent(student.activeStudent.id),
    refetchOnWindowFocus: true,
    enabled: true,
  });

  const {
    isSuccess: isAssignmentSubmissionsFetchSuccess,
    isLoading: isAssignmentSubmissionsFetching,
    data: assignmentSubmissionsList,
  } = useQuery({
    queryKey: [ASSIGNMENT_SUBMISSIONS_OF_STUDENT, student.activeStudent.id],
    queryFn: () => AssignmentService.instance.getAssignmentSubmissionsOfStudent(student.activeStudent.id),
    refetchOnWindowFocus: true,
    enabled: true,
  });

  const {
    isLoading: isAllBadgesFetching,
    data: allBadges,
  } = useQuery({
    queryKey: [ALL_STUDENT_BADGES_LIST],
    queryFn: () => AssignmentService.instance.getAllBadges(),
    refetchOnWindowFocus: true,
    enabled: true,
  });

  const initialAssignmentSubmissionData = {
    fileId: 0,
    fileUrl: '',
    fileType: '',
    text: ''
  }

  const [isSubmitFormOpen, setIsSubmitFormOpen] = useState<boolean>(false);
  const [assignmentSubmissionData, setAssignmentSubmissionData] = useState<AssignmentSubmissionData>(initialAssignmentSubmissionData)
  const queryClient = useQueryClient();
  
  function scrollToTop(delay = 0) {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        // behavior: 'instant',
      });
    }, delay);
  }

  const handleTextSubmissionInpuChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAssignmentSubmissionData(prev => ({
      ...prev,
      text: e.target.value
    }))
  }

  const postFile = async (data: FormData) => {
    try {
        const response = await UploadService.instance.uploadImage(data);
        if (response.success) {
            if (response.data && response.data[0]) {
                const fileData = response.data[0];
                if (fileData.url && fileData.id) {
                    setAssignmentSubmissionData(prev => ({
                        ...prev,
                        fileId: fileData.id,
                        fileUrl: fileData.url,
                        fileType: fileData.mime
                    }));
                }
            }
            return response.data;
        } else {
            throw new Error(response.error?.message || 'Upload failed');
        }
    } catch (error) {
        console.error(`Error in postFile: ${error}`);
        throw error;
    }
  };

  const {
      mutate: uploadFile,
      isLoading: isFileUploading,
      error: isFileUploadError,
  } = useMutation(postFile, {
      onSuccess: data => {
          console.log("File uploaded!");
      },
      onError: error => {
          alert('Failed to Upload File! Please try again.');
      },
  });

  const handleSubmissionFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
            const fileSizeInBytes = selectedFiles[i].size;
            const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to kilobytes

            if (fileSizeInKB > 10240) {
                alert("File size exceeds 10MB. Please choose a smaller file.");
                return;
            }
        }

        const formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append(`files`, selectedFiles[i]);
        }

        const data = formData;
        uploadFile(data);
    }
};

  const handleToggleSubmitForm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    e.stopPropagation()
    scrollToTop(100);
    if(assignmentSubmissionsList?.data?.data.some(submission => submission?.attributes?.assignment?.data?.id === id)){
      const data = assignmentSubmissionsList?.data?.data?.find(submission => submission?.attributes?.assignment?.data?.id === id);
      setAssignmentSubmissionData({
        fileId: data?.attributes?.fileSubmission?.data?.id || 0,
        fileUrl: data?.attributes?.fileSubmission?.data?.attributes?.url || '',
        text: data?.attributes?.textSubmission || '',
        fileType: data?.attributes?.fileSubmission?.data?.attributes?.mime || ''
      })
    }else{
      setAssignmentSubmissionData(initialAssignmentSubmissionData)
    }
    setSubmissionFormModalOpenItemId(id);
    if(id === 0){
      setIsSubmitFormOpen(false);
    }else{
      setIsSubmitFormOpen(true);
    }
  };

  const useMediaQuery = (query: string): boolean => {
      // Initialize state with the result of the media query
      const [matches, setMatches] = useState<boolean>(window.matchMedia(query).matches);

      useEffect(() => {
          // Create a MediaQueryList object
          const mediaQueryList = window.matchMedia(query);
          // Define a function to update the state when the media query matches
          const handleChange = () => setMatches(mediaQueryList.matches);

          // Add an event listener to the media query list
          mediaQueryList.addEventListener('change', handleChange);
          // Clean up the event listener on component unmount
          return () => mediaQueryList.removeEventListener('change', handleChange);
      }, [query]); // Re-run the effect if the query changes

      // Return the current match status
      return matches;
  };

  const [moreInfoModalOpenItemId, setMoreInfoModalOpenItemId] = useState<number>(0);
  const [submissionFormModalOpenItemId, setSubmissionFormModalOpenItemId] = useState<number>(0);
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if(!isMoreInfoModalOpen){
      setMoreInfoModalOpenItemId(0)
    }
  },[isMoreInfoModalOpen])

  const handleOpenMoreInfo = (id: number) => {
    setIsMoreInfoModalOpen(true)
    setMoreInfoModalOpenItemId(id);
  }

  const handleDownloadFile = (url: string, name: string) => {
    const link = document.createElement('a');
    link.href = url;
    const fileName = `Assignment-${name}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const postAssignmentSubmission = async (
      data: AssignmentSubmissionPostData,
  ): Promise<AssignmentSubmissionAttribute | undefined> => {
      const response =
          await AssignmentService.instance.postAssignmentSubmission(data);
      if (response.success) {
          return response.data;
      } else {
          throw new Error(response.error?.message);
      }
  };

  const {
      mutate: submitAssignment,
      isLoading: isAssignmentSubmissionLoading,
      isSuccess: isAssignmentSubmited,
  } = useMutation(postAssignmentSubmission, {
      onSuccess: data => {
          queryClient.invalidateQueries(ASSIGNMENT_SUBMISSIONS_OF_STUDENT)
          // setAssignmentSubmissionData(initialAssignmentSubmissionData);
      },
      onError: error => {
          alert('Failed to submit assignment. Please try again!');
      },
  });

  const handleAssignmentSubmission = () => {
    const body: AssignmentSubmissionPostData = {
      assignment: submissionFormModalOpenItemId,
      student: student.activeStudent.id,
      textSubmission: assignmentSubmissionData?.text === '' ? null : assignmentSubmissionData?.text,
      fileSubmission: assignmentSubmissionData?.fileId === 0 ? null : assignmentSubmissionData?.fileId,
    }
    if(assignmentSubmissionsList?.data?.data.length === 4){
      body.badges = [allBadges?.data?.data?.find(b => b?.attributes?.identifier === BRONZE)?.id ?? 0,allBadges?.data?.data?.find(b => b?.attributes?.identifier === PUNCTUAL_PRO)?.id ?? 0]
    }
    if(assignmentSubmissionsList?.data?.data.length === 6){
      body.badges = [allBadges?.data?.data?.find(b => b?.attributes?.identifier === SILVER)?.id ?? 0]
    }
    if(assignmentSubmissionsList?.data?.data.length === 9){
      body.badges = [allBadges?.data?.data?.find(b => b?.attributes?.identifier === GOLD)?.id ?? 0]
    }
    submitAssignment(body)
  }

  const [activeBadgeInfo, setActiveBadgeInfo] = useState<number>(0);

  const showBadgeInfo = (badgeId: number) => {
    setActiveBadgeInfo(badgeId);
  }

  return {
    isLoading: isRegularAssignmentsFetching || isFileUploading || isAssignmentSubmissionLoading || isAssignmentSubmissionsFetching || isAllBadgesFetching,
    regularAssignments: regularAssignments?.data?.data ?? [],
    assignmentSubmissions: assignmentSubmissionsList?.data?.data ?? [],
    student,
    isSubmitFormOpen,
    handleToggleSubmitForm,
    useMediaQuery,
    handleOpenMoreInfo,
    moreInfoModalOpenItemId,
    isMoreInfoModalOpen,
    setIsMoreInfoModalOpen,
    activeMoreInfoModalItem: regularAssignments?.data?.data?.find(item => item.id === moreInfoModalOpenItemId),
    activeSubmissionFormModalItem: regularAssignments?.data?.data?.find(item => item.id === submissionFormModalOpenItemId),
    handleDownloadFile,
    assignmentSubmissionData,
    handleTextSubmissionInpuChange,
    handleSubmissionFileUpload,
    allBadges: allBadges?.data?.data ?? [],
    handleAssignmentSubmission,
    showBadgeInfo,
    activeBadgeInfo,
  }
}

export default AssignmentPageViewModel