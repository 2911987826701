import React from 'react'
import FullScreenVideoPlayer from '../../components/common/full-screen-video-player'
import ShowcaseVideoPageViewModel from '../../view-models/showcase-video-page-viewmodel';

const ShowCaseVideoPage = () => {
    const {
        videoUrl,
        handleCloseShowcaseVideo,
    } = ShowcaseVideoPageViewModel();

    return (
        <div>
            <FullScreenVideoPlayer
                isOpen={true}
                handleClose={handleCloseShowcaseVideo}
                video={videoUrl}
            />
        </div>
    )
}

export default ShowCaseVideoPage