import { HomePageSettingsDataWrapper } from "../models/home-page-settings/home-page-settings-data-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class HomePageService {
    private static _instance: HomePageService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new HomePageService());
    }

    public getHomePageSettings = async () => {
        return invokeApi<null, HomePageSettingsDataWrapper>({
            route: `home-screen-setting?populate[0]=featured.image&populate[1]=showcaseVideos.video&populate[2]=preFooter.background`,
            method: 'GET',
            privateRoute: false,
        });
    };
}