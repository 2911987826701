import ApiResponse from "../models/api/api-response";
import { ClassDetailsWrapper } from "../models/class/class-details-wrapper";
import { ClassListWrapper } from "../models/class/class-list-wrapper";
import { CashfreeOrderData } from "../models/fee-structure/cashfree-order-data";
import { CreateFeeOrderPostData } from "../models/fee-structure/create-fee-order-post-data";
import { CreateHashPostData } from "../models/fee-structure/create-hash-post-data.";
import { FeeStructureListWrapper } from "../models/fee-structure/fee-structure-list-wrapper";
import { FeePaymentListWrapper } from "../models/fee/fee-payment-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class FeeService {
    private static _instance: FeeService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new FeeService());
    }
    public getFeeTransactionForClass = async (classId: number,studentId: number): Promise<ApiResponse<FeePaymentListWrapper>> => {
        return invokeApi<null, FeePaymentListWrapper>({
            route: `fee-collections?populate=*&filters[student][id]=${studentId}&filters[class][id]=${classId}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getTrainingFeeStructureOfClass = async (classId: number): Promise<ApiResponse<FeeStructureListWrapper>> => {
        return invokeApi<null, FeeStructureListWrapper>({
            route: `fee-structures?populate=*&filters[type]=Training&filters[classes][id]=${classId}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public createHash = async (data:CreateHashPostData) => {
        return invokeApi<{ data: CreateHashPostData },{data:string}>({
            route: `payu/create-hash`,
            method: 'POST',
            privateRoute: true,
            data: {data}
        })
    }

    // public createFeeOrder = async (data: CreateFeeOrderPostData): Promise<ApiResponse<{data: CashfreeOrderData}>> => {
    //     return invokeApi<{data: CreateFeeOrderPostData}, { data: CashfreeOrderData}>({
    //         route: `payu/create-order`,
    //         method: 'POST',
    //         privateRoute: true,
    //         data: {data}
    //     });
    // }

    public createFeeOrder = async (data: CreateFeeOrderPostData): Promise<ApiResponse<{data: string}>> => {
        return invokeApi<{data: CreateFeeOrderPostData}, { data: string}>({
            route: `payu/create-order`,
            method: 'POST',
            privateRoute: true,
            data: {data}
        });
    }

    public getOrderDetails = async (orderId: string,classId: number): Promise<ApiResponse<{data: PayuTransactionDetails}>> => {
        return invokeApi<null, {data: PayuTransactionDetails}>({
            route: `payu/verify-order/${orderId}/${classId}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    // public getOrderDetails = async (orderId: string,classId: number): Promise<ApiResponse<{data: CashfreeOrderDetails}>> => {
    //     return invokeApi<null, {data: CashfreeOrderDetails}>({
    //         route: `cashfree/verify-order/${orderId}/${classId}`,
    //         method: 'GET',
    //         privateRoute: true,
    //     });
    // }
}