import React from 'react'
import Layout from '../../components/common/layout'
import ProfilePageViewModel from '../../view-models/profile-page-viewmodel';
import { BOARD_OPTIONS, GRADE_OPTIONS } from '../enrolment-form-page/data';
import FullScreenLoader from '../../components/common/full-sreen-loader';

const ProfilePage = () => {
  const {
    isLoading,
    toggleEditMode,
    isEditModeOn,
    isStudentFetchSuccess,
    studentData,
    handleInputChange,
    studentFormData,
    handleStudentUpdateFormSubmit,
    handleFileChange,
  } = ProfilePageViewModel();

  return (
    <Layout isProfileSwitchTabVisible={false} noPadding={true}>
        {isLoading && <FullScreenLoader />}
        <div className='bg-[#e6cd8f] bg-gradient-to-bl from-[#F9CA54] h-[160px] w-full'></div>
        {isStudentFetchSuccess && (
            <div className='md:w-[600px] w-full mx-auto'>
                <div className='flex items-center justify-between translate-y-[-50%] px-4'>
                    <div className='relative h-28 w-28 rounded-full overflow-hidden'>
                        {isEditModeOn && 
                            <div className={`h-full w-full absolute ${studentFormData?.photoId ? 'opacity-0' : 'opacity-100'} rounded-full bg-slate-400 flex items-center justify-center cursor-pointer`}>
                                <img 
                                    src='/assets/images/upload.png'
                                    className='h-10 w-10'
                                />
                                <input 
                                    type='file'
                                    onChange={handleFileChange}
                                    className='absolute h-full w-full opacity-0 cursor-pointer rounded-full'
                                />
                            </div>}
                        {studentFormData?.photoId ? 
                            <img 
                                src={studentFormData?.photoUrl}
                                className='h-full w-full border-2 rounded-full shadow-md border-gray-200 overflow-hidden object-contain bg-white'
                            />
                            :
                            <div className='flex h-full w-full rounded-full items-center justify-center font-semibold text-gray-400 bg-white border-2 border-gray-200'>
                                No Photo
                            </div>
                        }
                    </div>
                    <div 
                        className={`rounded-[30px] px-4 py-2 font-semibold ${isEditModeOn ? 'bg-red-300 border-red-200' : 'bg-gray-200 border-white'} text-lg flex items-center gap-2 border-2 cursor-pointer active:scale-95 transition-all`}
                        onClick={toggleEditMode}
                    >
                        <img 
                            src={isEditModeOn ? '/assets/images/cross.png' : '/assets/images/edit.png'}
                            className={`h-4 w-4`}
                        />
                        {isEditModeOn ? 'Cancel' : 'Edit'}
                    </div>
                </div>
                <div className='p-4 translate-y-[-50px]'>
                    <div className='text-3xl font-semibold'>{studentFormData?.firstName + ' ' + studentFormData?.middleName + ' ' + studentFormData?.lastName}</div>
                    <div className='text-sm text-slate-600 font-semibold'>S/o Parent Name</div>
                    <div className='border-2 border-slate-50 shadow-inner shadow-gray-300 rounded-lg p-4 mt-2'>
                        <div className='text-lg font-semibold'>Academic Details</div>
                        <hr className='mb-2 border-black'/>
                        <div className='flex items-center gap-6 justify-between my-1'>
                            <div className='text-lg text-slate-600 font-semibold'>School</div>
                            <input 
                                type='text'
                                className='w-[60%] h-8 border-0 shadow-inner rounded-md py-1 outline-0 ring-0 focus:ring-0 font-semibold bg-white disabled:bg-slate-100 text-gray-500 text-right pr-2'
                                disabled={!isEditModeOn}
                                value={studentFormData?.school}
                                onChange={(e) => handleInputChange('school', e.target.value)}
                            />
                        </div>
                        <div className='flex items-center gap-6 justify-between my-1'>
                            <div className='text-lg text-slate-600 font-semibold'>Board</div>
                            <select 
                                className='w-[60%] h-8 border-0 shadow-inner rounded-md py-1 outline-0 ring-0 focus:ring-0 font-semibold bg-white disabled:bg-slate-100 text-gray-500 text-right pr-2'
                                disabled={!isEditModeOn}
                                value={studentFormData?.board}
                                onChange={(e) => handleInputChange('board', e.target.value)}
                            >
                                <option value=""></option>
                                {BOARD_OPTIONS.map(item => (
                                    <option value={item.value} key={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='flex items-center gap-6 justify-between my-1'>
                            <div className='text-lg text-slate-600 font-semibold'>Grade</div>
                            <select 
                                className='w-[60%] h-8 border-0 shadow-inner rounded-md py-1 outline-0 ring-0 focus:ring-0 font-semibold bg-white disabled:bg-slate-100 text-gray-500 text-right pr-2'
                                disabled={!isEditModeOn}
                                value={studentFormData?.grade}
                                onChange={(e) => handleInputChange('grade', e.target.value)}
                            >
                                <option value=""></option>
                                {GRADE_OPTIONS.map(item => (
                                    <option value={item.value} key={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    {isEditModeOn && 
                        <div className='flex items-center justify-center'>
                            <div 
                                className='px-4 py-2 bg-[#3AB7FC] text-white text-center text-lg font-semibold rounded-lg w-[140px] active:scale-95 transition-all'
                                onClick={handleStudentUpdateFormSubmit}
                            >
                                Save
                            </div>
                        </div>
                    }
                </div>
            </div>
        )}
    </Layout>
  )
}

export default ProfilePage