import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../utils/redux/user';
import { Routes } from '../../../navigation/routes';
import { User } from '../../../../models/user/user';
import { getJWTToken, loadUserState, useCurrentQueryParams } from '../../../../utils/helpers/helpers';

interface AuthWrapperProps {
  children: ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queries = useCurrentQueryParams().toString();

  useEffect(() => {
    const token = getJWTToken();
    const userState: User | null = loadUserState();

    if (token && userState) {
      dispatch(setUser(userState));
    } else {
      navigate(`${Routes.Login}?${queries}`, { replace: true });
    }
  }, [dispatch, navigate]);

  return <>{children}</>;
};

export default AuthWrapper;
