import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useNavigate } from 'react-router-dom';
import { CLAIMABLE_STUDENTS_LIST, PARENT_DETAILS_QUERY } from '../../utils/constants/globals';
import { StudentService } from '../../services/student-service';
import { setAllClaimableProfiles, setParentDetailsData } from '../../utils/redux/active-profile';
import { ParentService } from '../../services/parent-service';
import { ParentDetailsData } from '../../models/parent-detail/parent-detail-data';
import { Routes } from '../navigation/routes';

const ProfileClaimViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const student = useSelector((state: RootState) => state.activeProfile);
    const [isLoading,setIsLoading] = useState<boolean>(false)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedStudentsId, setSelectedStudentsId] = useState<number[]>([])

    const {
        isSuccess: isParentDetailsFetchSuccess,
        isLoading: isParentDetailsFetching,
        data: parentDetails,
    } = useQuery({
        queryKey: [PARENT_DETAILS_QUERY],
        queryFn: () => ParentService.instance.getParentUserDetails(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
        onSuccess(data) {
          dispatch(setParentDetailsData(data?.data?.data[0] ?? {} as ParentDetailsData))
        },
    });

    const {
        isSuccess: isclaimableStudentsFetchSuccess,
        isLoading: isclaimableStudentsFetching,
        data: claimableStudents,
    } = useQuery({
        queryKey: [CLAIMABLE_STUDENTS_LIST, student.parentUserDetails],
        queryFn: () => StudentService.instance.getClaimableStudents(userState?.user?.email ?? '',student?.parentUserDetails?.attributes?.phoneNumber),
        refetchOnWindowFocus: false,
        enabled: userState?.user?.email !== undefined,
        onSuccess(data) {
          const claimedProfilesId = student.allStudentsData.map(student => student.id)
          dispatch(setAllClaimableProfiles(data?.data?.data.filter(student => !claimedProfilesId.includes(student.id)) ?? []))
        },
    });

    const handleClaimStudentSubmit = () => {
      setIsLoading(true);
    
      const promises = selectedStudentsId.map(id => {
        const reqBody = {
          data: {
            parentLinkedTo: userState.user?.id,
            parentEmailId: userState.user?.email ?? null,
            parentContactNo: parentDetails?.data?.data[0]?.attributes?.phoneNumber ?? null,
          },
        };
    
        return StudentService.instance.updateStudent(id, reqBody);
      });
    
      Promise.all(promises)
        .then(responses => {
          setIsLoading(false);
          // Alert success if all updates are successful
          alert('All students claimed successfully!');
          navigate(Routes.SelectStudentProfile, { replace: true });
          // queryClient.invalidateQueries(USER_QUERY)
        })
        .catch(error => {
          setIsLoading(false);
          console.error(error);
          // Handle the error as needed, e.g., show an error message to the user
          alert('An error occurred while claiming students.');
        });
    };    


  return {
    isLoading: isLoading || isParentDetailsFetching || isclaimableStudentsFetching,
    claimableStudents: student.allClaimableProfiles,
    handleClaimStudentSubmit,
    selectedStudentsId,
    setSelectedStudentsId
  }
}

export default ProfileClaimViewModel
