import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import { User } from '../../models/user/user';
import { loadUserState, saveUserState } from '../helpers/helpers';
// import { UserDetailsData } from '../../models/user-details/user-details-data';

const userState = {
    user: loadUserState(),
    // userDetails: {} as UserDetailsData,
};

const userSlice = createSlice({
    name: 'user',
    initialState: userState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
            saveUserState(action.payload);
        },
        // setUserDetails: (state, action: PayloadAction<UserDetailsData>) => {
        //     state.userDetails = action.payload;
        // },
    },
});



export const {setUser} = userSlice.actions;
// export const {setUserDetails} = userSlice.actions;
export default userSlice.reducer;
