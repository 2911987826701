import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { LoginService } from '../../services/login-service';
import { getJWTToken, loadUserState, setJWTToken, useCurrentQueryParams } from '../../utils/helpers/helpers';
import { Routes } from '../navigation/routes';
import { setUser } from '../../utils/redux/user';
import { useDispatch } from 'react-redux';


export interface UserLoginFormDataType {
    user: string,
    password: string
}

const SignInViewModel = () => {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const [errorText,setErrorText] = useState('');
    const [isPasswordVisible,setIsPasswordVisible] = useState(false)
    const queries = useCurrentQueryParams().toString();
    const handleSignUpClick = () => {navigate(`${Routes.SignUp}?${queries}`, { replace: true })};

    useEffect(() => {
      // Check if JWT token and user state exist in local storage
      const token = getJWTToken();
      const userState = loadUserState();
      
      if (token && userState) {
          // If both exist, redirect to the desired page
          dispatch(setUser(userState));
          navigate(`${Routes.SelectStudentProfile}?${queries}`, { replace: true });
      }
    }, [dispatch, navigate]);

    const initialUserLoginFormData = {
      user: '',
      password: ''
    }

    const handlePasswordEyeClick = () => {
      setIsPasswordVisible(prev => !prev)
    }

    const [userLoginFormData,setUserLoginFormData] = useState<UserLoginFormDataType>(initialUserLoginFormData)

    const handleInputChange = (field: string, value: string) => {
      setUserLoginFormData(prev => {
        return {
          ...prev,
          [field]: value
        }
      })
    }

    const handleUserLogin = (e: React.FormEvent) => {
      e.preventDefault();

      setIsLoading(true)
      LoginService.instance.userLogin(userLoginFormData.user, userLoginFormData.password)
          .then(async(response) => {
              if (response.success && response?.data?.user) {
                setIsLoading(true)
                  await LoginService.instance.getUserRole(response?.data?.user?.id ?? 0).then(async(res) => {
                    if (res.success && (res.data?.role.type === 'parent')) {
                        // setIsAcademyUser(true)
                        dispatch(setUser(response?.data?.user ?? null))
                        setJWTToken(response.data?.jwt ?? '');
                        navigate(`${Routes.SelectStudentProfile}?${queries}`, { replace: true });
                        setIsLoading(false)
                    }else{
                        setErrorText("Only parent users can login.")
                        setIsLoading(false)
                    }
                  })
              } else {
                  setIsLoading(false)
                  setErrorText(response.error.message)
              }
          })
          .catch(error => {
            setErrorText(error.message)
            setIsLoading(false)
          });
    }
    
  return {
    isLoading: isLoading,
    handleSignUpClick,
    userLoginFormData,
    handleInputChange,
    handleUserLogin,
    errorText,
    isPasswordVisible,
    handlePasswordEyeClick
  }
}

export default SignInViewModel