import React from 'react'
import { AssignmentAttribute } from '../../../../models/assigments/assignment-attribute';
import { getFormattedDate } from '../../../../utils/helpers/helpers';
import { BadgesData } from '../../../../models/badges/badges-data';

type Props = {
  submissionType: string | null;
  onSubmitClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  openMoreInfo: () => void;
  attributes: AssignmentAttribute;
  isSelectedForSubmission: boolean;
  isSubmitted: boolean;
  badges: BadgesData[];
  showBadgeInfo: (badgeId: number) => void;
}

const AssignmentListItem = ({
  submissionType,
  onSubmitClick,
  openMoreInfo,
  attributes,
  isSelectedForSubmission,
  isSubmitted,
  badges,
  showBadgeInfo,
}: Props) => {
  return (
    <div className='w-full rounded-lg shadow-md shado shadow-slate-200 p-2 px-4' onClick={openMoreInfo}>
      {submissionType &&
        <div className='flex items-center justify-between'>
          <div className='bg-[#39B5F9] rounded-lg h-12 w-12 p-1'>
            <img 
              src={submissionType === 'TEXT' ? '/assets/images/text.png' 
                  : submissionType === 'VIDEO' ? '/assets/images/video.png' 
                  : submissionType === 'AUDIO' ? '/assets/images/audio.png' 
                  : submissionType === 'IMAGE' ? '/assets/images/image.png' 
                  : submissionType === 'DOCUMENT' ? '/assets/images/document.png' 
                  : '/assets/images/image.png'
              }
              className='h-full w-full'
            />
          </div>
          {isSelectedForSubmission ? (
            <div onClick={(e) => e.stopPropagation()} className='flex items-center gap-4 border bg-black border-gray-200 shadow-sm rounded-lg p-1 px-2 active:scale-110 transition-all'>
              <div 
                className='text-sm font-semibold text-white'
              >
                Selected
              </div>
            </div>
          ) : isSubmitted ? (
            <div onClick={onSubmitClick} className='flex items-center gap-4 border bg-green-500 border-gray-200 shadow-sm rounded-lg p-1 px-2 active:scale-110 transition-all'>
              <div 
                className='text-sm font-semibold text-white'
              >
                Submitted
              </div>
            </div>
          ) : (
            <div onClick={onSubmitClick} className='flex items-center gap-4 border border-gray-200 shadow-sm rounded-lg p-1 px-2 active:scale-110 transition-all'>
              <img
                src='/assets/images/submit.png'
                className='h-6 w-6'
              />
              <div 
                className='text-sm font-semibold'
              >
                Submit
              </div>
            </div>
          )}
        </div>
      }
      <div className='pt-2 flex flex-col gap-2'>
        <div className='flex justify-between items-center'>
          <div className='text-lg font-semibold'>{attributes?.name}</div>
          {attributes?.acceptSubmission &&
            <div className='text-xs text-slate-400'>Submit by {getFormattedDate(new Date(attributes?.submissionDeadline ?? ''))}</div>
          }
        </div>
        <div className='text-md text-slate-500 line-clamp-3'>{attributes?.description}</div>
      </div>
      {badges.length > 0 && (
        <div className='flex items-center gap-2 w-full justify-end'>
          {badges.map((badge) => (
            <div
              key={badge.id}
              className='cursor-pointer'
              onClick={(e) => {
                e.stopPropagation();
                showBadgeInfo(badge?.id);
              }}
            >
              <img
                src={badge?.attributes?.badgeImage?.data?.attributes?.url}
                alt='badge'
                className='h-12 w-12 cursor-pointer animate-shake-tilt-delay'
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AssignmentListItem