import { useQuery } from 'react-query';
import { VirtualClassesService } from '../../services/virtual-classes-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { ACTIVE_VIRTUAL_CLASSES_OF_STUDENT } from '../../utils/constants/globals';
import { useNavigate } from 'react-router-dom';

const VirtualClassesPageViewModel = () => {
    const profile = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();

    const {
        isLoading: isActiveVirtualClassesOfStudentFetching,
        data: activeVirtualClassesOfStudent,
    } = useQuery({
        queryKey: [ACTIVE_VIRTUAL_CLASSES_OF_STUDENT, profile?.activeStudent?.id],
        queryFn: () => VirtualClassesService.instance.getActiveMeetingOfStudent(profile?.activeStudent?.id ?? 0),
        refetchOnWindowFocus: true,
        enabled: (profile?.activeStudent?.id ?? 0) > 0,
        refetchInterval: 30000,
    });

    return {
        isLoading: isActiveVirtualClassesOfStudentFetching,
        activeVirtualClasses: activeVirtualClassesOfStudent?.data?.data,
        navigate,
    }
}

export default VirtualClassesPageViewModel