import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../navigation/routes";


const PaymentPageViewModel = () => {
    const checkoutRef = useRef(null);
    const cashfreeRef = useRef<any>(null);
    useEffect(() => {
        cashfreeRef.current = window.cashfree;
    }, []);

    const location = useLocation();
    const navigate = useNavigate();
    const { sessionId, orderId } = location.state || {};
    
    useEffect(() => {
        if (sessionId && checkoutRef.current) {
            let checkoutOptions = {
                paymentSessionId: sessionId,
                redirectTarget: checkoutRef.current,
                appearance: {
                    width: "100%",
                    height: "100%",
                },
            };

            if (cashfreeRef.current) {
                cashfreeRef.current.checkout(checkoutOptions).then((result: any) => {
                    if (result.error) {
                        // This will be true when there is any error during the payment
                        console.log("There is some payment error, Check for Payment Status");
                        console.log(result.error);
                    }
                    if (result.redirect) {
                        // This will be true when the payment redirection page couldnt be opened in the same window
                        // This is an exceptional case only when the page is opened inside an inAppBrowser
                        // In this case the customer will be redirected to return url once payment is completed
                        console.log("Payment will be redirected");
                    }
                    if (result.paymentDetails) {
                        // This will be called whenever the payment is completed irrespective of transaction status
                        console.log("Payment has been completed, Check for Payment Status");
                        console.log(result.paymentDetails.paymentMessage);
                        navigate(Routes.paymentStatus+`/${orderId}`,{replace: true});
                    }
                });
            } else {
                console.error('Cashfree object is not available');
            }
        }
    }, [checkoutRef, cashfreeRef, sessionId]);

    return {
        isLoading: false,
        checkoutRef,
    };
}

export default PaymentPageViewModel