import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FitnessTestsService } from '../../services/fitness-tests-service';
import { STUDENT_FITNESS_TESTS_LIST_QUERY } from '../../utils/constants/globals';



const FitnessTestsPageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const navigate = useNavigate();
    const profile = useSelector((state: RootState) => state.activeProfile);

    const {
        isLoading: isFitnessTestsRecordsFetching,
        data: fitnessTestRecords,
    } = useQuery({
        queryKey: [STUDENT_FITNESS_TESTS_LIST_QUERY],
        queryFn: () => FitnessTestsService.instance.getStudentFitnessRecords(profile?.activeStudent?.id),
        refetchOnWindowFocus: false,
        enabled: profile?.activeStudent?.id > 0,
    });

    const [isFitnessTestInfoOpen, setisFitnessTestInfoOpen] = useState(false)
    const [activeFitnessTestInfoOpen, setActiveFitnessTestInfoOpen] = useState(0)

    const handleOpenFitnessTestInfo = (id: number) => {
        setActiveFitnessTestInfoOpen(id)
        setisFitnessTestInfoOpen(true)
    }

    function calculateBmiScore(height: number, weight: number) {
        const bmi = weight / (height * height);
        return bmi.toFixed(2);
    }
    
    function getUnitShortName(unit: string): string {
        switch (unit) {
            case 'METER':
                return 'm';
            case 'CENTIMETER':
                return 'cm';
            case 'SECOND':
                return 's';
            case 'MINUTE':
                return 'min';
            case 'REPETITION':
                return 'rep';
            case 'KILOGRAM':
                return 'kg';
            case 'GRAM':
                return 'g';
            default:
                return '';
        }
    }


    return {
        isLoading: isFitnessTestsRecordsFetching,
        isFitnessTestInfoOpen,
        setisFitnessTestInfoOpen,
        handleOpenFitnessTestInfo,
        fitnessTestRecords: fitnessTestRecords?.data?.data,
        activeFitnessTestInfoOpen: fitnessTestRecords?.data?.data?.find(test => test.id === activeFitnessTestInfoOpen),
        calculateBmiScore,
        getUnitShortName,
    }
}

export default FitnessTestsPageViewModel;

