
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../navigation/routes";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* Footer for desktop View */}
            <div className="sm:flex hidden footer bottom-0 relative w-full justify-between items-center px-6 py-2">
                <div>
                    <img src="/assets/images/logo.svg" alt="link" onClick={() => navigate(Routes.DefaultPage)} className="cursor-pointer h-10" />
                </div>
                <div className="w-[70%] flex justify-between items-center pr-10 font-bold text-[14px]">
                    <a className="cursor-pointer" onClick={() => navigate(Routes.cookiePolicy)}>
                        Cookie Policy
                    </a>
                    <a className="cursor-pointer" onClick={() => navigate(Routes.aboutUs)}>
                        About Us
                    </a>
                    <a className="cursor-pointer" onClick={() => navigate(Routes.contactUs)}>
                        Contact Us
                    </a>
                    <a className="cursor-pointer" onClick={() => navigate(Routes.termsAndConditions)}>
                        Terms & Conditions
                    </a>
                    <a className="cursor-pointer" onClick={() => navigate(Routes.privacyPolicy)}>
                        Privacy Policy
                    </a>
                </div>
            </div>
            {/* Footer for mobile view */}
            <div className="sm:hidden buttom-0 h-[10rem] w-full px-4 py-4 items-center">
                <div className="w-full flex justify-center">
                    <img src="/assets/images/logo.svg" alt="link" className="cursor-pointer h-8" />
                </div>
                <div className="flex justify-center text-sm gap-10 mt-2">
                    <a className="cursor-pointer" onClick={() => navigate(Routes.cookiePolicy)}>
                        Cookie Policy
                    </a>
                    <a className="cursor-pointer" onClick={() => navigate(Routes.aboutUs)}>
                        About Us
                    </a>
                    <a className="cursor-pointer" onClick={() => navigate(Routes.contactUs)}>
                        Contact Us
                    </a>
                </div>
                <div className="flex justify-center text-sm gap-10 mt-2">
                    <a className="cursor-pointer" onClick={() => navigate(Routes.termsAndConditions)}>
                        Terms & Conditions
                    </a>
                    <a className="cursor-pointer" onClick={() => navigate(Routes.privacyPolicy)}>
                        Privacy Policy
                    </a>
                </div>
            </div>
        </>
    );
}

export default Footer