import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StudentData } from '../../models/student/student-data';
import { ClassData } from '../../models/class/class-data';
import { saveStudentToLocalStorage, saveClassToLocalStorage, loadStudentFromLocalStorage, loadClassFromLocalStorage, loadClassFeeDataFromLocalStorage, saveClassFeeDataToLocalStorage, loadAllStudentsDataFromLocalStorage, saveAllStudentsToLocalStorage, loadAllClassesDataFromLocalStorage, saveAllClassesToLocalStorage, loadAttendanceOfClassFromLocalStorage, saveAttendanceOfClassToLocalStorage } from '../../utils/helpers/helpers';
import { FeePaymentData } from '../../models/fee/fee-payment-data';
import { AttendanceAttributesWraper } from '../../models/attendance/attendance-attributes-wrapper';
import { ParentDetailsData } from '../../models/parent-detail/parent-detail-data';

const activeProfileState = {
    activeStudent: loadStudentFromLocalStorage() || {} as StudentData,
    activeClassData: loadClassFromLocalStorage() || {} as ClassData,
    activeClassFeeData: loadClassFeeDataFromLocalStorage() || {} as FeePaymentData,
    activeClassAttendanceData: loadAttendanceOfClassFromLocalStorage() || [] as AttendanceAttributesWraper[],
    allStudentsData: loadAllStudentsDataFromLocalStorage() || [] as StudentData[],
    allClassesOfProfile: loadAllClassesDataFromLocalStorage() || [] as ClassData[],
    allClaimableProfiles: [] as StudentData[],
    parentUserDetails: {} as ParentDetailsData,
};

const activeProfile = createSlice({
    name: 'active-profile',
    initialState: activeProfileState,
    reducers: {
        setActiveStudentProfile: (state, action: PayloadAction<StudentData>) => {
            state.activeStudent = action.payload;
            saveStudentToLocalStorage(action.payload);
        },
        setActiveClassData: (state, action: PayloadAction<ClassData>) => {
            state.activeClassData = action.payload;
            saveClassToLocalStorage(action.payload);
        },
        setActiveClassFeeData: (state, action: PayloadAction<FeePaymentData>) => {
            state.activeClassFeeData = action.payload;
            saveClassFeeDataToLocalStorage(action.payload);
        },
        setActiveClassAttendanceData: (state, action: PayloadAction<AttendanceAttributesWraper[]>) => {
            state.activeClassAttendanceData = action.payload;
            saveAttendanceOfClassToLocalStorage(action.payload);
        },
        setAllStudentsData: (state, action: PayloadAction<StudentData[]>) => {
            state.allStudentsData = action.payload;
            saveAllStudentsToLocalStorage(action.payload);
        },
        setAllClasses: (state, action: PayloadAction<ClassData[]>) => {
            state.allClassesOfProfile = action.payload;
            saveAllClassesToLocalStorage(action.payload);
        },
        setAllClaimableProfiles: (state, action: PayloadAction<StudentData[]>) => {
            state.allClaimableProfiles = action.payload;
        },
        setParentDetailsData: (state, action: PayloadAction<ParentDetailsData>) => {
            state.parentUserDetails = action.payload;
        },
    },
});

export const { setActiveStudentProfile, setParentDetailsData, setActiveClassData, setActiveClassFeeData, setAllStudentsData, setAllClasses, setActiveClassAttendanceData, setAllClaimableProfiles } = activeProfile.actions;
export default activeProfile.reducer;
