import React from 'react'
import CertificationsViewModel from '../../view-models/certifications-viewmodel';
import Layout from '../../components/common/layout';
import { getFormattedDate } from '../../../utils/helpers/helpers';

const CertificationsPage = () => {
    const {
        isLoading,
        StudentCertifications,
    } = CertificationsViewModel();

    return (
        <Layout isProfileSwitchTabVisible={true}>
            <div className='text-3xl font-semibold drop-shadow mb-4'>Certifications</div>
            {StudentCertifications?.map((certification, index) => (
                <a 
                    key={index}
                    className="w-full p-2 rounded flex items-center gap-2 bg-slate-300" 
                    href={certification?.attributes?.certificate?.data?.attributes?.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className='rounded-full h-14 w-14 border-slate-50 border-2 flex items-center justify-center text-2xl font-semibold'>
                        {certification?.attributes?.certificationInfo?.data?.attributes?.level}
                    </div> 
                    <div>
                        <div className='font-semibold text-lg'>{certification?.attributes?.certificationInfo?.data?.attributes?.name}</div>
                        <div className='text-sm text-gray-500'>Issued on {getFormattedDate(certification?.attributes?.createdAt)}</div>
                    </div>
                </a>
            ))}
        </Layout>
    )
}

export default CertificationsPage