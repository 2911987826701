import React from 'react'
import ResetPasswordViewModel from '../../view-models/reset-password-viewmodel'
import FullScreenLoader from '../../components/common/full-sreen-loader';
import { motion } from "framer-motion";
import { Routes } from '../../navigation/routes';

const ResetPasswordPage = () => {
    const {
        isLoading,
        handleResetPasswordSubmit,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        navigate,
        isPasswordChangeSuccess,
    } = ResetPasswordViewModel();

  return (
    <div className="sm:flex w-full">
            {isLoading && <FullScreenLoader />}
            <div className="sm:block hidden bg-[#3AB7FC] w-[40%] h-svh"></div>
            <div className="bg-white sm:w-[60%] w-full h-svh">
                <div className="w-full sm:h-[15%] h-[10%] bg-[#F6B40A]">
                    <div className="w-full h-[35%]"></div>
                    <div className="flex justify-center w-full h-[50%]">
                        <img
                            className=" "
                            src='/assets/images/logo.svg'
                            alt="link"
                        />
                    </div>
                </div>
                <div className='flex h-[calc(100vh-128px)] items-center justify-center p-4'>
                    <div className="container mx-auto">
                        <div className="flex justify-center">
                            <div className="w-full md:w-1/2">
                                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                    <div className="text-center">
                                        {/* <h2 className="text-center text-xl font-bold mb-2">Forgot Password?</h2> */}
                                        <p className="text-sm mb-4">You can reset your password here.</p>
                                    </div>
                                    <div className="mb-4">
                                        <form id="register-form" role="form" onSubmit={handleResetPasswordSubmit} autoComplete="off" className="form">
                                            <div className="mb-4">
                                                <div className="flex items-center bg-gray-200 rounded-lg mb-2">
                                                    <input 
                                                        placeholder="New password" 
                                                        className="w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                                        type="password"
                                                        minLength={6}
                                                        value={password}
                                                        required
                                                        onChange={e => setPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="flex items-center bg-gray-200 rounded-lg">
                                                    <input 
                                                        placeholder="Confirm password" 
                                                        className="w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                                        type="password"
                                                        value={confirmPassword}
                                                        required
                                                        onChange={e => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <input name="recover-submit" disabled={password !== confirmPassword} className="w-full bg-blue-500 hover:bg-blue-700 disabled:opacity-30 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" value="Change Password" type="submit"/>
                                            </div>
                                            <input type="hidden" className="hidden" name="token" id="token" value="" /> 
                                        </form>
                                    </div>
                                        {(password !== confirmPassword && confirmPassword !== '') &&
                                            <div className='text-red-400 text-center text-sm font-semibold'>Passwords do not match!</div>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isPasswordChangeSuccess &&
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 100 }}
                    className='fixed bottom-0 left-0 top-0 h-full w-full right-0 z-[999999999999] flex flex-col items-center gap-4 justify-center bg-white p-4'
                >
                    <div className='text-2xl font-semibold'>Password has been changed successfully!</div>
                    <div 
                        className='w-full py-2 bg-slate-500 px-2 text-center text-xl font-semibold text-white rounded cursor-pointer'
                        onClick={() => window.location.href = 'https://academies.sparts.app/login'}
                    >
                        Academy Login
                    </div>
                    <div 
                        className='w-full py-2 bg-[#F6B40A] px-2 text-center text-xl font-semibold text-white rounded cursor-pointer'
                        onClick={() => navigate(Routes.Login, {replace: true})}
                    >
                        Parents Login
                    </div>
                </motion.div>
            }
        </div>
  )
}

export default ResetPasswordPage