import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/landing-page-items/navbar";
import { FaGreaterThan } from "react-icons/fa";
import { PiArrowArcRightBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/landing-page-items/footer";



const FirstPage = () => {

    return (
<div className="flex flex-col sm:gap-12 items-center bg-gradient-to-r from-red-50 via-orange-50 to-blue-50 min-h-screen p-4">
  
  <div className="flex flex-col md:flex-row mt-[8%] sm:mt-[5%] w-full items-center sm:pl-28">
    <img
      src="https://media-public.canva.com/ql-BM/MAFAX_ql-BM/1/s-1.svg"
      className="w-[120px] h-[190px] md:w-[175px] md:h-[300px] animate-slide-in-left"
    />
    <h1 className="text-[#388E3C] text-center text-3xl md:text-[5rem] sm:ml-4 font-bold w-full md:w-[70%] leading-[48px] md:leading-[96px] tracking-wide md:tracking-widest mt-4 md:mt-0">
      Can you outplay or outcreate the best?
    </h1>
    <img
      src="https://media-public.canva.com/TnNT8/MAE7BdTnNT8/1/s.svg"
      className="w-[120px] h-[190px] md:w-[300px] md:h-[275px] animate-slide-in-right"
    />
  </div>

  <p className="text-center text-xl md:text-5xl text-[#388E3C] font-bold my-6 md:mb-8">
    An amazing reward is just a nomination away!
  </p>

  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 w-full">
    <img
      src="https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-cartoon-boy-playing-football-wearing-red-jersey-png-image_6113130.png"
      alt="Character 2"
      className="w-[175px] h-[150px] md:w-[300px] md:h-[300px] object-contain mx-auto animate-slide-in-left"
    />
    <img
      src="https://static.vecteezy.com/system/resources/previews/049/655/127/non_2x/cartoon-boy-running-clip-art-png.png"
      alt="Character 3"
      className="w-[100px] h-[150px] md:w-[175px] md:h-[275px] object-contain mx-auto animate-slide-in-right"
    />
    <img
      src="https://media-public.canva.com/MkSVU/MAFUh3MkSVU/1/s.svg"
      alt="Character 4"
      className="w-[100px] h-[150px] md:w-[240px] md:h-[275px] object-contain mx-auto animate-slide-in-left"
    />
    <img
      src="https://media-public.canva.com/9qImw/MAF-OH9qImw/1/s.png"
      alt="Character 5"
      className="w-[100px] h-[150px] md:w-[240px] md:h-[275px] object-contain mx-auto sm:ml-16 animate-slide-in-right"
    />
  </div>
</div>


)
}

const About = () => {
return (
    <div className="bg-gradient-to-r from-[#d5bb6b] to-[#9fd3f1] p-6 md:p-8 rounded-lg shadow-md">
  <h2 className="text-3xl md:text-5xl font-bold text-[#156669] mb-6 md:mb-12 inline-block leading-8 md:leading-10 text-center md:text-left">
    About the Scholarship
  </h2>
  <p className="text-xl md:text-3xl leading-[30px] md:leading-[50px] mb-4 md:mb-6 tracking-wide md:tracking-wider text-justify md:text-left">
    SpArts, a leading aggregator of Sports and Creative Arts
    academies, is offering an exciting scholarship opportunity for talented
    students aged 6–15. This scholarship aims to recognize and nurture
    young talents in sports and arts by providing them with a platform to
    shine. Open to all eligible participants, the program celebrates
    creativity and athleticism, inspiring the next generation to pursue
    their passions. Don't miss your chance to be part of this incredible
    opportunity!
  </p>
  <h3 className="my-12 md:mt-20 text-3xl md:text-5xl font-bold text-[#156669] text-center leading-normal md:leading-snug">
    Nominate today for exciting rewards!
  </h3>
</div>

  );
}

const Nomination = () => {
    return (
    <div className="flex flex-col bg-gradient-to-r from-[#9fd3f1] to-[#d5bb6b] p-8">
      <h2 className="text-3xl md:text-5xl text-[#156669] leading-8 md:leading-10 tracking-tight font-bold mb-6">Nomination Criteria</h2>
      
      <div className="max-w-4xl w-full">
        <div className=" flex gap-4">
        <h2 className="text-2xl font-semibold text-green-800 mt-2">
            <span className="mr-2"><FaGreaterThan style={{width:'20px', height:'30px', color:'#156669'}}/></span> 
        </h2>
        <div>
            <p className="text-xl md:text-3xl text-[#156669] leading-[30px] md:leading-[50px] tracking-tight font-bold">Academic Excellence</p>
            <p className="text-xl md:text-3xl leading-[30px] md:leading-[50px] tracking-tight">
                Applicants aged 6 to 15 years must exhibit outstanding academic performance by meeting or exceeding the grade requirements set by their educational institution.
            </p>
        </div>
        </div>

        <div className=" flex gap-4 relative">
        <h2 className="text-2xl font-semibold text-green-800 mt-2">
            <span className="mr-2"><FaGreaterThan style={{width:'20px', height:'30px', color:'#156669'}}/></span> 
        </h2>
        <div>
            <p className="text-xl md:text-3xl text-[#156669] leading-[30px] md:leading-[50px] tracking-tight font-bold">Attendance Requirement</p>
            <p className="text-xl md:text-3xl leading-[30px] md:leading-[50px] tracking-tight">
                Applicants are required to maintain at least 90% attendance at their SpArts partner academy.
            </p>
        </div>
        </div>

        <div className=" flex gap-4">
        <h2 className="text-2xl font-semibold text-green-800 mt-2">
            <span className="mr-2"><FaGreaterThan style={{width:'20px', height:'30px', color:'#156669'}}/></span> 
        </h2>
        <div>
            <p className="text-xl md:text-3xl text-[#156669] leading-[30px] md:leading-[50px] tracking-tight font-bold">Event Participation</p>
            <p className="text-xl md:text-3xl leading-[30px] md:leading-[50px] tracking-tight">
                Within the past six months, applicants must have participated in at least one sports or arts event or tournament.
            </p>
        </div>
        </div>
      </div>
    </div>
  );
}

const Evaluation = () => {
    return (
        <div className="flex flex-col bg-gradient-to-r from-[#d5bb6b] to-[#9fd3f1] p-8">
            <div>
                <h2 className="text-3xl md:text-5xl text-[#156669] leading-8 md:leading-10 tracking-tight font-bold mb-6 ml-[5%]">Nomination Process</h2>
                <div className=" flex gap-4 items-center">
                    <h2 className="text-2xl font-semibold text-green-800 m-0">
                        <span className="mr-2"><FaGreaterThan style={{width:'20px', height:'30px', color:'#156669'}}/></span> 
                    </h2>
                    <p className="text-xl md:text-3xl leading-[30px] md:leading-[50px] tracking-tight">
                    Only through SpArts partner academies can students be nominated for this prestigious scholarship
                    </p>
                </div>
            </div>
            <div>
                <h2 className="text-3xl md:text-5xl text-[#156669] leading-8 md:leading-10 tracking-tight font-bold mb-6 ml-[5%]">Evaluation</h2>
                <div className=" flex gap-4 items-center">
                    <h2 className="text-2xl font-semibold text-green-800 m-0">
                        <span className="mr-2"><FaGreaterThan style={{width:'20px', height:'30px', color:'#156669'}}/></span> 
                    </h2>
                    <p className="text-xl md:text-3xl leading-[30px] md:leading-[50px] tracking-tight">
                        SpArts will carefully evaluate the talent and potential of each nominee
                    </p>
                </div>
                <div className=" flex gap-4 items-center">
                    <h2 className="text-2xl font-semibold text-green-800 m-0">
                        <span className="mr-2"><FaGreaterThan style={{width:'20px', height:'30px', color:'#156669'}}/></span> 
                    </h2>
                <p className="text-xl md:text-3xl leading-[30px] md:leading-[50px] tracking-tight">
                    The selection process will include several rounds and interactive sessions
                </p>
                </div>
            </div>
        </div>
    )
}

const Timeline = () => {

const navigate = useNavigate();

    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-r from-[#9fd3f1] to-[#d5bb6b] p-8">
            <h2 className="text-3xl md:text-5xl text-[#156669] leading-8 md:leading-10 tracking-tight font-bold ml-[10%]">Timeline</h2>
            <div className="flex flex-col items-center my-12">
          <img src="/assets/images/timeline.png"/>
            </div>
            <h2 className="text-5xl text-center text-[#156669] leading-normal tracking-tight mb-6">
                18 awardees will receive scholarships of up to ₹1 Lakh!
            </h2>
            <div className="relative flex items-center justify-center">
          <img src="https://media-public.canva.com/dn8YE/MAEorHdn8YE/1/s.png" className="self-center animate-scale h-52 w-72" />
        </div>
        <button onClick={() => navigate('/contact-us')} className="sm:mt-[5%] mt-[10%] sm:w-[15%] w-[60%] self-center text-white bg-[#3AB7FC] sm:p-4 p-2 rounded-xl shadow-lg font-bold text-[20px] active:scale-95">Contact Us</button>
        <p className="mt-[2%] self-center text-[#156669] font-bold text-[30px] active:scale-95">Ph : 8095192069</p>
        </div>
    )
}

const ScholarshipPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <>
        <Helmet>
            <title>SpArts Scholarships</title>
            {/* <meta name="description" content={`Find top-rated sports and arts academies with SpArts. Explore programs designed to support student growth through structured training, skill-building exercises, and SpArts AI for personalized development.`} /> */}
        </Helmet>
          <Navbar />
          <div className="flex flex-col w-screen">
            <FirstPage />
            <About />
            <Nomination />
            <Evaluation />
        <Timeline />
        <div className="bg-gradient-to-r from-[#9fd3f1] to-[#d5bb6b] sm:p-8">
          <Footer/>
        </div>
        
          </div>
      </>
  );
};

export default ScholarshipPage;
