import ApiResponse from "../models/api/api-response";
import { ParentDetailListWrapper } from "../models/parent-detail/parent-detail-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class ParentService {
    private static _instance: ParentService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new ParentService());
    }
    public getParentUserDetails = async (id: number): Promise<ApiResponse<ParentDetailListWrapper>> => {
        return invokeApi<null, ParentDetailListWrapper>({
            route: `parent-details?filters[userInfo][id]=${id}`,
            method: 'GET',
            privateRoute: true,
        });
    }
}