import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Navigation, FreeMode, Autoplay, EffectCoverflow } from 'swiper/modules';
import SlideUtility from '../../../../utils/slider-utils';
import { testimonialsData } from './data';
import { useState } from 'react';

interface ITestimonial {
    title: string;
    summary: string;
    img: string;
    subtitle:string;
}

const TestimonyCarousal = () => {
    const sliderutilitycurrent = new SlideUtility(testimonialsData);
    const [swiperInstance, setSwiperInstance] = useState<any>(null);

    const breakpoints = {
        768: {
            slidesPerView: 3, 
        },
        
        320: {
            slidesPerView: 1,
        },
    };

    return (
        <div className='testimonial-carousel w-full mt-[4rem] relative right-[-100%] h-[70%]'>
            <Swiper
                modules={[EffectCoverflow, Navigation, FreeMode, Autoplay]}
                autoplay={true}
                spaceBetween={30}
                slidesPerView={3}
                breakpoints={breakpoints}
                autoFocus={true}
                freeMode={true}
                loop={true}
                className='max-w-[90%] h-[100%] p-6 mySwipper'
                onSlideChange={sliderutilitycurrent.handleSlideChange}
                onSwiper={setSwiperInstance}
            >
                {
                    testimonialsData && testimonialsData.map((d: ITestimonial, index: number) => {
                        return (
                            <SwiperSlide
                                className="items-center flex"
                                key={index}>
                                <div className="relative w-full h-[90%] bg-slate-300 rounded-lg shadow-lg transition-all">
                                    <div className="p-4">
                                        <div className="text-lg font-normal mb-2">
                                            {d.summary}
                                        </div>
                                        <div className="absolute bottom-3 left-3 text-slate-600 w-[70%]">
                                            {d.title} <br />
                                            {d.subtitle}
                                        </div>
                                        

                                    </div>
                                    <div
                                        className="bg-slate-700 w-20 h-20 rounded-full absolute bottom-3 right-3 bg-cover bg-center"
                                        style={{
                                            backgroundImage: `url(${d.img})`,
                                        }}
                                    />
                                </div>
                            </SwiperSlide>
                        );
                    })
                }
                <div className="z-[999999] absolute right-[0px] top-[45%]">
                    <button
                        className={`shadow-sm bg-[#3AB7FC] text-white w-[3rem] h-[3rem] text-2xl rounded-full flex items-center justify-center`}
                        onClick={() => {
                            if (swiperInstance) {
                                swiperInstance.slideNext();
                            }
                        }}
                    >
                        &gt;
                    </button>
                </div>
            </Swiper>

        
        </div>
    );
}

export default TestimonyCarousal;
