import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useNavigate } from 'react-router-dom';


const SettingsPageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    return {
        isLoading: false,
    }
}

export default SettingsPageViewModel
