import React, { useState } from 'react';
import { PiDotsNineBold } from "react-icons/pi";
import {FaDownload} from 'react-icons/fa6';
import {motion} from "framer-motion";
import DragCloseDrawer from '../drag-close-drawer';
import {TransactionData} from '../../../view-models/fee-transactions-viewmodel';
import {getFormattedDate} from '../../../../utils/helpers/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/redux/store';
import { useNavigate } from 'react-router-dom';
import { router } from '../../../navigation/route-container';
import { Routes } from '../../../navigation/routes';

type fileData = {
    data:any;
}

type fileAttributes = {
    mime: any;
    name: string;
    url: string;
    file: fileData;
};

type file = {
    attributes: fileAttributes;
};

type RowActionProps = {
    text: string;
    id: string;
    dates: string;
    description?: string;
    file?: file | null;
    feeDetails?: TransactionData;
    tryit?: string,
};

const RowAction: React.FC<RowActionProps> = ({ text, id, dates, description, file, feeDetails, tryit}) => {
  const [open, setOpen] = useState(false);
  const parent = useSelector((state: RootState) => state.activeProfile);
  const renderFilePreview = () => {
    
      if (!file || !file.attributes) return null;

      const {mime, url} = file?.attributes;

      if (mime.startsWith('image/')) {
          return (
              <img
                  src={url}
                  alt={file?.attributes.name}
                  className="max-w-full h-[300px]"
              />
          );
      }

      if (mime.startsWith('audio/')) {
          return (
              <audio controls className="w-full h-[300px]">
                  <source src={url} type={mime} />
              </audio>
          );
      }

      if (mime.startsWith('video/')) {
        
        
          return (
              <video controls className="w-full h-[300px]">
                  <source src={url} type={mime} />
              </video>
          );
      }

      if (mime === 'application/pdf') {
          return (
              <a
              target='_blank'
                  href={url}
                  download
                  className=" hover:underline flex gap-4 text-white border p-4 rounded-lg">
                  <FaDownload className="text-white" /> Download pdf
              </a>
          );
      }

      return null;
  };
  const navigate = useNavigate()
  return (
      <>
          <motion.div
              className="flex flex-row w-full bg-[#D8D8D8] justify-between h-18 items-center p-4 rounded-lg shadow-lg"
              initial={{opacity: 0, scale: 0.9}}
              animate={{opacity: 1, scale: 1}}
              transition={{
                  duration: 0.6,
                  delay: 0.3,
                  ease: [0, 0.71, 0.2, 1.01],
              }}>
              <div className="flex flex-col justify-between  gap-2">
                  <div className="font-bold overflow-hidden text-ellipsis whitespace-nowrap max-w-[70vw]">
                      {text}
                  </div>
                  <div className="font-bold">{dates}</div>
              </div>



              <button id={id} onClick={() => setOpen(true)}>
                  <PiDotsNineBold className="text-4xl" />
              </button>
          </motion.div>
          {description ? (
              <DragCloseDrawer open={open} setOpen={setOpen}>
                  <div className="mx-auto max-w-2xl space-y-4 text-neutral-400">
                        <h1 className='text-4xl font-semibold text-center'>{text}</h1>
                        <hr className='border-t-2 border-gray-700'/>
                        {tryit && <button 
                        onClick={()=>navigate(`${Routes.technique}/${tryit}`)}
                        className='p-4 border-2 border-white bg-black text-white w-full rounded hover:invert'
                        > try it</button>}
                        <div dangerouslySetInnerHTML={{__html: description ?? ''}} className='reset-all p-2 my-2' />
                        {
                            <div className="flex items-center justify-center">
                                {renderFilePreview()}
                                
                            </div>
                        }
                       
                    </div>
              </DragCloseDrawer>
          ) : (
              <DragCloseDrawer open={open} setOpen={setOpen}>
                  <div className="mx-auto max-w-2xl space-y-4 text-neutral-400">
                      <h2 className="text-4xl font-bold text-neutral-200 text-center">
                          Transaction Details
                      </h2>
                      {
                          <div className="flex gap-3  border-b-2 border-slate-500 justify-between text-white py-2">
                              <p className="font-medium">Class Name: </p>
                              <p>
                                  {parent.activeClassData.attributes.class_name}
                              </p>
                          </div>
                      }

                      {
                          <div className="flex border-b-2 justify-between border-slate-500 text-white py-2">
                              <p className="font-semibold">
                                  Fee cycle started on:{' '}
                              </p>
                              <p>
                                  {getFormattedDate(
                                      feeDetails?.startDate ?? new Date(),
                                  )}
                              </p>
                          </div>
                      }
                      {feeDetails?.dueDate &&
                      feeDetails?.dueDate > new Date() ? (
                          <div className="flex border-b-2 justify-between border-slate-500 text-white py-2">
                              <p className="font-semibold">
                                  Fee cycle will end on:{' '}
                              </p>
                              <p>
                                  {getFormattedDate(feeDetails?.dueDate ?? '')}
                              </p>
                          </div>
                      ) : (
                          <div className="flex border-b-2 justify-between border-slate-500 text-white py-2">
                              <p className="font-semibold">
                                  Fee cycle ended on:{' '}
                              </p>
                              <p>
                                  {getFormattedDate(feeDetails?.dueDate ?? '')}
                              </p>
                          </div>
                      )}

                      {
                          <div className="flex border-b-2 justify-between border-slate-500 text-white py-2">
                              <p className="font-semibold">Payment Mode: </p>
                              <p>{feeDetails?.payment_mode}</p>
                          </div>
                      }

                      {
                          <div className="flex border-b-2 justify-between border-slate-500 text-white py-2 ">
                              <p className="font-semibold">Schedule: </p>
                              <p>{feeDetails?.schedule}</p>
                          </div>
                      }
                      {
                          <div className="flex  justify-between text-white pb-2">
                              <p className="font-semibold">
                                  Balance after payment:{' '}
                              </p>
                              <p>₹{feeDetails?.balance}</p>
                              {/* <p> {feeDetails?.}</p> */}
                          </div>
                      }

                      {/* ############# */}
                      <div className="border-2 border-slate-400 p-4 rounded flex justify-between text-center ">
                          {feeDetails?.amount && (
                              <div className="flex flex-col justify-between  text-white ">
                                  <p className="font-medium">Amount Paid: </p>
                                  <p>₹{feeDetails?.amount}</p>
                              </div>
                          )}
                          {
                              <div className="flex flex-col justify-between  text-white ">
                                  <p className="font-semibold">
                                      Paid on:{' '}
                                  </p>
                                  <p>
                                      {getFormattedDate(
                                          feeDetails?.fee_date ?? new Date(),
                                      )}
                                  </p>
                              </div>
                          }
                      </div>

                      {/* <div className="bg-blue-400 rounded-lg px-8 py-2 text-center  flex justify-center items-center m-auto">
                          <button className="text-white font-semibold">
                              Download fee receipt
                          </button>
                      </div> */}
                  </div>
              </DragCloseDrawer>
          )}
      </>
  );
};

export default RowAction;
