import { AcademyData } from "../models/academy/academy-data";

interface ITestimonial {
  title: string;
  summary: string;
  img: string;
};

class SlideUtility {
    data;
    currentIndex;
    constructor(data:AcademyData[]|ITestimonial[]) {
      this.data = data;
      this.currentIndex = -1;
    }
  
    calculateSlideSize(index:number) {
      let height = "85%";
  
      if (
        index === this.currentIndex ||
        index === (this.currentIndex + 1) % this.data?.length
      ) {
        height = "100%";
      }
  
      return { height: height };
    }
  
    handleSlideChange(swiper: { realIndex: number; }) {
      this.currentIndex = (swiper.realIndex + 1) % this.data?.length;
    }
  }
  
  export default SlideUtility;
  