import axios from "axios";
import Webcam from "react-webcam";
import { useNavigate, useParams } from "react-router-dom";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as cam from "@mediapipe/camera_utils";
import { Pose, Results } from "@mediapipe/pose";
import * as mediapipePose from "@mediapipe/pose";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { BiVideoRecording } from "react-icons/bi";
import { IoChevronBack, IoCloudUploadOutline } from "react-icons/io5";
import { IoIosArrowBack, IoMdSend } from "react-icons/io";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { NormalizedLandmark } from "@mediapipe/pose";
import VideoPlayer from "../../components/common/videoPlayer/VideoPlayer";
import "./submissionPage.scss";
import { t2s } from '../../../utils/ai/t2s'
import { checkVisibility, checkXValue } from "../../../utils/ai/fitInframe";
import { compute_similarity_vector } from "../../../utils/ai/similarityUtils";
import Layout from '../../components/common/layout'
import AssignmentPageViewModel from "../../view-models/assignment-viewmodel";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/redux/store";

interface Technique {
  name: string;
  _id: string;
}

interface Props {
  techniquesListed: Technique[] | null;
  setTechniquesListed: React.Dispatch<React.SetStateAction<Technique[] | null>>;
  email: string;
  timer: boolean;
  setTimer: React.Dispatch<React.SetStateAction<boolean>>;
  setCountdown: React.Dispatch<React.SetStateAction<number>>;
  countdown: number;
}

interface CoorinatesXYZ {
  x: number;
  y: number;
  z: number;
}

interface WebcamRef {
  current: {
    video: HTMLVideoElement;
    canvas?: HTMLCanvasElement;
    ctx?: CanvasRenderingContext2D | null;
    requestUserMediaId?: number;
    unmounted?: boolean;
  };
}

interface KeyframeData {
  join1: string;
  join2: string;
  weightage: string;
}

interface Keyframe {
  timeStamp: number;
  data: KeyframeData[];
}

interface ResponseData {
  url: string;
  name: string;
  fps: number;
  keyframe: Keyframe[];
  insstructorkeyframe: [number, number, number][][];
}

interface OriginaAssignmentTechniqueRequestsData {
  id: number;
  attributes: {
    name: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

interface TransformedAssignmentTechniqueRequestsData {
  name: string;
  _id: string;
}

interface OriginalViewTechniqueByIdData {
  data: {
    id: number;
    attributes: {
      name: string;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
      keyFrames: {
        id: number;
        timeStamp: string;
        data: {
          id: number;
          join1: number;
          join2: number;
          weightage: number;
        }[];
      }[];
      video: {
        data: {
          id: number;
          attributes: {
            name: string;
            alternativeText: string | null;
            caption: string | null;
            width: number | null;
            height: number | null;
            formats: string | null;
            hash: string;
            ext: string;
            mime: string;
            size: number;
            url: string;
            previewUrl: string | null;
            provider: string;
            provider_metadata: string | null;
            createdAt: string;
            updatedAt: string;
          };
        };
      };
    };
  };
}

interface TransformedViewTechniqueByIdData {
  url: string;
  name: string;
  fps: number;
  keyframe: {
    timeStamp: number;
    data: {
      join1: string;
      join2: string;
      weightage: string;
    }[];
  }[];
}

interface ExtendedTransformedViewTechniqueByIdData
  extends TransformedViewTechniqueByIdData {
  insstructorkeyframe: number[][][];
}

interface OriginalKeyFrameData {
  frame: number[][][];
}

interface OriginalAttributes {
  framesPerSecond: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  keyFrames: OriginalKeyFrameData;
}

interface OriginalViewTechniqueByIdKeyframeData {
  id: number;
  attributes: OriginalAttributes;
}

type Mode = "OPTIONS" | "RECORD" | "UPLOAD";


const TechniqueTryPage = () => {
  const student = useSelector((state: RootState) => state.activeProfile);
  const user = useSelector((state: RootState) => state.user);

  const { techniqueId } = useParams()
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (user.user?.email) {
      console.log(user.user?.email)
      setEmail(user.user?.email)
    }
  }, [user])

  const [techniquesListed, setTechniquesListed] = useState<Technique[] | null>(
    null
  );
  const [timer, setTimer] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(3);

  const navigate = useNavigate();
  const name = email;

  let camera: cam.Camera | null = null;
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const scoreCanvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const [played, setPlayed] = useState<number>(0);
  const [jsonlst, setJsonlst] = useState<any>();
  const [video, setVideo] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [mode, setMode] = useState<Mode>("OPTIONS");
  const [level, setLevel] = useState<string>("Beginner");
  const [capturing, setCapturing] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isKeyFrame, setIsKeyFrame] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>({});
  const [frameNumbers, setFrameNumbers] = useState<number[]>([]);
  const [simThreshold, setSimThreshold] = useState<number>(0.6);
  const [keyframeCheck, setKeyframeCheck] = useState<any[]>([]);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(techniqueId ? techniqueId : null);
  const [currentLandmarks, setCurrentLandmarks] = useState<
    NormalizedLandmark[]
  >([]);
  const [currentKeyframe, setCurrentKeyframe] = useState<number | null>(null);
  const [recordingPaused, setRecordingPaused] = useState<boolean>(false);
  const [lstContinueCounter, setLstContinueCounter] = useState<number>(0);

  const [simThresholdScoring, setSimThresholdScoring] = useState<number>(0.6);
  const [currentSimilarityScore, setCurrentSimilarityScore] = useState<
    number | null
  >(null);
  const [startTime, setStartTime] = useState<number>(0.0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [beginPause, setBeginPause] = useState<boolean>(false);
  const [isCameraLoading, setIsCameraLoading] = useState(true);
  const [recordingDone, setRecordingDone] = useState<boolean>(false);
  const [techniqueSelectedID, setTechniqueSelectedID] = useState(null);
  const [isSubmitting, setisSubmitting] = useState<boolean>(false);
  const [techniqueName, setTechniqueName] = useState("")

  useEffect(() => {
    if (mode === "RECORD") {
      setTimeout(() => {
        // setIsCameraLoading(false);
      }, 2000); // Hide loader after 2 seconds
    }
  }, [mode]);

  /**
   * Handles the resize event and updates the state for mobile viw accordingly.
   */
  useEffect(() => {
    const handleResize = (): void => {
      // console.log(window.innerWidth);
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    setRecordingDone(false);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Effect to initialize and configure pose detection when mode is 'RECORD'.
   * Sets up a MediaPipe Pose instance, configures options, and starts camera
   * streaming and pose detection.
   */
  useEffect(() => {
    if (mode === "RECORD") {
      console.log("start");
      const userPose = new Pose({
        locateFile: (file: string) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/pose@0.2/${file}`;
        },
      });

      userPose.setOptions({
        minDetectionConfidence: 0.9,
        minTrackingConfidence: 0.9,
      });

      if (
        webcamRef.current &&
        webcamRef.current != null &&
        webcamRef.current.video &&
        typeof webcamRef.current.video !== "undefined"
      ) {
        userPose.onResults(onResults);
        camera = new cam.Camera(webcamRef.current?.video, {
          onFrame: async () => {
            if (webcamRef.current && webcamRef.current.video) {
              await userPose.send({ image: webcamRef.current.video });
            }
          },
          width: 640,
          height: 480,
        });
        camera.start();
      }
    }
  }, [mode]);

  /**
   * Transforms the original technique data fetched by ID into the desired format.
   *
   * @param {OriginalViewTechniqueByIdData} originalData - The original data fetched by ID from the API.
   * @returns {TransformedViewTechniqueByIdData} The transformed data in the desired format.
   *
   * The function extracts necessary properties from the original data, such as `name`, `keyFrames`,
   * and `video.url`, and maps over the key frames to convert timestamps and nested data into the
   * appropriate format for the application.
   */
  const transformewTechniqueByIdData = (
    originalData: OriginalViewTechniqueByIdData
  ): TransformedViewTechniqueByIdData => {
    const { attributes } = originalData.data;
    const { keyFrames, video } = attributes;

    // console.log({keyFrames:keyFrames})

    return {
      url: video.data.attributes.url,
      name: attributes.name,
      fps: 30,
      keyframe: keyFrames.map((keyFrame) => ({
        timeStamp: parseFloat(keyFrame.timeStamp),
        data: keyFrame.data.map((item) => ({
          join1: item.join1.toString(),
          join2: item.join2.toString(),
          weightage: item.weightage.toString(),
        })),
      })),
    };
  };

  /**
   * Fetches and processes data for the selected option.
   *
   * This effect is triggered whenever `selectedOption` changes. It fetches technique details and processed keyframes
   * from the API, transforms the data, and updates the state accordingly.
   *
   * Steps:
   * 1. Fetch technique details and keyframes from the API.
   * 2. Transform the fetched data using the `transformewTechniqueByIdData` function.
   * 3. Fetch processed keyframes data from a second API endpoint.
   * 4. Merge the transformed data with the processed keyframes.
   * 5. Calculate frame numbers and update the state with the transformed data.
   *
   * @async
   * @function
   * @memberof useEffect
   * @param {void}
   * @returns {void}
   */
  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchData")
      if (selectedOption) {
        try {
          setLoading(true);
          // console.log(selectedOption)
          const response = await axios.get(
            `${process.env.REACT_APP_STRAPI_URL}/api/assignment-technique-requests/${selectedOption}?populate=keyFrames.data&populate=video.data&populate=response.data`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STAPI_TOKKEN}`,
              },
            }
          );

          // console.log(response.data.data.attributes.response.data.id)

          console.log(response.data)

          setTechniqueName(response.data.data.attributes.name)

          setTechniqueSelectedID(
            response.data.data.attributes.response.data.id
          );
          const transformedDataInit: TransformedViewTechniqueByIdData =
            transformewTechniqueByIdData(response.data);

          // console.log({transformedDataInit:transformedDataInit})

          const response2 = await axios.get(
            `${process.env.REACT_APP_STRAPI_URL}/api/assignment-techniques-processed/${response.data.data.attributes.response.data.id}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STAPI_TOKKEN}`,
              },
            }
          );


          const keyFrames = JSON.parse(
            response2.data.data.attributes.keyFrames
          ).frame.map((frame: number[][]) => frame.slice(0, 33));

          const transformedData: ExtendedTransformedViewTechniqueByIdData = {
            ...transformedDataInit,
            insstructorkeyframe: keyFrames,
          };


          const framNumbers = transformedData.keyframe.map((keyframe: any) =>
            Math.floor(keyframe.timeStamp * transformedData.fps)
          );
          if (transformedData.keyframe.length > 0) {
            setStartTime(transformedData.keyframe[0].timeStamp);
          } else {
            setStartTime(0);
          }

          setFrameNumbers(framNumbers);

          const updatedKeyframeCheck = transformedData.keyframe.map(
            (item: any) => ({
              ...item,
              frame: Math.floor(item.timeStamp * transformedData.fps),
            })
          );


          setKeyframeCheck(updatedKeyframeCheck);
          setResponseData(transformedData);
          setVideoUrl(transformedData.url);
          setJsonlst(transformedData.insstructorkeyframe);
          setLoading(false);
        } catch (error) {
          if (error instanceof Error) {
            console.log(error);
          } else {
            console.log("Error");
          }
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [selectedOption]);

  const drawSlantedLines = (
    context: CanvasRenderingContext2D,
    width: number,
    height: number,
  ) => {
    // Clear the canvas
    // context.clearRect(0, 0, width, height);

    // Set line style
    context.strokeStyle = '#3EF57E';
    context.lineWidth = 2;

    const x1 = 0.2 * width;
    const x2 = 0.8 * width;
    const y1 = 0.05 * height;
    const y2 = 0.95 * height;

    // Set rectangle style
    context.strokeStyle = "rgb(144, 238, 144)";
    context.lineWidth = 2;

    // Draw the rectangle
    context.beginPath();
    context.rect(x1, y1, x2 - x1, y2 - y1);
    context.stroke();
  };


  /**
   * Effect to process landmarks and compute similarity, manage state, and provide feedback based on specified conditions.
   *
   * @param jsonlst - List of keyframe landmarks.
   * @param isKeyFrame - Boolean indicating if the current frame is a keyframe.
   * @param currentLandmarks - Current landmarks detected in the frame.
   * @param currentSimilarityScore - Current similarity score.
   * @param currentKeyframe - Index of the current keyframe.
   * @param simThreshold - Similarity threshold for continuing the process.
   * @param keyframeCheck - Callback function to check keyframes.
   * @param counter - Current frame counter.
   * @param lstContinueCounter - Last continue counter value.
   * @param handleResumeCaptureClick - Function to handle resuming capture.
   * @param t2s - Function to convert text to speech.
   * @param setCurrentSimilarityScore - Function to set the current similarity score.
   * @param setLstContinueCounter - Function to set the last continue counter.
   * @param setCurrentLandmarks - Function to set the current landmarks.
   * @param setCurrentKeyframe - Function to set the current keyframe.
   * @param setIsKeyFrame - Function to set the keyframe state.
   * @param setBeginPause - Function to set the pause state.
   */
  useEffect(() => {
    try {
      if (
        jsonlst &&
        typeof isKeyFrame == "boolean" &&
        currentLandmarks &&
        typeof currentKeyframe != "undefined" &&
        currentKeyframe != null &&
        currentLandmarks.length !== null &&
        currentLandmarks.length !== undefined &&
        currentLandmarks?.length > 0
      ) {
        const jsonlst_result = jsonlst[Number(currentKeyframe)];
        const currentLandmarks_lst: [number, number, number][] =
          currentLandmarks.map((item: CoorinatesXYZ) => [
            item.x,
            item.y,
            item.z,
          ]);

        const sim = compute_similarity_vector(
          jsonlst_result,
          currentLandmarks_lst,
          keyframeCheck,
          currentKeyframe
        );
        setCurrentSimilarityScore(sim);

        console.log({
          sim: sim,
        });

        if (
          sim >= simThreshold &&
          checkVisibility(currentLandmarks) &&
          checkXValue(currentLandmarks)
        ) {
          if (lstContinueCounter < counter - 10) {
            t2s("Continue", 1);
            setLstContinueCounter(counter);
          }
          handleResumeCaptureClick();
          setCurrentLandmarks([]);
          setCurrentKeyframe(null);
          setIsKeyFrame(false);
          setBeginPause(false);
        } else {
          console.log("waiting to be in frame");
        }
      } else {
        console.log(
          "NOT jsonlst && typeof isKeyFrame == 'boolean' && currentLandmarks && typeof currentKeyframe != 'undefined' && currentKeyframe != null "
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [currentLandmarks, currentKeyframe, isKeyFrame]);

  /**
   * Effect to handle stopping the capture and providing feedback when the playback position reaches or exceeds 85%.
   *
   * @param played - The current playback position as a percentage (0 to 1).
   * @param capturing - Boolean indicating if the capturing is in progress.
   * @param handleStopCaptureClick - Function to handle stopping the capture.
   * @param t2s - Function to convert text to speech.
   * @param setCurrentSimilarityScore - Function to set the current similarity score.
   */
  useEffect(() => {
    if (played >= 0.85 && capturing) {
      const timer = setTimeout(() => {
        handleStopCaptureClick();
        t2s("Recording Ended", 1);
        setCurrentSimilarityScore(null);
        setRecordingDone(true);
      }, 3000); // 3000 milliseconds = 3 second

    }
  }, [played]);

  /**
   * Handles the change event for a select element, updating the selected option state.
   *
   * @param event - The change event triggered by the select element.
   */
  const handleOptionChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setSelectedOption(event.target.value);
    },
    [setSelectedOption]
  );

  /**
   * Handles the change event for a select element, updating the selected level and adjusting the similarity threshold accordingly.
   *
   * @param event - The change event triggered by the select element.
   */
  const handleMobileLevelChange = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedLevel = event.target.value;
    setLevel(selectedLevel);

    switch (selectedLevel) {
      case "Beginner":
        setSimThreshold(0.6);
        break;
      case "Intermediate":
        setSimThreshold(0.675);
        break;
      case "Advance":
        setSimThreshold(0.8);
        break;
      default:
        break;
    }
  };

  const videoConstraints = {
    facingMode: "user"
  };

  /**
   * Handles the change event for an input element, updating the similarity threshold.
   *
   * @param event - The change event triggered by the input element.
   */
  const handleThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSimThreshold(parseFloat(event.target.value));
  };

  /**
   * Handles the change event for an input element, updating the similarity threshold for scoring.
   *
   * @param event - The change event triggered by the input element.
   */
  const handleThresholdScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSimThresholdScoring(parseFloat(event.target.value));
  };

  /**
   * Handles the change event for an input element, updating the similarity threshold for scoring.
   *
   * @param event - The change event triggered by the input element.
   */
  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setVideo(event.target.files[0]);
    }
  };

  /**
   * Starts the timer and countdown for the capture process, providing audio feedback for the countdown.
   */
  const handleStartTimer = () => {
    if (selectedOption && !loading) {
      setStart(true);
      setTimer(true);
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          return prevCountdown - 1;
        });
      }, 1000);

      for (let x = 3; x > 0; x--) {
        t2s(`${x}`, 1.5);
      }

      setTimeout(() => {
        setTimer(false);
        clearInterval(Number(interval));
        handleStartCaptureClick();
        setCountdown(3);
      }, 3000);
    } else if (loading) {
      alert("Please wait for user data to be loaded.");
    } else {
      alert("Select a technique first.");
    }
  };

  /**
   * Pauses the media recording and updates the capturing state.
   */
  const handlePauseCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.pause();
      setCapturing(false);
      setRecordingPaused(true);
    }
  }, [mediaRecorderRef, setCapturing]);

  /**
   * Stops the media recording and updates the capturing state.
   */
  const handleStopCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, setCapturing]);

  /**
   * Stops the media recording and updates the capturing state.
   */
  const handleResumeCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.resume();
      setCapturing(true);
      setRecordingPaused(false);
    }
  }, [mediaRecorderRef, setCapturing]);

  /**
   * Draws a green rectangle on the canvas context.
   *
   * @param canvasCtx - The 2D rendering context of the canvas element.
   * @param canvasWidth - The width of the canvas.
   * @param canvasHeight - The height of the canvas.
   */
  const drawRectangle = (
    canvasCtx: CanvasRenderingContext2D | null,
    canvasWidth: number,
    canvasHeight: number
  ) => {
    if (!canvasCtx) return;

    // Clear previous drawings
    // canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);

    // Calculate rectangle coordinates
    const x1 = 0.2 * canvasWidth;
    const x2 = 0.8 * canvasWidth;
    const y1 = 0.05 * canvasHeight;
    const y2 = 0.95 * canvasHeight;

    // Set rectangle style
    canvasCtx.strokeStyle = "rgb(144, 238, 144)";
    canvasCtx.lineWidth = 2;

    // Draw the rectangle
    canvasCtx.beginPath();
    canvasCtx.rect(x1, y1, x2 - x1, y2 - y1);
    canvasCtx.stroke();
  };

  /**
   * Handles the available data from the media recorder, adding it to the recorded chunks.
   *
   * @param event - The event containing the available data.
   * @param event.data - The available data as a BlobPart.
   */
  const handleDataAvailable = useCallback(({ data }: { data: BlobPart }) => {
    if (data && typeof data === "object" && "size" in data && data.size > 0) {
      // Wrap data in an array before concatenating
      setRecordedChunks((prev) => prev.concat([data as Blob]));
    }
  }, []);

  /**
   * Starts capturing video from the webcam, initializing the MediaRecorder.
   */
  const handleStartCaptureClick = useCallback(() => {
    if (
      webcamRef.current &&
      webcamRef.current.video &&
      !mediaRecorderRef.current
    ) {
      const stream = webcamRef.current.video.srcObject as MediaStream;
      if (stream) {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        mediaRecorderRef.current.start();
        setCapturing(true);
        setBeginPause(true);
      }
    }
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  /**
   * Downloads the recorded video chunks as a webm file.
   */
  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    } else {
      alert("Recording was unsuccessful");
    }
  }, [recordedChunks]);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async () => {
    if (!video) {
      alert("No video selected");
      return;
    }

    setUploading(true);
    const uploadPreset = process.env.REACT_APP_STAPI_TOKKEN;
    if (!uploadPreset) {
      throw new Error("REACT_APP_STAPI_TOKKEN is not defined");
    } else {
      try {
        const formData = new FormData();
        formData.append("files", video);
        // formData.append("upload_preset", uploadPreset);

        const response = await axios.post(
          `${process.env.REACT_APP_STRAPI_URL}/api/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STAPI_TOKKEN}`,
            },
          }
        );

        if (response.status === 200) {
          try {
            const response2 = await axios.post(
              `${process.env.REACT_APP_BASE_URL}api/v1/techniques/checkVideo_Strapi`,
              {
                name: name,
                email: email,
                url: response.data[0].url,
                technique_id: selectedOption,
                techniqueName: techniqueName,
                scoringThereshold: simThresholdScoring,
                student_id: `${student.activeStudent.id}`
              },
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_STAPI_TOKKEN}`,
                },
              }
            );
            if (response2.status === 200) {
              alert(
                "Successfully Sent."
              );
            } else {
              console.error("Upload failed:", response2.statusText);
              alert("Network Error, please submit again");
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        } else {
          console.error("Upload failed:", response.statusText);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error uploading video:", error);
          alert(`Error uploading video: ${error.message}`);
        } else {
          console.error("Unknown error uploading video:", error);
          alert("Unknown error uploading video");
        }
      } finally {
        setUploading(false);
      }
    }
  };

  const handleSubmit = useCallback(async () => {
    setisSubmitting(true);
    if (recordedChunks.length) {
      try {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });

        const formData = new FormData();
        formData.append("files", blob);
        console.log(`${process.env.REACT_APP_BASE_URL}api/upload`)
        const response = await axios.post(
          `${process.env.REACT_APP_STRAPI_URL}/api/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STAPI_TOKKEN}`,
            },
          }
        );

        if (response.status === 200) {
          try {
            console.log(`${process.env.REACT_APP_BASE_URL}api/v1/techniques/checkVideo_Strapi`)
            const response2 = await axios.post(
              `${process.env.REACT_APP_BASE_URL}api/v1/techniques/checkVideo_Strapi`,
              {
                name: name,
                email: email,
                url: response.data[0].url,
                technique_id: selectedOption,
                techniqueName: techniqueName,
                scoringThereshold: simThresholdScoring,
                student_id: `${student.activeStudent.id}`
              },
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_STAPI_TOKKEN}`,
                },
              }
            );
            if (response2.status === 200) {
              alert(
                "Successfully sent, You will be notified of the result through mail."
              );
            } else {
              console.error("Upload failed:", response2.statusText);
              alert("Network Error, please submit again");
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        } else {
          console.error("Upload failed:", response.statusText);
        }
        setRecordedChunks([]);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setFrameNumbers([]);
        setVideo(null);
        setVideoUrl(null);
        setStart(false);
        setSelectedOption(null);
        setUploading(false);
        setMode("OPTIONS");
        navigate(-1);
      }
    } else {
      alert("Recording was unsuccessful");
    }
  }, [recordedChunks]);

  const onResults = (results: Results) => {
    if (
      mode === "RECORD" &&
      webcamRef &&
      webcamRef.current &&
      webcamRef.current.video &&
      canvasRef &&
      canvasRef.current
    ) {
      if (
        webcamRef.current.video.videoWidth !== null &&
        webcamRef.current.video.videoHeight !== null
      ) {
        const videoElement = webcamRef.current.video;
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;

        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext("2d");

        if (canvasCtx) {
          canvasCtx.save();
          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

          canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );

          if (typeof results.poseLandmarks != "undefined") {
            setIsCameraLoading(false)
            setCurrentLandmarks(results.poseLandmarks);
          }
          else {
            setCurrentSimilarityScore(0.00)
          }

          drawConnectors(
            canvasCtx,
            results.poseLandmarks,
            mediapipePose.POSE_CONNECTIONS,
            {
              color: "#FFFFFF77",
              lineWidth: 8
            }
          );

          drawLandmarks(canvasCtx, results.poseLandmarks, {
            color: "#F9C954",
            lineWidth: 2,
            radius: 8,
          });

          drawSlantedLines(canvasCtx, videoWidth, videoHeight)

          // drawScoreOnCanvas(canvasCtx, videoWidth, videoHeight, results.poseLandmarks)

          canvasCtx.restore();
        }
      }
    }
  };
  const Loader = ({ children }: { children: React.ReactNode }) => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent grey background
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontSize: "3em",
        }}
      >
        {children}
      </div>
    );
  };


  const handleBackClick = () => {
    navigate(-1); // Go back to the previous route
  };

  return (
    <Layout isProfileSwitchTabVisible={true}>
      {timer && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "8em",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          {countdown}
        </div>
      )}
      <div className="flex flex-col">
        <div className={` hidden pt-8 pl-2 ${ mode === "RECORD"? "md:hidden" :"md:block"}`}>
          <img src='/assets/images/logo.svg' alt="link" />
        </div>
        <div className={`flex gap-8 md:pl-3 md:pt-4 pl-2`}>
          <IoChevronBack
            className={`text-blue-500 text-3xl mt-3 ${ mode === "RECORD"? "md:hidden" :"md:block"}  hidden`}
            onClick={handleBackClick}
          />

          <span className={`md:text-4xl text-2xl font-light md:mt-1 ${ mode === "RECORD"? "md:hidden" :"md:block"}`}>
            {techniqueName?techniqueName:"Loading..."}
          </span>
        </div>

        <div className="SubmissionPage ">

          {isSubmitting && <Loader ><div>Submitting please wait.</div></Loader>}
          {!isMobile ? (
            <div className="w-full h-full flex justify-between mr-4">
              <div className="col">
                <div className="VideoPlayer-box flex flex-col">
                  <div className={`h-16 m-8 ${ mode === "RECORD"? "hidden" :"flex"} justify-between gap-2 `}>
                    <button
                      onClick={() => {
                        setSimThreshold(0.6);
                        setLevel("Beginner");
                      }}
                      className={`p-4 w-full border rounded  ${level === "Beginner" ? "bg-[#F9C954] text-white" : "bg-white"} hover:bg-[#e1e1e1]`}
                    >
                      Beginner{" "}
                    </button>
                    <button
                      onClick={() => {
                        setSimThreshold(0.675);
                        setLevel("Intermediate");
                      }}
                      className={`p-4 w-full border rounded  ${level === "Intermediate" ? "bg-[#F9C954] text-white" : "bg-white"} hover:bg-[#e1e1e1]`}
                    >
                      Intermediate{" "}
                    </button>
                    <button
                      onClick={() => {
                        setSimThreshold(0.8);
                        setLevel("Advance");
                      }}
                      className={`p-4 w-full border rounded  ${level === "Advance" ? "bg-[#F9C954]" : "bg-white"} hover:bg-[#e1e1e1]`}
                    >
                      Advance{" "}
                    </button>
                  </div>
                  <div className="bg-[#D8D8D8] gap-2">
                    {videoUrl !== null && (
                      <>
                        {/* <h1>videoUrl !== null</h1> */}
                        <div
                          style={{
                            position: "relative",
                            fontSize: "1em",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              height: "2em",
                              display: "flex",
                              flexDirection: "row",
                              margin: ".5em",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              style={{
                                borderWidth: 0,
                              }}
                            >
                              {" "}
                              <FaArrowLeft /> {"Right"}
                            </button>
                            <button
                              style={{
                                borderWidth: 0,
                              }}
                            >
                              {" "}
                              {"Left"} <FaArrowRight />
                            </button>
                          </div>
                          {responseData && videoUrl !== null && (
                            <VideoPlayer
                              capturing={capturing}
                              recordingPaused={recordingPaused}
                              setCapturing={setCapturing}
                              handlePauseCaptureClick={handlePauseCaptureClick}
                              setRecordingPaused={setRecordingPaused}
                              setIsKeyFrame={setIsKeyFrame}
                              setCurrentKeyframe={setCurrentKeyframe}
                              handleStopCaptureClick={handleStopCaptureClick}
                              url={videoUrl}
                              fps={30}
                              keyframes={frameNumbers}
                              start={start}
                              setStart={setStart}
                              setPlayed={setPlayed}
                              height_new={`${ mode === "RECORD"? "90vh" :"30em"}`}
                              startTime={startTime}
                              beginPause={beginPause}
                            />
                          )}
                        </div>
                      </>
                    )}
                    {videoUrl == null && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <img src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif" alt="Loading GIF" />
                      </div>
                    )}
                  </div>

                </div>
              </div>
              <div className="col flex flex-start top-0" style={{ gap: 0 }}>
                <div className="simThreshold h-20 text-2xl ">
                  <h1 className="w-full flex flex-row justify-between">
                    <p className="min-w-[30%]">Level: {level}{" "}</p>
                    {currentSimilarityScore && <p className={`min-w-[30%] text-center ${currentSimilarityScore ? currentSimilarityScore > simThreshold ? "bg-green-500 text-white" : "bg-red-600 text-white" : "bg-red-600 text-white"}`}>{currentSimilarityScore &&
                      `${Math.round(
                        currentSimilarityScore * 100
                      ).toString()}%`}{" "}</p>}
                  </h1>

                </div>
                {mode === "OPTIONS" ? (
                  <div className="WebcamDivOptions h-[480px]">
                    <button
                      className={`btn ${mode !== "OPTIONS" ? "disabled" : ""} ${selectedOption ? "" : "disabled"
                        }`}
                      onClick={() => {
                        if (mode === "OPTIONS") {
                          setVideoUrl(responseData.url);
                          setMode("RECORD");
                        }
                      }}
                      disabled={mode !== "OPTIONS" || !selectedOption}
                    >
                      <BiVideoRecording size={45} className="Icon" />
                      <p>Record Video</p>
                    </button>
                    <button className="btn" onClick={() => setMode("UPLOAD")}>
                      <IoCloudUploadOutline size={45} className="Icon" />
                      <p>Upload Video</p>
                    </button>
                  </div>
                ) : mode === "RECORD" ? (
                  <div className="WebcamDiv">
                    <div className="toolbar">
                      <div>
                        <button className="flex flex-row gap-2 items-center p-2 hover:bg-black  w-24" onClick={() => setMode("OPTIONS")}>
                          {" "}
                          <IoIosArrowBack /> Back
                        </button>
                      </div>
                      {capturing && !recordingPaused ? (
                        <div>
                          {played >= 0.9 && (
                            <button onClick={handleStopCaptureClick}>
                              Stop Capture
                            </button>
                          )}
                          <button onClick={handlePauseCaptureClick}>
                            Pause Capture
                          </button>
                        </div>
                      ) : !capturing && recordingPaused ? (
                        <div>
                          {played >= 0.95 && (
                            <button onClick={handleStopCaptureClick}>
                              Stop Capture
                            </button>
                          )}
                        </div>
                      ) : (
                        <div>
                          {canvasRef && (
                            <button onClick={handleStartTimer}>
                              Start Capture
                            </button>
                          )}
                        </div>
                      )}
                      {recordedChunks.length > 0 && (
                        <div>
                          <button onClick={handleDownload}>Download</button>
                          <button onClick={handleSubmit}>Submit</button>
                        </div>
                      )}
                    </div>
                    {isCameraLoading && <Loader><div>Loading camera...</div></Loader>}
                    {!recordingDone ? (
                      <div
                        style={{
                          position: "absolute",
                        }}
                        className="w-full"
                      >
                        <Webcam
                          ref={webcamRef}
                          videoConstraints={videoConstraints}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            right: 0,
                            textAlign: "center",
                            zIndex: 9,
                            width: "100%",
                            height: "35rem",
                            display: "none",
                            visibility: "hidden"
                          }}
                        />
                        <div className="flex  w-[830px] bg-inherit">
                          <canvas
                            ref={canvasRef}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              right: 0,
                              // top:0,
                              textAlign: "center",
                              zIndex: 10,
                              // width: "100%",
                              // height: "480px",
                              transform: "scaleX(-1)",
                              // display: isCameraLoading ? "none" : "block",
                            }}
                            className="w-[100%] h-[480px]"
                          />
                        </div>

                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            right: 0,
                            textAlign: "center",
                            zIndex: 100,
                            width: "100%",
                            height: "35rem",
                            background: "transparent",
                            // marginTop: "-75%",
                          }}
                        >
                          Thanks for recording submit now
                        </div>
                      </>
                    )}
                  </div>
                ) : mode === "UPLOAD" ? (
                  <div className="WebcamDivOptions">
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleVideoChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    <div
                      className={`btn ${!selectedOption ? "disabled" : ""}`}
                      onClick={selectedOption ? handleClick : () => { }}
                      style={{
                        opacity: selectedOption ? 1 : 0.5,
                        cursor: selectedOption ? "pointer" : "not-allowed",
                      }}
                    >
                      <IoCloudUploadOutline size={45} className="Icon" />
                      {!video && <p>Select File</p>}
                      {video && (
                        <div>
                          <p>{video.name}</p>
                          <p>Click to change</p>
                        </div>
                      )}
                    </div>
                    {video && (
                      <div className="btn" onClick={handleUpload}>
                        <IoMdSend size={45} className="Icon" />
                        <p>Submit</p>
                      </div>
                    )}
                    <div className="btn" onClick={() => setMode("OPTIONS")}>
                      <IoIosArrowBack size={45} className="Icon" />
                      <p>Back</p>
                    </div>
                  </div>
                ) : (
                  <div>Default Div</div>
                )}
              </div>
            </div>
          ) : (
            <div className="mobileCol">
              <div className="col" style={{ gap: 0 }}>
                {mode === "OPTIONS" && (
                  <div className="sim-DropDown flex flex-row justify-between">
                    <label> Level:</label>

                    <label>
                      <input
                        type="radio"
                        value="Beginner"
                        checked={level === "Beginner"}
                        onChange={handleMobileLevelChange}
                      />
                      Beginner
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="Intermediate"
                        checked={level === "Intermediate"}
                        onChange={handleMobileLevelChange}
                      />
                      Intermediate
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="Advance"
                        checked={level === "Advance"}
                        onChange={handleMobileLevelChange}
                      />
                      Advance
                    </label>

                  </div>
                )}
              </div>
              <div className="col flex flex-col gap-4">
                <div className="VideoPlayer-box">
                  {videoUrl !== null && (
                    <div
                      style={{
                        position: "relative",
                        fontSize: "1em",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          height: "2em",
                          display: "flex",
                          flexDirection: "row",
                          margin: ".5em",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{
                            borderWidth: 0,
                          }}
                        >
                          {" "}
                          <FaArrowLeft /> {"Right"}
                        </button>
                        <button
                          style={{
                            borderWidth: 0,
                          }}
                        >
                          {" "}
                          {"Left"} <FaArrowRight />
                        </button>
                      </div>
                      {responseData && videoUrl !== null && (
                        <VideoPlayer
                          capturing={capturing}
                          recordingPaused={recordingPaused}
                          setCapturing={setCapturing}
                          handlePauseCaptureClick={handlePauseCaptureClick}
                          setRecordingPaused={setRecordingPaused}
                          setIsKeyFrame={setIsKeyFrame}
                          setCurrentKeyframe={setCurrentKeyframe}
                          handleStopCaptureClick={handleStopCaptureClick}
                          url={videoUrl}
                          fps={30}
                          keyframes={frameNumbers}
                          start={start}
                          setStart={setStart}
                          setPlayed={setPlayed}
                          height_new={"30vh"}
                          startTime={startTime}
                          beginPause={beginPause}
                        />
                      )}
                    </div>
                  )}
                  {videoUrl == null && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img
                        className="mobileVideoPlayerBoxDummy"
                        src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif"
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col">
                {mode === "OPTIONS" && (
                  <div className="WebcamDivOptions p-4" style={{ width: "85%" }}>
                    <button
                      className={`btn ${mode !== "OPTIONS" || !selectedOption ? "disabled" : ""
                        }`}
                      onClick={() => {
                        if (mode === "OPTIONS" && selectedOption) {
                          setVideoUrl(responseData.url);
                          setMode("RECORD");
                        }
                      }}
                      disabled={mode !== "OPTIONS" || !selectedOption}
                    >
                      <BiVideoRecording size={45} className="Icon" />
                      <p>Record Video</p>
                    </button>
                    <button
                      className={`btn ${mode !== "OPTIONS" || !selectedOption ? "disabled" : ""
                        }`}
                      onClick={() => setMode("UPLOAD")}
                    >
                      <IoCloudUploadOutline size={45} className="Icon" />
                      <p>Upload Video</p>
                    </button>
                  </div>
                )}
              </div>
              <div className="col">
                {mode === "RECORD" && (
                  <div className="WebcamDiv">
                    <div className="toolbar">
                      <div>
                        <button onClick={() => setMode("OPTIONS")}>
                          {" "}
                          <IoIosArrowBack /> Back
                        </button>
                      </div>
                      {capturing && !recordingPaused ? (
                        <div>
                          {played >= 0.9 && (
                            <button onClick={handleStopCaptureClick}>
                              Stop Capture
                            </button>
                          )}
                          <button onClick={handlePauseCaptureClick}>
                            Pause Capture
                          </button>
                        </div>
                      ) : !capturing && recordingPaused ? (
                        <div>
                          {played >= 0.95 && (
                            <button onClick={handleStopCaptureClick}>
                              Stop Capture
                            </button>
                          )}
                        </div>
                      ) : (
                        <div>
                          <button onClick={handleStartTimer}>Start Capture</button>
                        </div>
                      )}
                      {recordedChunks.length > 0 && (
                        <div>
                          <button onClick={handleDownload}>Download</button>
                          <button onClick={handleSubmit}>Submit</button>
                        </div>
                      )}
                    </div>
                    {isCameraLoading &&  <Loader><div>Loading camera...</div></Loader>}
                    {!recordingDone ? (
                      <div
                        style={{
                          position: "absolute",
                        }}
                      >
                        <Webcam
                          ref={webcamRef}
                          videoConstraints={videoConstraints}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            right: 0,
                            textAlign: "center",
                            zIndex: 9,
                            width: "30vh",
                            height: "35em",
                            display: "none",
                          }}
                        />
                        <canvas
                          ref={canvasRef}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            right: 0,
                            textAlign: "center",
                            zIndex: 10,
                            width: "0vw",
                            height: "30vh",
                            transform: "scaleX(-1)",
                            // display: isCameraLoading ? "none" : "block",
                          }}
                        />
                        <canvas
                          ref={scoreCanvasRef}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            right: 0,
                            textAlign: "center",
                            zIndex: 100,
                            width: "100%",
                            height: "30vh",
                            background: "transparent",

                            // display: isCameraLoading ? "none" : "block",
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            right: 0,
                            textAlign: "center",
                            zIndex: 100,
                            width: "100%",
                            height: "35rem",
                            background: "transparent",
                          }}
                        >
                          Thanks for recording submit now
                        </div>
                      </>
                    )}

                  </div>
                )}
                {mode === "UPLOAD" && (
                  <div className="WebcamDivOptions">
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleVideoChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    <div className="btn" onClick={handleClick}>
                      <IoCloudUploadOutline size={45} className="Icon" />
                      {!video && <p>Select File</p>}
                      {video && (
                        <div>
                          <p>{video.name}</p>
                          <p>Click to change</p>
                        </div>
                      )}
                    </div>
                    {video && (
                      <div className="btn" onClick={handleUpload}>
                        <IoMdSend size={45} className="Icon" />
                        <p>Submit</p>
                      </div>
                    )}
                    <div className="btn" onClick={() => setMode("OPTIONS")}>
                      <IoIosArrowBack size={45} className="Icon" />
                      <p>Back</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default TechniqueTryPage