import React from 'react'
import {
  useDragControls,
  useMotionValue,
  useAnimate,
  motion,
} from "framer-motion";
import useMeasure from 'react-use-measure';

interface DragCloseDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  background?: string;
}


const DragCloseDrawer = ({ open, setOpen, children, background }: DragCloseDrawerProps) => {
    const [scope, animate] = useAnimate();
    const [drawerRef, { height }] = useMeasure();
  
    const y = useMotionValue(0);
    const controls = useDragControls();
  
    const handleClose = async () => {
      animate(scope.current, {
        opacity: [1, 0],
      });
  
      const yStart = typeof y.get() === "number" ? y.get() : 0;
  
      await animate("#drawer", {
        y: [yStart, height],
      });
  
      setOpen(false);
    };
  
    return (
      <>
        {open && (
          <motion.div
            ref={scope}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onClick={handleClose}
            className="fixed inset-0 z-[999999] bg-neutral-950/70"
          >
            <motion.div
              id="drawer"
              ref={drawerRef}
              onClick={(e) => e.stopPropagation()}
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{
                ease: "easeInOut",
              }}
              className={`absolute bottom-0 h-auto max-h-[90vh] w-full overflow-hidden rounded-t-3xl pb-[20px] bg-neutral-900}`}
              style={{ y, background}}
              drag="y"
              dragControls={controls}
              onDragEnd={() => {
                if (y.get() >= 100) {
                  handleClose();
                }
              }}
              dragListener={false}
              dragConstraints={{
                top: 0,
                bottom: 0,
              }}
              dragElastic={{
                top: 0,
                bottom: 0.5,
              }}
            >
              <div style={{ background }} className={`absolute left-0 right-0 top-0 z-10 flex justify-center bg-neutral-900 p-4`}>
                <button
                  onPointerDown={(e) => {
                    controls.start(e);
                  }}
                  className="h-2 w-14 cursor-grab touch-none rounded-full bg-neutral-700 active:cursor-grabbing"
                ></button>
              </div>
              <div className='flex items-center justify-center w-full'>
                <div className="relative z-0 h-full overflow-y-auto p-4 pt-12 thin-srollbar w-full md:w-[600px]">
                  {children}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </>
    );
  };

export default DragCloseDrawer