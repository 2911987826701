import React from 'react';
import LoaderTillCamera from '../../components/common/loaderTillCamera';
import FitnessPageViewModel from '../../view-models/fitness-viewmodel';
import Layout from '../../components/common/layout';
import DragCloseDrawer from '../../components/common/drag-close-drawer';
import Webcam from 'react-webcam';

const FitnessPage: React.FC = () => {

    const {
        techniqueScores,
        loading,
        webcamRef,
        canvasRef,
        techniqueMode,
        displayInstructionStop,
        showModal,
        confirmStop,
        cancelStop,
        showOverlay,
        onGoing,
        startCountdown,
        handelNext,
        pause,
        handelPause,
        handelContinue,
        handelStop,
        techniqueTiming,
        pauseTiming,
        countdown,
        cancelInstructionStop,
        showRes,
        goHome,
        showRestTimer,
        deviceId,
        devices,
        setDeviceId,
        videoRef,
        hasPermission,
        handleInstructionButtonClick,
        isNative,
        disclaimerAccepted,
        setShowDisclaimerModal,
        setShowDetailedExcerciseCounter,
        showCameraSwitchOptions,
        setShowCameraSwitchOptions,
        showDetailedExcerciseCounter,
        showDisclaimerModal,
        handleDisclaimerAccept,
        handleDisclaimerCancel,
        showInstructions,
        setShowInstructions,
        setDisclaimerAccepted,
        setIsWebcamReady,
        rotateCamFeed,
    } = FitnessPageViewModel();

    return (
        <Layout isProfileSwitchTabVisible={false} hideMobileBottomBar={true} hideMobileTopBar={true} noPadding={true}>
            <div className="w-full h-screen">
                {/* <div className="sm:block hidden pt-8 pl-2">
                    <img src={logo} alt="link" />
                </div> */}
                <div className="flex w-full h-full items-center justify-center relative">
                    {/* {loading && <LoaderTillCamera />} */}
                    {hasPermission ? (
                        <>
                            <video
                                ref={videoRef}
                                style={{ display: 'none' }}
                                autoPlay
                                playsInline
                                muted
                            />
                            <Webcam 
                                ref={webcamRef} 
                                style={{ display: 'none' }}
                                audio={false}
                                videoConstraints={{ deviceId: deviceId }}
                                onUserMedia={(stream) => {
                                    if(videoRef.current) {
                                        videoRef.current.srcObject = stream;
                                        videoRef.current.play();
                                    }
                                    setIsWebcamReady(true);
                                }}
                                // onAbort={() => {
                                //     setIsWebcamReady(false);
                                //     if (videoRef.current && videoRef.current.srcObject) {
                                //         const mediaStream = videoRef.current.srcObject as MediaStream;
                                //         mediaStream.getTracks().forEach(track => track.stop());
                                //         videoRef.current.srcObject = null;
                                //     }
                                // }}
                            />
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center">Camera permission required</div>
                    )}
                    <canvas
                        ref={canvasRef}
                        className="w-full h-full"
                        style={{
                            transform: rotateCamFeed ? "scaleX(-1)" : "scaleX(1)",
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 10,
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            objectFit: 'cover',
                        }}
                    />

                    {showOverlay && (
                        <div className="fixed inset-0 flex animate-pulse flex-col items-center justify-center bg-black bg-opacity-80 backdrop-blur-sm z-30 border-8 border-red-500 border-opacity-60">
                            <div className="text-yellow-400 text-4xl text-center font-semibold p-4 rounded-md">
                                Improper visibility due to lighting conditions or body not fully in frame.
                            </div>
                        </div>
                    )}
                    <div className='absolute top-0 left-0 h-full w-full z-[50]'>
                        <div className='absolute flex flex-col gap-2 items-center top-4 right-4 '>
                            <div 
                                onClick={handleInstructionButtonClick}
                                className={`${isNative ? 'mt-4' : ''} h-14 w-14 rounded-full bg-[#3ab6fc] bg-opacity-40 scale-75 hover:scale-100 transition-all duration-300 cursor-pointer hover:bg-opacity-100`}>
                                <img 
                                    src='/assets/images/question.png'
                                    className='invert'
                                />
                            </div>
                            {devices?.length > 1 && (
                                <div className=''>
                                    <div className='relative'>
                                        {showCameraSwitchOptions && (
                                            <div className='absolute top-full right-0 flex flex-col p-1 gap-1 rounded w-[180px]'>
                                                {devices.map((device, index) => (
                                                    <div 
                                                        onClick={() => {
                                                            setDeviceId(device.deviceId);
                                                            setShowCameraSwitchOptions(false);
                                                        }} 
                                                        key={device.deviceId} 
                                                        className='p-2 bg-white rounded cursor-pointer hover:bg-gray-200'
                                                    >
                                                        {device.label || `Camera ${index + 1}`}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <img 
                                            src='/assets/images/camera-switch.png'
                                            className='h-10 w-10 rounded-full invert cursor-pointer'    
                                            onClick={() => {
                                                if(devices.length === 2) {
                                                    setDeviceId(devices.filter(device => device.deviceId !== deviceId)[0].deviceId);
                                                }else{
                                                    setShowCameraSwitchOptions(prev => !prev)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {onGoing ? (
                            <div className='flex justify-center w-full items-center absolute bottom-4 z-[10]'>
                                <div className="flex flex-row justify-between gap-y-4 w-[93%] md:w-[600px]">
                                    <button
                                        className="active:scale-95 transition-all flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
                                        onClick={handelStop}>
                                        Exit
                                    </button>
                                    {pause ? (
                                        <button
                                            className="active:scale-95 transition-all flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
                                            onClick={handelContinue}>
                                            Resume
                                        </button>
                                    ) : (
                                        <button
                                            className="active:scale-95 transition-all flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
                                            onClick={handelPause}>
                                            Pause
                                        </button>
                                    )}
                                    <button
                                        className="active:scale-95 transition-all flex-1 p-4 w-[30vw] text-white font-bold bg-red-400 m-2 rounded-xl"
                                        onClick={handelNext}>
                                        Skip
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='flex justify-center w-full items-center absolute bottom-4'>
                                {!loading &&
                                    <button 
                                        onClick={() => {
                                            disclaimerAccepted ? startCountdown() : setShowDisclaimerModal(true);
                                        }}
                                        className="active:scale-95 whitespace-nowrap px-10 py-3 text-lg font-semibold rounded-md border-2 border-[#1BFD9C] text-[#1BFD9C] transition-all duration-300 overflow-hidden shadow-[inset_0_0_10px_rgba(27,253,156,0.4),0_0_9px_3px_rgba(27,253,156,0.1)] hover:text-[#82ffc9] hover:shadow-[inset_0_0_10px_rgba(27,253,156,0.6),0_0_9px_3px_rgba(27,253,156,0.2)]">
                                        <span 
                                            className="absolute left-[-4em] w-16 h-full top-0 transition-transform duration-400 ease-in-out bg-gradient-to-r from-transparent via-[rgba(27,253,156,0.1)] to-transparent hover:translate-x-[15em]"
                                        ></span>
                                        I'M READY
                                    </button>
                                }
                            </div>
                        )}
                        {countdown !== null && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full h-full animate-ping">
                                <div className="text-white text-9xl">
                                    {countdown}
                                </div>
                            </div>
                        )}
                        {(onGoing && techniqueMode !== 'Pause' && countdown === null) && (
                            <>
                                <div className={`mt-4 absolute font-semibold text-2xl top-4 left-4 h-14 w-14 rounded-full bg-[#3ab6fc] flex items-center justify-center`}>
                                    <span className={`${techniqueTiming && techniqueTiming <= 10 ? 'text-red-500 animate-ping' : 'text-white'}`}>{techniqueTiming}</span>
                                </div>
                                <div 
                                    className='absolute font-semibold text-2xl text-white bottom-24 w-full flex items-center justify-center cursor-pointer' 
                                    onClick={() => setShowDetailedExcerciseCounter(true)}
                                >
                                    <div className='bg-[#3ab6fc] rounded-full flex items-center justify-center p-1 gap-2'>
                                        <div className='px-6'>{techniqueMode}</div>
                                        <div className='h-12 w-auto px-2 rounded-full bg-white text-[#3ab6fc] flex items-center justify-center'>
                                            {Object.entries(techniqueScores).find(([techniqueName, score]) => techniqueName === techniqueMode)?.[1].toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {(onGoing && techniqueMode === 'Pause' && countdown === null) && (
                            <div className='absolute top-0 left-0 h-full w-full flex items-center justify-center'>
                                <div className='p-4 rounded-lg bg-[#3ab6fc] text-white text-xl font-semibold'>
                                    Starting the next technique in <span className='text-[#febd59] text-4xl px-2'>{pauseTiming}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <DragCloseDrawer open={showDetailedExcerciseCounter} setOpen={() => setShowDetailedExcerciseCounter(false)} background='#11181f'>
                <div className="flex flex-col gap-2 text-2xl w-full text-white">
                    {Object.entries(techniqueScores).map(
                        ([techniqueName, score]) => (
                            <div
                                key={techniqueName}
                                className={`flex px-10 flex-row justify-between w-full ${techniqueName === techniqueMode ? 'text-blue-600' : ''}`}
                            >
                                <div className="flex-1 p-2 font-bold">
                                    {techniqueName}
                                </div>
                                <div className="flex-1 p-2 font-bold text-right">
                                    {score}
                                </div>
                            </div>
                        ),
                    )}
                </div>
            </DragCloseDrawer>

            <DragCloseDrawer open={showDisclaimerModal} setOpen={() => setShowDisclaimerModal(false)} background='#11181f'>
                <div className="text-center w-full text-white">
                    <h1 className="text-2xl md:text-2xl font-bold mb-4">Important! 🚨</h1>
                    <p className="mb-4 text-lg md:text-base text-left">
                        Hey there, SpArts superstar! 🌟
                    </p>
                    <p className="mb-4 text-lg md:text-base text-left">
                        Welcome to the SpArts Exclusive AI Module! This cutting-edge tool is tailor-made for students of SpArts partner academies. Get ready to practice basic exercises and compete with your batchmates and fellow SpArts students.
                    </p>
                    <p className="mb-4 text-lg md:text-base text-left">
                        Before you dive into our awesome exercise module, have a quick chat with your physician. Safety first!
                    </p>
                    <p className="mb-4 text-lg md:text-base text-left">
                        Remember:
                    </p>
                    <ul className="list-disc list-inside mb-4 text-lg md:text-base text-left">
                        <li>Rest when needed 🛌</li>
                        <li>Never sacrifice form 🙅‍♂️</li>
                        <li>Go at your own pace 🐢</li>
                    </ul>
                    <p className="mb-4 text-lg md:text-base text-left">
                        Let the fun begin! 💪🎉
                    </p>
                    <div className="flex items-center justify-center py-4 gap-2">
                        <input
                            type="checkbox"
                            id="acceptDisclaimer"
                            className="h-4 w-4 rounded-full"
                            checked={disclaimerAccepted}
                            onChange={(e) => setDisclaimerAccepted(e.target.checked)}
                        />
                        <label htmlFor="acceptDisclaimer" className="text-lg md:text-base">
                            I understand and accept these terms of use.
                        </label>
                    </div>
                    <button
                        className="p-3 w-full md:w-40 md:mx-4 text-white font-bold bg-[#3AB7FC] disabled:opacity-30 rounded-xl mb-2 md:mb-4 transition-all"
                        onClick={handleDisclaimerAccept}
                        disabled={!disclaimerAccepted}
                    >
                        Accept
                    </button>
                    <button
                        className="p-3 w-full md:w-40 md:mx-4 text-white font-bold bg-red-400 rounded-xl"
                        onClick={handleDisclaimerCancel}
                    >
                        Cancel
                    </button>
                    <p className="mt-4 text-lg text-gray-600">
                        Best experienced on desktop
                    </p>
                </div>
            </DragCloseDrawer>
            
            <DragCloseDrawer open={showInstructions} setOpen={() => setShowInstructions(false)} background='#11181f'>
                <div className="text-left text-white">
                    <p className="mb-4 text-xl text-center md:text-base font-bold">
                        Let’s Get Moving! 🚀
                    </p>
                    <p className="mb-4 text-lg md:text-base font-bold">
                        Follow These Steps:
                    </p>
                    <ul className="list-disc pl-6 mb-4 text-lg md:text-base">
                        <li>Ensure your body is positioned correctly. ✅</li>
                        <li>Face towards your left ⬅️ and keep your body in between the two green lines 🟩🟩.</li>
                    </ul>
                    <p className="mb-4 text-lg md:text-base font-bold">
                        How it Works:
                    </p>
                    <ul className="list-disc pl-6 mb-4 text-lg md:text-base">
                        <li>
                            Each technique lasts for <strong>45 seconds ⏱️, followed by a 20-second break 🛌</strong>.
                        </li>
                        <li>You can pause ⏸️ or stop 🛑 the session using the respective buttons.</li>
                    </ul>
                    <p className="mb-4 text-lg md:text-base font-bold">
                        Techniques Order:
                    </p>
                    <ul className="list-disc pl-6 mb-4 text-lg md:text-base font-bold">
                        <li>Pushups 💪</li>
                        <li>Squats/Sit-ups 🏋️‍♂️</li>
                    </ul>
                    <p className="mb-4 text-lg md:text-base font-bold text-center">
                        Let’s crush this workout together! 💥 Ready, set, go! 🏃‍♀️🏃‍♂️
                    </p>
                </div>
            </DragCloseDrawer>
        </Layout>
    );
};

export default FitnessPage;








