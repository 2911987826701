import React, { useEffect, useState } from 'react';
import { LoginService } from '../../services/login-service';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../navigation/routes';

const ResetPasswordViewModel = () => {
    const [password,setPassword] = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    const postResetPasswordPosting = async (data: {
        code: string;
        password: string;
        passwordConfirmation: string;
    }) => {
        try {
            const response = await LoginService.instance.changePassword(data);
            if (response.success) {
                console.log("success");
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in  Reset Password: ${error}`);
            throw error;
        }
    };

    const [isPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState(false)

    const {
        mutate: resetPasswordpostData,
        isLoading: isresetPasswordpostDataLoading,
        error: isresetPasswordpostDataError,
    } = useMutation(postResetPasswordPosting, {
        onSuccess: data => {
            setIsPasswordChangeSuccess(true)
            // alert('Passsword changed successfully!');
            // navigate(Routes.Login, {replace: true});
        },
        onError: error => {
            alert('Failed to update the password! Please try again');
        },
    });

    const handleResetPasswordSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        resetPasswordpostData({
            code: code ?? '',
            password: password,
            passwordConfirmation: confirmPassword
        })
    }

    return {
        handleResetPasswordSubmit,
        isLoading: false,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        isPasswordChangeSuccess,
        navigate,
    };
}

export default ResetPasswordViewModel