import { MediaUploadResponse } from "../models/media/media-upload-response";
import { invokeApi } from "../utils/helpers/invoke-api";

export class UploadService {
    private static _instance: UploadService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new UploadService());
    }

    public uploadImage = async (data: FormData) => {
        return invokeApi<FormData, MediaUploadResponse[]>({
            route: 'upload/',
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    // public deleteImage = async (imageId: number) => {
    //     return invokeApi<unknown, DeleteImageResponse>({
    //         route: `upload/files/${imageId}`,
    //         method: 'DELETE',
    //         privateRoute: true,
    //     });
    // };
}
