import React, { useEffect, useState } from 'react'


interface BeforeInstallPromptEvent extends Event {
    prompt: () => Promise<void>;
    userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};


const PwaInstallPromptViewModel = () => {

    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
    const [isInstallPromptVisible, setIsInstallPromptVisible] = useState<boolean>(false);
    const [iosInstallPrompt, setIosInstallPrompt] = useState<boolean>(isIOS());

    useEffect(() => {
        const beforeInstallPromptHandler = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e as BeforeInstallPromptEvent);
            setIsInstallPromptVisible(true);
            console.log("✅ BeforeInstallPromptEvent fired");
        };

        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        };
    }, []);
   
    const installApp = async () => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          console.log("🆗 Installation Dialog opened");
          const { outcome } = await deferredPrompt.userChoice;
          setDeferredPrompt(null);
          if (outcome === 'accepted') {
            setIsInstallPromptVisible(false);
            console.log('😀 User accepted the install prompt.');
          } else if (outcome === 'dismissed') {
            console.log('😟 User dismissed the install prompt');
          }
        }
    };

    const isInstalledOnIos = () => {
        return window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
    }


    return {
        isInstallPromptVisible,
        setIsInstallPromptVisible,
        installApp,
        iosInstallPrompt,
        setIosInstallPrompt,
        isInstalledOnIos,
    }
}

export default PwaInstallPromptViewModel
