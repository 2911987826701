import React from 'react'
import Layout from '../../components/common/layout'
import VirtualClassesPageViewModel from '../../view-models/virtual-classes-page-viewmodel'
import FullScreenLoader from '../../components/common/full-sreen-loader';
import { formatTimeToAMPM } from '../../../utils/helpers/helpers';

const VirtualClassesPage = () => {
    const {
        isLoading,
        activeVirtualClasses,
        navigate,
    } = VirtualClassesPageViewModel();

    return (
        <Layout isProfileSwitchTabVisible={true}>
            {isLoading && <FullScreenLoader/>}
            <div className='sm:text-4xl text-2xl px-2 sm:mt-1 font-semibold drop-shadow-md my-2'>Virtual Classes</div>
            <div className='flex flex-col gap-4 p-1'>
                {activeVirtualClasses?.map((virtualClass, index) => (
                    <div 
                        key={index}
                        className="w-full p-2 rounded bg-slate-300 cursor-pointer active:scale-95 transition-all" 
                        onClick={() => navigate(`/virtual-class-room/${virtualClass?.id}`)}
                    >
                        <div className='text-2xl font-semibold'>
                            {virtualClass?.attributes?.meetingName.split('-')[0]}
                        </div>
                        <div className='flex justify-between w-full text-sm text-gray-500 mt-2'>
                            <div className=''>Started at: {formatTimeToAMPM(virtualClass?.attributes?.startTime)}</div>
                            <div className=''>Ends at: {formatTimeToAMPM(virtualClass?.attributes?.endTime)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    )
}

export default VirtualClassesPage