import ApiResponse from "../models/api/api-response";
import { VirtualMeetingDataWrapper } from "../models/virtual-meeting/virtual-meeting-data-wrapper";
import { VirtualMeetingListWrapper } from "../models/virtual-meeting/virtual-meeting-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class VirtualClassesService {
    private static _instance: VirtualClassesService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new VirtualClassesService());
    }

    public getActiveMeetingOfStudent = async (studentId: number): Promise<ApiResponse<VirtualMeetingListWrapper>> => {
        return invokeApi<null, VirtualMeetingListWrapper>({
            route: `virtual-meetings?populate=*&filters[participants][id]=${studentId}&filters[startTime][$lte]=${new Date().toISOString()}&filters[endTime][$gte]=${new Date().toISOString()}&filters[hasEnded]=false`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getActiveMeetingById = async (meetingId: number): Promise<ApiResponse<VirtualMeetingDataWrapper>> => {
        return invokeApi<null, VirtualMeetingDataWrapper>({
            route: `virtual-meetings/${meetingId}?populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    };

}