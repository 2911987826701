

import React, { useState } from 'react';
import FooterStatic from '../../components/footer-static-pages';
import NavbarStatic from '../../components/navbar-static-pages';
import { UtilityService } from '../../../services/utility-service';
import { ContactUsFormData } from '../../../utils/types/contact-us-form-data';
import { useMutation } from 'react-query';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/landing-page-items/navbar';
import Footer from '../../components/landing-page-items/footer';
import { FaUserFriends } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

const ContactUs = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [inValidMessage, setInValidMessage] = useState(false);

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const postContactFormSubmission = async (
      data: ContactUsFormData,
    ): Promise<ContactUsFormData | undefined> => {
        const response =
            await UtilityService.instance.postContactUsSubmission(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };
  
    const {
        mutate: submitContactFormData,
        isLoading: isContactFormLoading,
    } = useMutation(postContactFormSubmission, {
        onSuccess: data => {
            setFullName('');
            setEmail('');
            setMessage('');
            setIsFormSubmitted(true);
        },
        onError: error => {
            alert('Failed to process the request. Please try again!');
        },
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(message.length < 16){
          setInValidMessage(true);
          return;
        }
        submitContactFormData({ 
          fullName, 
          email, 
          message,
          origin: 'STUDENT APP'
        });
    };

    return (
        <div className='w-full min-h-screen flex flex-col'>
            <Helmet>
                <title>Contact SpArts | Reach Out for Support & Inquiries | SpArts Student Portal</title>
                <meta name='description' content={`Have questions about SpArts? Reach out to our support team via the SpArts Student Portal's Contact Us page. We’re here to assist with any inquiries regarding attendance, assignments, SpArts AI features, and more!`}/>
            </Helmet>
            <Navbar />
            <div className="flex flex-col w-full p-2 flex-grow mt-8">
                <h1 className='sm:text-4xl text-3xl font-semibold mt-8 py-6 sm:ml-4 p-2 text-center'>We’d Love to Hear from You</h1>
                <p className='sm:ml-4 my-0 font-sans text-[1.3rem] leading-8 text-[#6b6b6b] p-2 text-center'>Got a question or need support? Use the contact form to connect with us, and we’ll respond as soon as possible to assist you.</p>

                <div className='flex sm:flex-row flex-col items-center justify-center sm:gap-12'>
                    {/* Contact details */}
                    <div className="w-[500px] sm:p-10 p-20">
                        
                        <p className='text-[#34404e] font-semibold mb-1 flex items-center gap-2'>
                            <span><FaUserFriends /></span>
                            <span>Visit Us</span>
                        </p>
                        <p className='m-0'>SpArts Technologies<br/>
                        2nd Floor<br/>
                        65/1, C.M. Muniyallappa tower<br/>
                        Near Nandini Junction, Choodasandra<br/>
                        Bangaluru - 560099</p>
                        
                        <p className='text-[#34404e] font-semibold mt-5 mb-1 flex items-center gap-2'>
                            <span><MdEmail /></span>
                            <span>Email Us</span>
                        </p>
                        <p className='m-0'>info@sparts.app</p>

                        <p className='text-[#34404e] font-semibold mt-5 mb-1 flex items-center gap-2'>
                            <span><IoCall /></span>
                            <span>Call Us</span>
                        </p>
                        <p className='m-0'>8095192069 9085008017</p>

                        <p className='text-[#34404e] font-semibold mt-5 mb-1 flex items-center gap-2'>
                            <span><FaLocationDot/></span>
                            <span>Location</span>
                        </p>
                        <div className='w-[400px] h-[250px]'>
                        <iframe className='border rounded-3xl' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2771608776648!2d77.67087287587896!3d12.889891316662792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae135b66ff0a53%3A0xb8ded83f1a2360c6!2sSpArts%20Technologies!5e0!3m2!1sen!2sin!4v1733726966086!5m2!1sen!2sin" width="100%" height="100%" loading="lazy"></iframe>
                        </div>
                    </div>
                    {/* Contact form */}
                    <div className="sm:w-[500px] w-full p-10 rounded-lg border-2 shadow-xl sm:m-0 ml-10">
                    <h2 className="text-2xl text-center m-0 mb-2">Have Questions?</h2>
                    <p className='text-center'>
                        Please fill the form below
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-[20px]">
                            <label className='block ' htmlFor="fullName">Full name:</label>
                            <input
                                className='w-full p-2 border border-gray-300 rounded-md'
                                type="text"
                                id="fullName"
                                value={fullName}
                                onChange={e => setFullName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-[20px]">
                            <label className='block ' htmlFor="email">Email:</label>
                            <input
                                className='w-full p-2 border border-gray-300 rounded-md'
                                type="email"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-[20px]">
                            <div className='flex items-center justify-between'>
                              <label className='block ' htmlFor="message">Message:</label>
                              {inValidMessage && <div className='text-xs text-red-400 pr-1'>Please enter 16 characters or more!</div>}
                            </div>
                            <textarea
                              className={`w-full p-2 border border-gray-300 rounded-md resize-y ${inValidMessage ? 'outline-2 outline-red-400' : ''}`}
                              id="message"
                              value={message}
                              onChange={e => {
                                setMessage(e.target.value);
                                setInValidMessage(false);
                              }}
                              rows={6}
                              required
                            ></textarea>
                        </div>
                        <button disabled={isContactFormLoading} className='bg-[#4caf50] hover:bg-[#45a049] text-white px-4 py-2 rounded-md w-full font-semibold active:scale-95 transition-all disabled:opacity-25' type="submit">Submit</button>
                    </form>
                    </div>
                </div>
            </div>
            <Footer />
            {isFormSubmitted && (
              <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 100 }}
                  className='fixed bottom-0 left-0 top-0 h-full w-full right-0 z-[999999999999] flex items-center justify-center backdrop-blur-sm'
              >
                  <div className='sm:w-[480px] text-center w-[90vw] bg-white rounded-lg flex flex-col items-center justify-center shadow-2xl p-6 border-8 border-opacity-20 border-[#3AB7FC]'> 
                      <img 
                          src='/assets/images/logo.png'
                          className='p-2 h-20'
                          alt='logo'
                      />
                      <div className='text-center font-semibold text-2xl'>Thank you for contacting us!</div>
                      <div className='text-center text-gray-600'>We have recieved your message, we will get back to you shortly.</div>
                      <div onClick={() => navigate(Routes.DefaultPage)} className='px-6 py-2 bg-[#3AB7FC] cursor-pointer rounded-lg text-white mt-4 text-[22px] font-semibold'>
                          Back To Homepage
                      </div>
                  </div>
              </motion.div>
            )}
        </div>
    );
};

export default ContactUs;
