import React from 'react';
import { Capacitor } from '@capacitor/core';
import HomePageNative from '../../../pages/home-page-native';
import Landingpage from '../../../pages/landing-page';

const DefaultHome: React.FC = () => {
  const isNative = Capacitor.isNativePlatform();

  return (
      <div>
          {isNative ? <HomePageNative /> : <Landingpage />}
      </div>
  );
};

export default DefaultHome;