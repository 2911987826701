import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';
import { CertificationListWrapper } from '../models/certifications/certification-list-wrapper';
import { StudentCertificateRecordListWrapper } from '../models/certifications/student-certificate-record-list-wrapper';

export class CertificationService {
    private static _instance: CertificationService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new CertificationService());
    }

    public getCertificationsOfStudentForDiscipline = async (student: number,academy: number,discipline: number): Promise<ApiResponse<StudentCertificateRecordListWrapper>> => {
        return invokeApi<null, StudentCertificateRecordListWrapper>({
            route: `student-certificate-records?populate=*&filters[student][id]=${student}&filters[certificationInfo][academy][id]=${academy}&filters[certificationInfo][discipline][id]=${discipline}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
