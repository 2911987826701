import { useSelector } from "react-redux";
import ApiResponse from "../models/api/api-response";
import { NotificationListWrapper } from "../models/notification/notification-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";
import activeProfile from "../utils/redux/active-profile";
import user from "../utils/redux/user";
import { RootState } from "../utils/redux/store";
import { StudentData } from "../models/student/student-data";
import { ClassData } from "../models/class/class-data";

export class NotificationService {
    private static _instance: NotificationService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new NotificationService());
    }
    public getAllNotificationsOfParent = async (students: number[]): Promise<ApiResponse<NotificationListWrapper>> => {
        return invokeApi<null, NotificationListWrapper>({
            route: `parent-notifcations?populate=*&${students.map((id,index) => `filters[$or][${index}][studentsAssignedTo][id]=${id}`).join('&')}`,
            method: 'GET',
            privateRoute: true,
        });
    }

}