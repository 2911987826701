import { Comment, CommentReply, DeleteCommentData, EditedComment } from '../models/blog/blog-attributes';
import { BlogData } from '../models/blog/blog-data';
import { BlogDataWrapper } from '../models/blog/blog-data-wrapper';
import { BlogListWrapper } from '../models/blog/blog-list-wrapper';
import { BlogPostData } from '../models/blog/blog-post-data';
import { InterestsPostData } from '../models/blog/interests-post-data';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class BlogService {
    private static _instance: BlogService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new BlogService());
    }

    public getBlogsList = async () => {
        return invokeApi<null, BlogListWrapper>({
            route: `blog-posts?filters[isLive]=true&filters[isVisibleInStudentApp]=true&populate=*`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public getGlobalSearchBlogs = async (term: string) => {
        return invokeApi<null, BlogListWrapper>({
            route: `blog-posts?filters[isLive]=true&filters[isVisibleInStudentApp]=true&filters[title][$contains]=${term}&populate=previewImage`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public getBlogPost = async (url: string) => {
        return invokeApi<null, BlogDataWrapper>({
            route: `blog-posts?filters[url]=${url}&populate=*`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public updateBlog = async (id: number,data: BlogPostData) => {
        return invokeApi<{data: BlogPostData}, BlogDataWrapper>({
            route: `blog-posts/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {data},
        });
    };

    public blogLike = async (id: number) => {
        return invokeApi<null, BlogDataWrapper>({
            route: `blog/like/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };
    public undoBlogLike = async (id: number) => {
        return invokeApi<null, BlogDataWrapper>({
            route: `blog/like/undo/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };
    public blogDislike = async (id: number) => {
        return invokeApi<null, BlogDataWrapper>({
            route: `blog/dislike/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };
    public undoBlogDislike = async (id: number) => {
        return invokeApi<null, BlogDataWrapper>({
            route: `blog/dislike/undo/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };
    public postBlogComment = async (id: number,data: Comment) => {
        return invokeApi<Comment, BlogDataWrapper>({
            route: `blog/comment/${id}`,
            method: 'POST',
            privateRoute: true,
            data: data
        });
    };
    public postBlogCommentReply = async (id: number,data: CommentReply) => {
        return invokeApi<CommentReply, BlogDataWrapper>({
            route: `blog/comment/reply/${id}`,
            method: 'POST',
            privateRoute: true,
            data: data
        });
    };
    public updateBlogComment = async (id: number,data: EditedComment) => {
        return invokeApi<EditedComment, BlogDataWrapper>({
            route: `blog/comment/edit/${id}`,
            method: 'POST',
            privateRoute: true,
            data: data
        });
    };
    public deleteBlogComment = async (id: number,data: DeleteCommentData) => {
        return invokeApi<DeleteCommentData, BlogDataWrapper>({
            route: `blog/comment/delete/${id}`,
            method: 'POST',
            privateRoute: true,
            data: data
        });
    };

    public postInterests = async (data: InterestsPostData) => {
        return invokeApi<{data: InterestsPostData}, InterestsPostData>({
            route: `interests`,
            method: 'POST',
            privateRoute: true,
            data: {data}
        })
    }
}