export const GLOBAL_SEARCH_PLACEHOLDERS = [
    "Find that 'World's Best Academy' everyone talks about...",
    "Looking for karate? Or maybe just inner peace?",
    "Because 'Coach' isn't on speed dial...",
    "From ballet to boxing – what’s your fancy?",
    "Is it yoga? Is it zumba? Find it here!",
    "Lost your academy? We’ll help you find it!",
    "Looking for fitness... or just excuses?",
    "Where’s that place to finally learn salsa?",
    "Academy of Rock? Well, not quite, but close.",
    "Find that sport where you don’t break a sweat.",
    "Searching for the 'next big thing' in sports?",
    "Find a place where 'athlete' means more than Netflix marathoner.",
    "Academy of football or maybe... underwater basket weaving?",
    "Find the dojo for your inner warrior... or yoga for your zen.",
    "From chess to cheerleading – if it’s a sport, it’s here!",
    "Training for the Olympics? Or just for the free Wi-Fi?",
    "Find the gym... or just your motivation!",
    "Salsa, judo, chess – discover your hidden talent here!",
    "Where coaches don’t yell… much.",
    "Want to kickstart something? Like literally kick?",
    "Looking for sports? Or just a really good excuse to stretch?",
    "Ready to crush it? Start with finding the right academy!",
    "From badminton to ballroom – what's your next move?",
    "Find the place where 'one more rep' actually happens.",
    "A search for glory... or just a gym with AC?",
    "Channeling your inner Olympian? Start here!",
    "Searching for sports nirvana? Or at least some new gear?",
    "Want to master fencing? Or maybe just dodgeball?",
    "Step 1: Search. Step 2: Find the Academy. Step 3: Brag.",
    "Ready to unleash the athlete within? Or just window shopping?",
]

export const GLOBAL_SEARCH_NO_RESULT_TEXT = [
    "Oops, it looks like your search skills are almost as good as our karate classes!",
    "No results here… maybe it’s hiding in the advanced level?",
    "Sorry, nothing found! But hey, at least you're not lost in the yoga studio's storage closet!",
    "Hmm, we couldn’t find anything... maybe it’s taking a break in meditation class.",
    "No results, but you get an A+ for effort! Keep searching, champion!",
    "Looks like your search just did a disappearing act!",
    "We looked high and low, but no results in sight. Maybe we need a higher jump or a deeper stretch?",
    "It’s a 'no' from us this time. Try again? Don’t worry, we believe in second chances!",
    "If it’s a black belt in searching you seek, keep practicing. Not everything is found in the first round!",
    "Search results: 0. But your sense of adventure? 100%!"
]

export const GLOBAL_SEARCH_LOADING_TEXT = [
    "Hold tight—just stretching out the search muscles!",
    "Loading… doing a few warm-ups before bringing you the best results!",
    "Sharpening pencils and polishing medals... almost there!",
    "Fetching results... maybe they’re busy practicing?",
    "Hold on… doing a quick meditation for faster results!",
    "Results are on their way, just practicing their perfect landing!",
    "Loading… we promise it’s worth the wait (just like the black belt).",
    "Results are warming up… almost ready to impress!",
    "Hang on, we’re just adjusting our headbands and getting in the zone!",
    "Gathering your search results… they just need a little pep talk!"
]