
import React from 'react'

interface PopupModalProps {
    children: React.ReactNode
}

const PopupModal = ({
    children,
}: PopupModalProps) => {

    return (
            <div className="fixed flex justify-center items-center inset-0 z-10 w-screen overflow-y-auto backdrop-blur-md">
                <div className="flex items-center justify-center p-4 sm:w-[30%] w-full">
                        {children}
                </div>
            </div>
    )
}

export default PopupModal