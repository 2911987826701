import ApiResponse from "../models/api/api-response";
import { StudentData } from "../models/student/student-data";
import { StudentListWrapper } from "../models/student/student-list-wrapper";
import { StudentPostDataWrapper } from "../models/student/student-post-data-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class StudentService {
    private static _instance: StudentService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new StudentService());
    }

    public getStudentData = async (id: number): Promise<ApiResponse<{data: StudentData}>> => {
        return invokeApi<null, {data: StudentData}>({
            route: `students/${id}?populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getStudentsOfParent = async (id: number): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate[0]=classes.class_discipline&populate[1]=classes.academies.images&populate[2]=photo&populate[3]=academies&populate[4]=studentRelation&populate[5]=sessions&populate[6]=studentAcademyHistory&populate[7]=users&populate[8]=parentLinkedTo&filters[parentLinkedTo]=${id}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getClaimableStudents = async (email: string,number: number): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate=*&filters[$or][0][parentEmailId]=${email}&filters[$or][1][parentContactNo]=${number}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getClaimableStudentsOfAcademy = async (email: string,number: number,academyId: number): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate=*&filters[$or][0][parentEmailId]=${email}&filters[$or][1][parentContactNo]=${number}&filters[academies][id]=${academyId}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public updateStudent = async (id: number,data: StudentPostDataWrapper) => {
        return invokeApi<StudentPostDataWrapper, StudentData>({
            route: `students/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };
}