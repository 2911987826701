import {configureStore} from '@reduxjs/toolkit';
import userReducer from './user';
import activeProfile from './active-profile';


const store = configureStore({
    reducer: {
        user: userReducer,
        activeProfile
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
