import React, {useEffect, useState} from 'react';
import FullScreenLoader from '../../components/common/full-sreen-loader';
import ProfileClaimViewModel from '../../view-models/profile-claim-viewmodel';
import { motion, AnimatePresence } from "framer-motion";
import { StudentData } from '../../../models/student/student-data';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import Layout from '../../components/common/layout';

  
const useBodyClass = (className: string, condition: boolean) => {
    useEffect(() => {
        if (condition) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    
        return () => {
            document.body.classList.remove(className);
        };
    }, [className, condition]);
};

export function Gallery({claimableStudents, selectedStudentsId, setIndex}: {claimableStudents: StudentData[], selectedStudentsId: number[], setIndex: React.Dispatch<React.SetStateAction<number | undefined>>}) {
    return (
        <ul className="rounded-[25px] w-full h-auto am:m-10 sm:p-10 flex flex-wrap gap-4 justify-around items-center list-none">
        {claimableStudents.map((student) => {
            return(
            <motion.li
                className={`rounded-[10px] p-[10px] cursor-pointer w-full shadow-xl ${selectedStudentsId.includes(student.id) ? 'bg-green-300' : 'bg-[#fedb81]'}`}
                key={student.id}
                onClick={() => setIndex(student?.id)}
                layoutId={student.id.toString()}
            >
                <div className=' text-gray-600 font-semibold '>Name : {`${student?.attributes?.firstName} ${student?.attributes?.lastName ?? ''}`}</div>
                <div className=' text-gray-600 font-semibold '>Academies : {student?.attributes?.academies?.data?.map(academy => academy.attributes.name).join(', ')}</div>
                <div className=' text-gray-600 font-semibold '>Classes : {student?.attributes?.classes?.data?.map(classItem => classItem.attributes.class_name).join(', ')}</div>
            </motion.li>

            )
        })}
        </ul>
    );
}
    
export function SingleImage({ claimableStudent, selectedStudentsId, dobInput, setDobInput, handleAddRemoveClaim }: { 
    claimableStudent: StudentData, 
    selectedStudentsId: number[],
    dobInput: string,
    setDobInput: React.Dispatch<React.SetStateAction<string>>,
    handleAddRemoveClaim: (id: number) => void
}) {
    return (
        <div 
            className="single-image-container fixed inset-0 flex justify-center items-center pointer-events-none bg-black bg-opacity-80" 
        >
            <motion.div
                layoutId={claimableStudent.id.toString()}
                className="rounded-[20px] w-[92%] bg-white backdrop-blur-xl shadow-2xl p-8 flex flex-col"
            >
                <div className='text-center text-gray-600 font-semibold pt-2 inline-flex items-center justify-between w-full px-4 text-xl'>
                    <div>Name: </div>
                    <div>{`${claimableStudent?.attributes?.firstName} ${claimableStudent?.attributes?.lastName ?? ''}`}</div>
                </div>
                <hr className='mt-3 w-[70%] mx-auto text-center'/>
                <div className='text-center text-gray-600 font-semibold pt-2 inline-flex items-start justify-between w-full px-4 text-xl'>
                    <div>Academies: </div>
                    <div className='text-right text-lg flex flex-col justify-end'>{claimableStudent?.attributes?.academies?.data?.map(academy => (<div>{academy?.attributes?.name}</div>))}</div>
                </div>
                <hr className='mt-3 w-[70%] mx-auto text-center'/>
                <div className='text-center text-gray-600 font-semibold pt-2 inline-flex items-start justify-between w-full px-4 text-xl'>
                    <div>Classes: </div>
                    <div className='text-right text-lg flex flex-col justify-end'>{claimableStudent?.attributes?.classes?.data?.map(classItem => (<div>{classItem?.attributes?.class_name}</div>))}</div>
                </div>
                <hr className='mt-3 w-[70%] mx-auto text-center'/>
                <div className='text-center text-gray-600 font-semibold pt-2 inline-flex items-center justify-between w-full px-4 text-xl'>
                    <div>Address: </div>
                    <div className='text-right text-lg'>{claimableStudent?.attributes?.currentAddress}</div>
                </div>
                <div className='text-center text-gray-600 font-semibold p-4 mt-4 w-full px-8 text-xl rounded-md border-4 border-blue-300'>
                    <div>Select correct date of birth to continue</div>
                    <input
                        type='date'
                        value={dobInput}
                        onChange={(e) => setDobInput(e.target.value)}
                        className='w-full rounded-md p-4'
                    />
                </div>
                <div className='flex-grow flex mt-4 items-end'>
                    {selectedStudentsId.includes(claimableStudent.id) ? (
                        <div 
                            className='w-full h-12 bg-red-300 rounded-lg font-semibold text-white flex items-center justify-center'
                            onClick={() => handleAddRemoveClaim(claimableStudent.id)}
                        >
                                Remove
                        </div>
                    ) : (
                        <button 
                            className='w-full h-12 bg-green-400 rounded-lg font-semibold text-white flex items-center justify-center disabled:opacity-20'
                            onClick={() => handleAddRemoveClaim(claimableStudent.id)}
                            disabled={
                                !dobInput ||
                                !claimableStudent?.attributes?.dob ||
                                isNaN(new Date(dobInput).getTime()) ||
                                isNaN(new Date(claimableStudent.attributes.dob).getTime()) ||
                                new Date(dobInput).toISOString().split('T')[0] !== new Date(claimableStudent.attributes.dob).toISOString().split('T')[0]
                            }
                        >
                            Claim
                        </button>
                    )}
                </div>
            </motion.div>
        </div>
    );
}

  

const ProfileClaimPage = () => {
    const {
        isLoading, 
        claimableStudents,
        handleClaimStudentSubmit,
        selectedStudentsId,
        setSelectedStudentsId
    } = ProfileClaimViewModel();
    const [index, setIndex] = useState<number>();

    const handleAddRemoveClaim = (id: number) => {
        if (selectedStudentsId.includes(id)) {
            setSelectedStudentsId(prev => prev.filter(studentId => studentId !== id));
        } else {
            setSelectedStudentsId(prev => [...prev, id]);
        }
        setIndex(undefined);
    };

    const [dobInput,setDobInput] = useState<string>('')
    const navigate = useNavigate();



    useBodyClass('overflow-hidden', index !== undefined);

    return (
        <Layout isProfileSwitchTabVisible={false}>
            {isLoading ? <FullScreenLoader /> : (
                claimableStudents.length > 0 ? (
                    <div className="sm:h-full">
                        <AnimatePresence>
                            <Gallery claimableStudents={claimableStudents} selectedStudentsId={selectedStudentsId} setIndex={setIndex}/>
                            {index !== undefined && (
                                <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 0.6}}
                                    exit={{opacity: 0}}
                                    key="overlay"
                                    className="bg-white  fixed inset-0"
                                    onClick={() => setIndex(undefined)}
                                />
                            )}
    
                            {index !== undefined && (
                                <SingleImage
                                    key="image"
                                    claimableStudent={
                                        claimableStudents.filter(
                                            item => item.id === index,
                                        )[0]
                                    }
                                    selectedStudentsId={selectedStudentsId}
                                    dobInput={dobInput}
                                    setDobInput={setDobInput}
                                    handleAddRemoveClaim={handleAddRemoveClaim}
                                />
                            )}
                        </AnimatePresence>
                        {selectedStudentsId.length > 0 &&
                            <div className="p-4 mt-14">
                                <div
                                    className="py-2 px-4 bg-orange-300 rounded-md text-center font-semibold"
                                    onClick={handleClaimStudentSubmit}>
                                    Continue
                                </div>
                            </div>
                        }
                    </div>
                ) : (
                    <div className='m-auto w-full h-full  flex text-center items-center justify-center flex-col gap-12'>
                        <div className="sm:text-4xl text-2xl text-gray-500 font-semibold sm:pt-14 pt-7 sm:px-20 text-center">
                            No profiles found :{`(`}
                        </div>
                        
                    
                        <div
                            onClick={() => navigate(Routes.enrolment)}
                            className="py-2 px-4 bg-blue-300 text-center rounded-xl shadow-md my-2 text-xl font-semibold text-white  m-auto sm:w-[30%] ">
                            Enroll a new student
                        </div>
                    </div>
                )
            )}
        </Layout>
    );
};

export default ProfileClaimPage;
