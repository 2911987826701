import ApiResponse from '../models/api/api-response';
import { AttendanceListWrapper } from '../models/attendance/attendance-list-wrapper';
import {invokeApi} from '../utils/helpers/invoke-api';


export class AttendanceService {
    private static _instance: AttendanceService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new AttendanceService());
    }

    public getAttendanceOfStudentForClass = async (classId: number, studentId: number): Promise<ApiResponse<AttendanceListWrapper>> => {
        return invokeApi<null, AttendanceListWrapper>({
            route: `schedules?populate=*&filters[student]=${studentId}&filters[class]=${classId}&filters[present]=true`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAttendanceOfActiveStudent = async (studentId: number): Promise<ApiResponse<AttendanceListWrapper>> => {
        return invokeApi<null, AttendanceListWrapper>({
            route: `schedules?populate=*&filters[student]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
