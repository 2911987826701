import React from 'react'
import Layout from '../../components/common/layout'
import PaymentPageViewModel from '../../view-models/payment-page-viewmodel'
import FullScreenLoader from '../../components/common/full-sreen-loader';

const PaymentPage = () => {
    const {
        isLoading,
        checkoutRef
    } = PaymentPageViewModel();

  return (
    <Layout isProfileSwitchTabVisible={false}>
        {isLoading && <FullScreenLoader/>}
        <div className='fixed top-0 left-0 right-0 bottom-0 bg-[#F8C954] z-[10] flex items-center justify-center'>
            <div 
                className='bg-white h-[calc(100vh-146px)] w-[95%] rounded-lg overflow-hidden overflow-y-auto'
                ref={checkoutRef}
            >

            </div>
        </div>
    </Layout>
  )
}

export default PaymentPage