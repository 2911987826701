import { AssignmentListWrapper } from '../models/assigments/assignment-list-wrapper'
import ApiResponse from '../models/api/api-response';

import {invokeApi} from '../utils/helpers/invoke-api';
// import { AcademyService } from './academy-service';

type fitnessData = {
    data:{
        student:number,
        Pushup:number,
        Plank:number,
        Squats:number,
        Lunges:number
    }
}

interface Attributes {
    pushup: number;
    plank: number;
    lunge: number;
    squats: number;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
}

interface Data {
    id: number;
    attributes: Attributes;
}

export interface ApiResponse_here {
    data: Data;
    meta: Record<string, unknown>;
}


export class FitnessService {
    private static _instance: FitnessService;
    private constructor() {}

    public static get instance(){
        return this._instance ?? (this._instance = new FitnessService());
    }

    public postFitnessData =  async (
        studentId: number,
        pushup:number, 
        plank:number, 
        lunge:number, 
        squats:number
    ): Promise<ApiResponse<ApiResponse_here>> => {
        return invokeApi<fitnessData, ApiResponse_here>({
            route: `fitness-reports`,
            method: 'POST',
            privateRoute: true,
            data: {
                data:{
                    student:studentId,
                    Pushup:pushup,
                    Plank:plank,
                    Squats:squats,
                    Lunges:lunge
                }
            },
        });
    };
}