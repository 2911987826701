import { useEffect, useRef, useState } from "react";
import { useAnimation, useScroll } from 'framer-motion';
import { useQuery, useQueryClient } from "react-query";
import { ACADEMY_PROFILE_LIST_QUERY, FEATURED_DISCIPLINES, GLOBAL_SEARCH_ACADEMIES, GLOBAL_SEARCH_BLOGS, GLOBAL_SEARCH_DISCIPLINES, HOME_PAGE_SETTINGS } from "../../utils/constants/globals";
import { HomePageService } from "../../services/home-page-services";
import { AcademyProfileService } from "../../services/academy-profile-service";
import { useNavigate } from "react-router-dom";
import { DisciplineService } from "../../services/discipline-service";
import { Routes } from "../navigation/routes";
import { GLOBAL_SEARCH_LOADING_TEXT, GLOBAL_SEARCH_NO_RESULT_TEXT, GLOBAL_SEARCH_PLACEHOLDERS } from "../pages/home-page-native/data";
import { BlogService } from "../../services/blog-services";


const HomePageNativeViewModel = () => {

    const [currentPlaceholder, setCurrentPlaceholder] = useState('');
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [typingIndex, setTypingIndex] = useState(0);
    const typingSpeed = 100;
    const pauseBetweenPlaceholders = 4000;
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [selectedLoadingText, setSelectedLoadingText] = useState('');
    const [selectedNoResultText, setSelectedNoResultText] = useState('');

    useEffect(() => {
        setSelectedLoadingText(GLOBAL_SEARCH_LOADING_TEXT[Math.floor(Math.random() * GLOBAL_SEARCH_LOADING_TEXT.length)]);
        setSelectedNoResultText(GLOBAL_SEARCH_NO_RESULT_TEXT[Math.floor(Math.random() * GLOBAL_SEARCH_NO_RESULT_TEXT.length)]);
    }, []);

    useEffect(() => {
        const currentText = GLOBAL_SEARCH_PLACEHOLDERS[placeholderIndex];
        
        if (typingIndex < currentText.length) {
            // Typing effect
            const timeoutId = setTimeout(() => {
                setCurrentPlaceholder((prev) => prev + currentText.charAt(typingIndex));
                setTypingIndex((prev) => prev + 1);
            }, typingSpeed);
    
            return () => clearTimeout(timeoutId);
        } else {
            // Pause before selecting the next random placeholder
            const timeoutId = setTimeout(() => {
                let nextIndex;
                do {
                    nextIndex = Math.floor(Math.random() * GLOBAL_SEARCH_PLACEHOLDERS.length);
                } while (nextIndex === placeholderIndex); // Ensure it's a different placeholder each time
                
                setPlaceholderIndex(nextIndex);
                setCurrentPlaceholder('');
                setTypingIndex(0);
            }, pauseBetweenPlaceholders);
    
            return () => clearTimeout(timeoutId);
        }
    }, [typingIndex, placeholderIndex]);  
    
    const controls = useAnimation();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isCarouselVisible, setIsCarouselVisible] = useState(true);

    useEffect(() => {
      const handleScroll = () => {
          const scrollY = scrollContainerRef?.current?.scrollTop;
          if (scrollY !== null && scrollY !== undefined && scrollY > 10 && isCarouselVisible) {
                setIsCarouselVisible(false);
                controls.start({
                    opacity: 0,
                    width: '0%',
                    marginTop: '0',
                    height: '0',
                    transition: { duration: 0.3, ease: 'easeInOut' },
                });
          } else if (scrollY === 0 && !isCarouselVisible) {
                setIsCarouselVisible(true);
                controls.start({
                    opacity: 1,
                    width: '100%',
                    marginTop: '1rem',
                    height: 'auto',
                    transition: { duration: 0.3, ease: 'easeInOut' },
                });
          }
      };

      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
          if (scrollContainer) {
              scrollContainer.removeEventListener('scroll', handleScroll);
          }
      };
    }, [controls, isCarouselVisible]);

    const [globalSearchTerm, setGlobalSearchTerm] = useState('');
    const navSearchInputRef = useRef<HTMLInputElement>(null);
    const heroSearchInputRef = useRef<HTMLInputElement>(null);

    const handleGlobalSearchTermChange = (val: string) => {
        setGlobalSearchTerm(val);
    }

    useEffect(() => {
        if (globalSearchTerm.length > 0) {
            setIsCarouselVisible(false)
            controls.start({
                opacity: 0,
                width: '0%',
                marginTop: '0',
                height: '0',
                transition: { duration: 0.3, ease: 'easeInOut' },
            });
        }
        navSearchInputRef.current?.focus();
    },[globalSearchTerm])


    const {
        isLoading: isHomePageSettingsFetching,
        data: homePageSettings,
    } = useQuery({
        queryKey: [HOME_PAGE_SETTINGS],
        queryFn: () => HomePageService.instance.getHomePageSettings(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const {
        isLoading: isFeaturedDisciplineFetching,
        data: featuredDiscipline,
    } = useQuery({
        queryKey: [FEATURED_DISCIPLINES],
        queryFn: () => DisciplineService.instance.getFeaturedDisciplines(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const {
        isLoading: isAcademyProfileListFetching,
        data: academyProfiles,
    } = useQuery({
        queryKey: [ACADEMY_PROFILE_LIST_QUERY],
        queryFn: () => AcademyProfileService.instance.getAcademyProfileList(''),
        refetchOnWindowFocus: false,
        enabled: true
    });

    const handlePlayShowcaseVideo = (url: string) => {
        navigate(Routes.ShowcaseVideos, {state: {url: url}});
    }

    const {
        isLoading: isGlobalSearchAcademiesFetching,
        data: globalSearchAcademies,
    } = useQuery({
        queryKey: [GLOBAL_SEARCH_ACADEMIES,globalSearchTerm],
        queryFn: () => AcademyProfileService.instance.getGlobalSearchAcademiesList(globalSearchTerm),
        refetchOnWindowFocus: false,
        enabled: globalSearchTerm.length > 0
    });

    const {
        isLoading: isGlobalSearchDisciplinesFetching,
        data: globalSearchDisciplines,
    } = useQuery({
        queryKey: [GLOBAL_SEARCH_DISCIPLINES,globalSearchTerm],
        queryFn: () => DisciplineService.instance.getGlobalSearchDisciplines(globalSearchTerm),
        refetchOnWindowFocus: false,
        enabled: globalSearchTerm.length > 0
    });

    const {
        isLoading: isGlobalSearchBlogsFetching,
        data: globalSearchBlogs,
    } = useQuery({
        queryKey: [GLOBAL_SEARCH_BLOGS,globalSearchTerm],
        queryFn: () => BlogService.instance.getGlobalSearchBlogs(globalSearchTerm),
        refetchOnWindowFocus: false,
        enabled: globalSearchTerm.length > 0
    });


    return {
        isLoading: false,
        currentPlaceholder,
        isCarouselVisible,
        scrollContainerRef,
        selectedLoadingText,
        selectedNoResultText,
        controls,
        globalSearchTerm,
        handleGlobalSearchTermChange,
        isHomePageSettingsFetching,
        homePageSettings: homePageSettings?.data?.data,
        isAcademyProfileListFetching,
        academyProfiles: academyProfiles?.data?.data,
        navigate,
        isFeaturedDisciplineFetching,
        featuredDiscipline: featuredDiscipline?.data?.data ?? [],
        handlePlayShowcaseVideo,
        navSearchInputRef,
        heroSearchInputRef,
        isGlobalSearchAcademiesFetching,
        globalSearchAcademies: globalSearchAcademies?.data?.data ?? [],
        isGlobalSearchDisciplinesFetching,
        globalSearchDisciplines: globalSearchDisciplines?.data?.data ?? [],
        isGlobalSearchBlogsFetching,
        globalSearchBlogs: globalSearchBlogs?.data?.data ?? [],
    }
}

export default HomePageNativeViewModel