export const GRADE_OPTIONS = [
    { value: 'PRE-KG', label: 'Pre-KG' },
    { value: 'LKG', label: 'LKG' },
    { value: 'UKG', label: 'UKG' },
    { value: 'GRADE-1', label: 'Grade 1' },
    { value: 'GRADE-2', label: 'Grade 2' },
    { value: 'GRADE-3', label: 'Grade 3' },
    { value: 'GRADE-4', label: 'Grade 4' },
    { value: 'GRADE-5', label: 'Grade 5' },
    { value: 'GRADE-6', label: 'Grade 6' },
    { value: 'GRADE-7', label: 'Grade 7' },
    { value: 'GRADE-8', label: 'Grade 8' },
    { value: 'GRADE-9', label: 'Grade 9' },
    { value: 'GRADE-10', label: 'Grade 10' },
    { value: 'GRADE-11', label: 'Grade 11' },
    { value: 'GRADE-12', label: 'Grade 12' },
];

export const BOARD_OPTIONS = [
    { label: 'ICSE', value: 'ICSE' },
    { label: 'CBSE', value: 'CBSE' },
    { label: 'NIOS', value: 'NIOS' },
    { label: 'IB', value: 'IB' },
    { label: 'State Board', value: 'StateBoard' },
]