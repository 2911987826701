import { useSelector } from "react-redux";
import ApiResponse from "../models/api/api-response";
import { ClassDetailsWrapper } from "../models/class/class-details-wrapper";
import { ClassListWrapper } from "../models/class/class-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";
import { RootState } from "../utils/redux/store";

export class ClassService {
    private static _instance: ClassService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new ClassService());
    }
    public getClassesOfStudent = async (studentId: number): Promise<ApiResponse<ClassListWrapper>> => {
        return invokeApi<null, ClassListWrapper>({
            route: `classes?populate=*&filters[students][id]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getAllClassesOfAllStudents = async (students: number[]): Promise<ApiResponse<ClassListWrapper>> => {
        return invokeApi<null, ClassListWrapper>({
            route: `classes?${students.map(id => `filters[students][id]=${id}`).join('&')}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getClassDetails = async (classId: number): Promise<ApiResponse<ClassDetailsWrapper>> => {
        return invokeApi<null, ClassDetailsWrapper>({
            route: `classes/${classId}?populate=*`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getAllClassesOfAcademy = async (academyId: number): Promise<ApiResponse<ClassListWrapper>> => {
        return invokeApi<null, ClassListWrapper>({
            route: `classes?populate=*&filters[academies][id]=${academyId}`,
            method: 'GET',
            privateRoute: true,
        })
    }
}