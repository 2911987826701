import React from 'react'
import { useQuery } from 'react-query';
import { CERTIFICATIONS_OF_ACTIVE_STUDENT_FOR_CLASS } from '../../utils/constants/globals';
import { CertificationService } from '../../services/certification-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';

const CertificationsViewModel = () => {
    const profile = useSelector((state: RootState) => state.activeProfile);

    const {
        isSuccess: isStudentCertificationsFetchSuccess,
        isLoading: isStudentCertificationsFetching,
        data: StudentCertifications,
    } = useQuery({
        queryKey: [CERTIFICATIONS_OF_ACTIVE_STUDENT_FOR_CLASS, profile.activeStudent.id,profile.activeClassData?.attributes?.academies?.data?.id],
        queryFn: () => CertificationService.instance.getCertificationsOfStudentForDiscipline(
            profile.activeStudent.id,
            profile.activeClassData?.attributes?.academies?.data?.id ?? 0,
            profile.activeClassData?.attributes?.class_discipline?.data?.id ?? 0
        ),
        refetchOnWindowFocus: false,
        enabled: (profile.activeClassData?.attributes?.academies?.data?.id ?? 0) > 0 && (profile.activeClassData?.attributes?.class_discipline?.data?.id ?? 0) > 0 && profile.activeStudent.id > 0,
    });

    return {
        isLoading: false,
        StudentCertifications: StudentCertifications?.data?.data,
    }
}

export default CertificationsViewModel