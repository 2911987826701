import React, { useEffect } from 'react'
import Layout from '../../components/common/layout'
import FeesPaymentViewModel from '../../view-models/fees-payment-viewmodel'
import FullScreenLoader from '../../components/common/full-sreen-loader';

const FeesPaymentPage = () => {
    const {
        isLoading,
        isSuccess,
        classTraingFeeStructure,
        classDetails,
        handleCheckoutClick
    } = FeesPaymentViewModel();

    function getPeriods(frequency: string) {
        switch (frequency) {
            case 'Monthly':
                return 4;
            case 'Quarterly':
                return 12;
            case 'SemiAnnually':
                return 24;
            case 'Annually':
                return 48;
            default:
                throw new Error('Invalid frequency');
        }
    }




    return (
        <Layout isProfileSwitchTabVisible={true}>
            {isLoading && <FullScreenLoader/>}
            {isSuccess && 
                <>
                    {classTraingFeeStructure ? 
                        <div className='p-2'>
                                <div className='text-xl font-semibold text-slate-600'>Tution Fees</div>
                                <hr className='my-2'/>
                                <div className='p-2 rounded border-2 my-2'>
                                    Previous fees history of the class
                                </div>
                                <div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
                                    {classTraingFeeStructure?.attributes?.trainingFees?.map(fee => (
                                        <div className='rounded-lg shadow-xl p-4 bg-[#F7F9F7]'>
                                            <div className='text-sm font-semibold'>{classDetails?.attributes?.class_name}</div>
                                            <div className='text-md font-semibold '><span className='text-4xl'>&#8377;{fee?.amount}</span> / {fee.frequency}</div>
                                            <hr className='my-2 opacity-40'/>
                                            <div className='text-md text-gray-600'>Whats included:</div>
                                            <div>&#8226; {fee?.sessionsPerWeek} Sessions Per Week</div>
                                            <div>&#8226; Plan Period: {fee?.frequency === 'Annually' ? '12 Months' : fee?.frequency === 'SemiAnnually' ? '6 Months' : fee?.frequency === 'Quarterly' ? '3 Months' : '1 Month'}</div>
                                            <div>&#8226; {fee?.sessionsPerWeek * getPeriods(fee?.frequency)} Total Sessions</div>
                                            <div 
                                                className=' bg-gradient-to-r from-[#3AB7FC] to-blue-500 shadow-md py-2 w-full text-center rounded-lg mt-2 text-white font-semibold active:scale-105 transition-all'
                                                onClick={() => handleCheckoutClick(fee?.amount,'Training',{sessions: fee.sessionsPerWeek, schedule: fee.frequency})}
                                            >
                                                Checkout
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* <iframe src="https://studentsqa.sparts.app" className='w-screen h-[calc(100vh-64px)] absolute top-[63px] left-0 '/> */}
                        </div>
                        :
                        <div className='flex flex-col justify-center items-center text-center mt-10'>
                            <div className='text-xl font-semibold text-slate-500'>We couldn't find any fee structure for this academy!</div>
                            <div className='text-lg text-gray-400'>Please reach out to academy for more details</div>
                        </div>
                    }
                </>
            }
        </Layout>
    )
}

export default FeesPaymentPage