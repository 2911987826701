import { NormalizedLandmark } from "@mediapipe/pose";

/**
 * Checks if all elements in the given data array have a visibility value greater than or equal to 0.85, if the visibility value is present.
 *
 * @param data - Array of landmarks, each containing x, y, z coordinates and an optional visibility value.
 * @returns True if all data points have a visibility value of at least 0.85, or if visibility is not present. Otherwise, returns false.
 */
const checkVisibility = (data: NormalizedLandmark[]): boolean => {
  for (let i = 0; i < data.length; i++) {
    if ((data[i].visibility ?? 0) < 0.5) {
      return false;
    }
  }
  return true;
};

/**
 * Checks if all elements in the given data array have an x value between 0.2 and 0.8.
 *
 * @param data - Array of landmarks, each containing x, y, z coordinates and an optional visibility value.
 * @returns True if all data points have an x value between 0.2 and 0.8. Otherwise, returns false.
 */
const checkXValue = (data: NormalizedLandmark[]): boolean => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].x < 0.2 || data[i].x > 0.8) {
      return false;
    }
  }
  return true;
};

export { checkVisibility, checkXValue };
