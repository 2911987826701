import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';

interface FullScreenVideoPlayerProps {
    isOpen: boolean;
    handleClose: () => void;
    video: string;
}

const FullScreenVideoPlayer = ({
    isOpen,
    handleClose,
    video,
}: FullScreenVideoPlayerProps) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const videoPlayerRoot = document.getElementById('video-player');
    const [progress, setProgress] = useState(0);
    const [showIcon, setShowIcon] = useState(true);

    const togglePlayPause = () => {
        setIsPlaying(prev => !prev);
        setShowIcon(true);

        // Hide icon after a short delay
        setTimeout(() => setShowIcon(false), 500);
    };

    const handleProgress = (state: OnProgressProps) => {
        setProgress(state.played);
    };

    if(!isOpen) return null;

    return videoPlayerRoot ? ReactDOM.createPortal(
        <div onClick={togglePlayPause} className='fixed top-0 left-0 right-0 bottom-0 bg-black z-[100]'>
            <div className='absolute top-0 left-0 right-0 bg-slate-200 bg-opacity-50 h-2'></div>
            <div
                className='absolute top-0 left-0 bg-[#F9CA54] bg-opacity-80 h-2'
                style={{ width: `${progress * 100}%` }}
            ></div>
            <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }}
                playing={isPlaying}
                controls={false}
                // className='showcase-video-thumbnail'
                onEnded={handleClose}
                onProgress={handleProgress}
            />
        </div>,
        videoPlayerRoot
    ) : null;
}

export default FullScreenVideoPlayer;
