import ApiResponse from '../models/api/api-response';
import { ParentDetailPostData } from '../models/parent-detail/parent-detail-post-data';
import { AuthResponse } from '../models/user/auth-response';
import { User } from '../models/user/user';
import { UserData } from '../models/user/user-data';
import { UserRolesListWrapper } from '../models/user/user-role-list-wrapper';
import { UsersListWrapper } from '../models/user/users-list-wrapper';
import { UsersPostDataWrapper } from '../models/user/users-post-data-wrapper';
import {invokeApi} from '../utils/helpers/invoke-api';

type LoginData = {
    identifier: string;
    password: string;
};

export class LoginService {
    private static _instance: LoginService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new LoginService());
    }

    public userLogin = async (
        email: string,
        password: string,
    ): Promise<ApiResponse<AuthResponse>> => {
        return invokeApi<LoginData, AuthResponse>({
            route: `auth/local`,
            method: 'POST',
            data: {
                identifier: email,
                password,
            },
        });
    };

    public getUserRole = async (id: number) => {
        return invokeApi<null, User>({
            route: `users/${id}?populate=role&field[0]=role&fields[1]=id`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postUserData = async ( data: UsersPostDataWrapper ) => {
        return invokeApi<UsersPostDataWrapper, User>({
            route: `users`,
            method: 'POST',
            privateRoute: false,
            data: data,
        })
    }

    public postParentDetails = async ( data: ParentDetailPostData ) => {
        return invokeApi<{data: ParentDetailPostData}, User>({
            route: `parent-details`,
            method: 'POST',
            privateRoute: false,
            data: {data},
        })
    }

    public checkForgotPasswordEmail = async (
        data: {email: string},
    ): Promise<ApiResponse<{ok: string}>> => {
        return invokeApi<{email: string}, {ok: string}>({
            route: `auth/forgot-password`,
            method: 'POST',
            data: data,
        });
    };

    public changePassword = async (
        data: {
            code: string;
            password: string;
            passwordConfirmation: string;
        },
    ): Promise<ApiResponse<null>> => {
        return invokeApi<{
            code: string;
            password: string;
            passwordConfirmation: string;
        }, null>({
            route: `auth/reset-password`,
            method: 'POST',
            data: data,
        });
    };

    public getUserRoles = async (): Promise<ApiResponse<UserRolesListWrapper>> => {
        return invokeApi<null, UserRolesListWrapper>({
            route: `/users-permissions/roles`,
            method: 'GET',
            privateRoute: false,
        });
    }

    public getUserNameAndEmailsList = async ():Promise<ApiResponse<{id: number, username: string, email: string}[]>> => {
        return invokeApi<null, {id: number, username: string, email: string}[]>({
            route: `users?fields[0]=username&fields[1]=email`,
            method: 'GET',
            privateRoute: true,
        });
    }

    
}
