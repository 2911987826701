import ApiResponse from "../models/api/api-response";
import { ClassListWrapper } from "../models/class/class-list-wrapper";
import { DisciplineListWrapper } from "../models/discipline/discipline-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class DisciplineService {
    private static _instance: DisciplineService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new DisciplineService());
    }

    public getFeaturedDisciplines = async (): Promise<ApiResponse<DisciplineListWrapper>> => {
        return invokeApi<null, DisciplineListWrapper>({
            route: `disciplines?filters[isFeatured]=true`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public getGlobalSearchDisciplines = async (term: string): Promise<ApiResponse<DisciplineListWrapper>> => {
        return invokeApi<null, DisciplineListWrapper>({
            route: `disciplines?filters[name][$contains]=${term}`,
            method: 'GET',
            privateRoute: true,
        });
    }
}