import React, { useEffect, useState } from 'react';
import { LoginService } from '../../services/login-service';
import { useMutation, useQuery } from 'react-query';
import { ALL_USERS_LIST } from '../../utils/constants/globals';

const ForgotPasswordViewModel = () => {
  

   const [email,setEmail] = useState('')
   const [isLinkSent,setIsLinkSent] = useState(false)

   const {
        isSuccess: isAllUsersFetched,
        isLoading: isAllUsersFetching,
        data: allUsers,
    } = useQuery({
        queryKey: [ALL_USERS_LIST],
        queryFn: () => LoginService.instance.getUserNameAndEmailsList(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const postForgotPasswordEmailPosting = async (
        data: {email : string},
    ) => {
        try {
            const response = await LoginService.instance.checkForgotPasswordEmail(data);
            if (response.success) {
                setIsLinkSent(true)
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postAddAcademy: ${error}`);
            alert('Failed to process the request! please try again.')
            throw error;
        }
    };

    const {
        mutate: postForgotPasswordEmailData,
        isLoading: ispostForgotPasswordEmailLoading,
        error: ispostForgotPasswordEmailError,
    } = useMutation(postForgotPasswordEmailPosting,{});

    const handleResetPassword = (e: React.FormEvent) => {
        e.preventDefault();
        postForgotPasswordEmailData({ email: email });
    };

    return {
        isLoading: false,
        handleResetPassword,
        email,
        setEmail,
        isLinkSent,
        isValidEmail: allUsers?.data?.map(user => user.email).includes(email)
    };
}

export default ForgotPasswordViewModel