import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ShowcaseVideoPageViewModel = () => {
    const location = useLocation();
    const { url } = location.state || {};
    const navigate = useNavigate();

    const [videoUrl, setVideoUrl] = useState(url);

    const handleCloseShowcaseVideo = () => {
        navigate(-1);
    }

    return {
        videoUrl,
        handleCloseShowcaseVideo,
    }
}

export default ShowcaseVideoPageViewModel