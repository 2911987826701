import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../utils/redux/store";
import { useSelector } from "react-redux";
import { Routes } from "../navigation/routes";
import { VirtualClassesService } from "../../services/virtual-classes-service";
import { useQuery } from "react-query";
import { ACTIVE_VIRTUAL_CLASS } from "../../utils/constants/globals";

const VirtualClassRoomViewModel = () => {
    const {id} = useParams<{id: string}>();
    const profile = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();

    const handleEndMeeting = () => {
        navigate(Routes.virtualClasses)
    }

    const {
        isLoading: isActiveVirtualClassesOfStudentFetching,
        data: activeVirtualClassesOfStudent,
    } = useQuery({
        queryKey: [ACTIVE_VIRTUAL_CLASS, id],
        queryFn: () => VirtualClassesService.instance.getActiveMeetingById(Number(id)),
        refetchOnWindowFocus: true,
        enabled: (profile?.activeStudent?.id ?? 0) > 0,
        refetchInterval: 30000,
    });

    return {
        isLoading: isActiveVirtualClassesOfStudentFetching,
        profile,
        handleEndMeeting,
        activeVirtualClassesOfStudent: activeVirtualClassesOfStudent?.data?.data,
    }
}

export default VirtualClassRoomViewModel