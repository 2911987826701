type Pose = [number, number, number][];
type Joint = { join1: number, join2: number, weightage: number };
type KeyframeCheckItem = { frame: number, data: Joint[] };

const default_joints: Joint[] = [
    {
        "join1": 11, // mp.solutions.pose.PoseLandmark.LEFT_SHOULDER.value
        "join2": 13, // mp.solutions.pose.PoseLandmark.LEFT_ELBOW.value
        "weightage": 1,
    },
    {
        "join1": 13, // mp.solutions.pose.PoseLandmark.LEFT_ELBOW.value
        "join2": 15, // mp.solutions.pose.PoseLandmark.LEFT_WRIST.value
        "weightage": 1,
    },
    {
        "join1": 23, // mp.solutions.pose.PoseLandmark.LEFT_HIP.value
        "join2": 25, // mp.solutions.pose.PoseLandmark.LEFT_KNEE.value
        "weightage": 1,
    },
    {
        "join1": 25, // mp.solutions.pose.PoseLandmark.LEFT_KNEE.value
        "join2": 27, // mp.solutions.pose.PoseLandmark.LEFT_ANKLE.value
        "weightage": 1,
    },
    {
        "join1": 12, // mp.solutions.pose.PoseLandmark.RIGHT_SHOULDER.value
        "join2": 14, // mp.solutions.pose.PoseLandmark.RIGHT_ELBOW.value
        "weightage": 1,
    },
    {
        "join1": 14, // mp.solutions.pose.PoseLandmark.RIGHT_ELBOW.value
        "join2": 16, // mp.solutions.pose.PoseLandmark.RIGHT_WRIST.value
        "weightage": 1,
    },
    {
        "join1": 24, // mp.solutions.pose.PoseLandmark.RIGHT_HIP.value
        "join2": 26, // mp.solutions.pose.PoseLandmark.RIGHT_KNEE.value
        "weightage": 1,
    },
    {
        "join1": 26, // mp.solutions.pose.PoseLandmark.RIGHT_KNEE.value
        "join2": 28, // mp.solutions.pose.PoseLandmark.RIGHT_ANKLE.value
        "weightage": 1,
    },
];

function calculate_vector(point1: [number, number, number], point2: [number, number, number]): [number, number, number] {
    if (point1.length !== 3 || point2.length !== 3) {
        throw new Error('Points must be in [x, y, z] format.');
    }

    const vector: [number, number, number] = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
        vector[i] = point2[i] - point1[i];
    }
    return vector;
}

function dotProduct(v1: [number, number, number], v2: [number, number, number]): number {
    let result = 0;
    for (let i = 0; i < 3; i++) {
        result += v1[i] * v2[i];
    }
    return result;
}

function norm(vector: [number, number, number]): number {
    let sumOfSquares = 0;
    for (let i = 0; i < 3; i++) {
        sumOfSquares += vector[i] * vector[i];
    }
    return Math.sqrt(sumOfSquares);
}

function cosine_similarity(v1: [number, number, number], v2: [number, number, number]): number {
    const dot = dotProduct(v1, v2);
    const normV1 = norm(v1);
    const normV2 = norm(v2);

    return dot / (normV1 * normV2);
}

function compute_similarity_vector(
    prev_pose: Pose,
    current_pose: Pose,
    keyframeCheck: KeyframeCheckItem[],
    currentKeyframe: number
): number {
    let default_joints2: Joint[] = [...default_joints];
    let keyFramesHereIdData = keyframeCheck.find(item => item.frame === currentKeyframe)?.data || [];
    let matchedSet = new Set<string>();

    // Iterate over defaultJoints and update weightage if match found
    for (let defaultJoint of default_joints2) {
        for (let anotherJoint of keyFramesHereIdData) {
            let defaultJoin1 = String(defaultJoint.join1);
            let defaultJoin2 = String(defaultJoint.join2);
            let anotherJoin1 = String(anotherJoint.join1);
            let anotherJoin2 = String(anotherJoint.join2);

            if (defaultJoin1 === anotherJoin1 && defaultJoin2 === anotherJoin2) {
                defaultJoint.weightage = anotherJoint.weightage;
                matchedSet.add(`${anotherJoin1},${anotherJoin2}`);
                break;
            }
        }
    }
    
    // Append unmatched elements from keyFramesHereIdData to defaultJoints
    for (let anotherJoint of keyFramesHereIdData) {
        let anotherJoin1 = String(anotherJoint.join1);
        let anotherJoin2 = String(anotherJoint.join2);

        if (!matchedSet.has(`${anotherJoin1},${anotherJoin2}`)) {
            default_joints2.push(anotherJoint);
        }
    }

    // console.log(default_joints2);
    try {
        let similarityRaw = 0;
        let normFactor = 0;

        for (let i of default_joints2) {
            let prevPoseJoin1 = prev_pose[i.join1];
            let prevPoseJoin2 = prev_pose[i.join2];
            let weight = Number(i.weightage);

            let currentPoseJoin1 = current_pose[i.join1];
            let currentPoseJoin2 = current_pose[i.join2];

            let prevPoseJ1J2 = calculate_vector(prevPoseJoin1, prevPoseJoin2);
            let currentPoseJ1J2 = calculate_vector(currentPoseJoin1, currentPoseJoin2);

            similarityRaw += cosine_similarity(prevPoseJ1J2, currentPoseJ1J2) * weight;
            normFactor += weight;
        }

        let similarity = similarityRaw / normFactor;

        return Math.abs(similarity);
    } catch (error) {
        console.error(error);
        return 0;
    }
}

export { compute_similarity_vector };