import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Scholarship = () => {

  const navigate = useNavigate();

  const images = [
    { src: "/assets/images/cricket.png", alt: "Cricket" },
    { src: "/assets/images/dancer.png", alt: "Dancer" },
    { src: "/assets/images/basketball.png", alt: "Basketball" },
    { src: "/assets/images/painter.png", alt: "Painter" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

    return (
<div className="relative bg-black text-center text-white min-h-screen w-full flex flex-col items-center px-4 md:px-10">
  <div className="absolute top-0 left-0 w-full h-full overflow-hidden">

          {/* Mobile view */}

          <div className="sm:hidden relative h-screen w-full flex items-center justify-center bg-black">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          className={`absolute object-cover transition-all duration-700 ${
            index === currentIndex ? "opacity-40 scale-100" : "opacity-0 scale-75"
          }`}
        />
      ))}
    </div>

      <div className="absolute sm:top-20 sm:left-5 md:top-16 md:left-10 opacity-40 sm:opacity-60 sm:w-[20%] sm:h-[30%] h-full w-full">
      <img
        src="/assets/images/dancer.png"
        alt="Dancer"
              className="hidden sm:flex animate-popup1 h-full w-full"
      />
     </div>
    <div className="absolute sm:bottom-16 sm:left-5 md:left-10 opacity-40 sm:opacity-60 flex sm:w-[20%] sm:h-[25%] h-full w-full">
      <img
        src="/assets/images/cricket.png"
        alt="Cricket"
              className="hidden sm:flex relative sm:left-[-10%] animate-popup2 h-full w-full"
              // style={{animationDelay: "1s"}}
      />
    </div>
    <div className="absolute sm:relative sm:left-[75%] sm:top-20 sm:right-5 md:top-16 md:right-10 opacity-60 sm:w-[20%] sm:h-[30%] h-full w-full">
      <img
        src="/assets/images/basketball.png"
        alt="Player"
              className="hidden h-full w-full sm:flex animate-popup3"
              // style={{animationDelay: "2s"}}
      />
    </div>
    <div className="absolute sm:bottom-16 sm:right-1 md:bottom-10 md:right-10 opacity-60 sm:w-[20%] sm:h-[25%] w-full h-full">
      <img
        src="/assets/images/painter.png"
        alt="Painter"
              className="hidden animate-popup4 sm:flex h-full w-full"
              // style={{animationDelay: "3s"}}
      />
    </div>
  </div>

  {/* Animated Money GIF */}
  {/* <img
    className="absolute opacity-80 w-3/4 md:w-auto"
    src="https://video-public.canva.com/VADlsxWEKFQ/videos/c1ff02fb1c.gif"
    draggable="false"
    alt="Money Animation for Presentations"
  /> */}
  <div className="relative max-w-full text-center px-4 md:max-w-4xl flex flex-col items-center justify-evenly h-screen z-10">
    <h2 className="text-4xl md:text-7xl font-bold md:mt-32 leading-[2.5rem] md:leading-[7rem] text-[#fde0a0]">
      YOUR CHILD PRODIGY CAN WIN UPTO
    </h2>
    <h3 className="text-6xl md:text-9xl font-bold mt-4 text-[#ff6a00]">
      Rs.1,00,000
          </h3>
          <div className="bg-white flex flex-col justify-center items-center sm:w-[70%] w-full">
            <div className="flex items-center justify-center">
            <img className="w-[50%] sm:w-[40%] h-[79%] text-7xl" src="/assets/images/logo.svg"></img>
            <span className="text-[#1d5175] md:text-[3.5rem] text-[1.6rem] mt-2 sm:mt-4 font-bold">Scholarships</span>
            </div>
            <img src="https://media-public.canva.com/VUgwo/MAEXKHVUgwo/1/t.png"/>
          </div>
    <button onClick={() => navigate('/scholarship')} className="mt-6 md:mt-10 bg-orange-500 hover:bg-orange-600 text-white text-2xl px-4 py-2 md:px-6 md:py-3 w-48 h-16">
      Know More
    </button>
  </div>
</div>
)
}

export default Scholarship;