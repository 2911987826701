import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';

import { useNavigate } from 'react-router-dom';

const AttendancePageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const student = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();
    const dispatch = useDispatch();

  return {
    isLoading: false,
    student,
  }
}

export default AttendancePageViewModel
