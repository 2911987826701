import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BlogService } from '../../services/blog-services';
import { useQuery, useQueryClient } from 'react-query';
import { BLOGS_POST_QUERY } from '../../utils/constants/globals';
import { RootState } from '../../utils/redux/store';
import { useSelector } from 'react-redux';
import { Comment, CommentReply, DeleteCommentData, EditedComment } from '../../models/blog/blog-attributes';
import { InterestsPostData } from '../../models/blog/interests-post-data';
import { BlogData } from '../../models/blog/blog-data';
import { Routes } from '../navigation/routes';

const BlogPageViewModel = () => {
    const { blogUrl } = useParams();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [blogIndex, setBlogIndex] = useState(-1);

    const {
        isLoading: isBlogPostFetching,
        data: blogPost,
    } = useQuery({
        queryKey: [BLOGS_POST_QUERY, blogUrl],
        queryFn: () => BlogService.instance.getBlogPost(blogUrl || ''),
        refetchOnWindowFocus: false,
        enabled: blogUrl != ''
    });

    const [blogId, setBlogId] = useState(blogPost?.data?.data[0]?.id);   

    useEffect(() => {
        if (blogPost?.data?.data[0]?.id) {
            setBlogId(blogPost.data?.data[0]?.id);
        }
    }, [blogPost])
    
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);

    useEffect(() => {
    if (blogId) {
        const storedLiked = localStorage.getItem(`isLiked-${blogId}`);
        setIsLiked(storedLiked ? JSON.parse(storedLiked) : false);

        const storedDisliked = localStorage.getItem(`isDisliked-${blogId}`);
        setIsDisliked(storedDisliked ? JSON.parse(storedDisliked) : false);
    }
    }, [blogId]);

    const [openPopup, setOpenPopup] = useState(false);
    const initialInterestFormData = { name: '', contact: '', interests: '', blog: Number(blogId) };
    const [interestFormData, setInterestFormData] = useState(initialInterestFormData);
    useEffect(() => {
    setInterestFormData((prev) => ({
        ...prev,
        blog: Number(blogId),
    }));
    }, [blogId]);
    const [isPostInterestSuccess, setIsPostInterestSuccess] = useState(false);

    // Update localStorage whenever the state changes
    useEffect(() => {
        if (blogId) {
            localStorage.setItem(`isLiked-${blogId}`, JSON.stringify(isLiked));
        }
    }, [isLiked, blogId]);

    useEffect(() => {
    if (blogId) {
        localStorage.setItem(`isDisliked-${blogId}`, JSON.stringify(isDisliked));
    }
    }  , [isDisliked, blogId]);

    useEffect(() => {
        const id = document.getElementById('interest-btn');
        id?.addEventListener('click', () => setOpenPopup(true));
        return () => document.removeEventListener('click', () => setOpenPopup(false));
    })

    const handleLike = async() => {
        if(isLiked){
            await BlogService.instance.undoBlogLike(Number(blogId) ?? 0)
        }else{
            await BlogService.instance.blogLike(Number(blogId) ?? 0)
        }
        if (isDisliked){
            await BlogService.instance.undoBlogDislike(Number(blogId) ?? 0)
            setIsDisliked(false);
        }
        setIsLiked(!isLiked);
    };

    const handleDislike = async() => {
        if(isDisliked){
            await BlogService.instance.undoBlogDislike(Number(blogId) ?? 0)
        }else{
            await BlogService.instance.blogDislike(Number(blogId) ?? 0)
        }
        if (isLiked) {
            await BlogService.instance.undoBlogLike(Number(blogId) ?? 0)
            setIsLiked(false);
        }
        setIsDisliked(!isDisliked);
    };

    const [blogComments, setBlogComments] = useState<Comment[]>([])


    
    useEffect(() => {
        if(blogPost?.data?.data[0]?.attributes?.comments){
            setBlogComments(blogPost?.data?.data[0]?.attributes?.comments)
        }
    },[blogPost?.data?.data[0]?.attributes?.comments])

    const handleAddNewComment = async (data: Comment) => {
        await BlogService.instance.postBlogComment(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
                alert(err);
            })
    }

    const handleAddNewCommentReply = async(data: CommentReply) => {
        await BlogService.instance.postBlogCommentReply(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleEditComment = async(data: EditedComment) => {
        await BlogService.instance.updateBlogComment(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteComment = async(data: DeleteCommentData) => {
        await BlogService.instance.deleteBlogComment(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSubmitInterests = async (data: InterestsPostData) => {
        setOpenPopup(false);
        setInterestFormData(initialInterestFormData);
        await BlogService.instance.postInterests(data)
            .then(res => {
            if (res.success) {
                console.log('Interest saved success');
                setIsPostInterestSuccess(true);
            }
            setIsPostInterestSuccess(true);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleBlogIndex = (blogs: BlogData[], blogId: number | undefined, action: string) => {
        const index = blogs.findIndex(blog => blog.id === blogId);
        if (action === 'next' && index < blogs.length - 1) {
            navigate(Routes.blog + `/${blogs[index+1]?.attributes?.url}`, {replace: false});
        } if (action === 'prev' && index > 0) {
            navigate(Routes.blog + `/${blogs[index-1]?.attributes?.url}`, {replace: false});
        }
    }

    return {
        isLoading: isBlogPostFetching,
        blogPost: blogPost?.data?.data[0],
        user,
        navigate,
        isLiked,
        isDisliked,
        handleLike,
        handleDislike,
        blogComments,
        handleAddNewComment,
        handleAddNewCommentReply,
        handleEditComment,
        handleDeleteComment,
        openPopup,
        setOpenPopup,
        interestFormData,
        setInterestFormData,
        handleSubmitInterests,
        isPostInterestSuccess,
        setIsPostInterestSuccess,
        handleBlogIndex,
        blogIndex
    }
}

export default BlogPageViewModel