import React from 'react'
import VirtualClassRoomViewModel from '../../view-models/virtual-class-rool-viewmodel';
import { JitsiMeeting } from '@jitsi/react-sdk';

const InlineSpinner = () => (
    <div className="w-screen h-screen flex items-center justify-center">
        <span className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></span>
    </div>
);

const VirtualClassRoom = () => {
    const {
        isLoading,
        profile,
        handleEndMeeting,
        activeVirtualClassesOfStudent,
    } = VirtualClassRoomViewModel();

    return (
        <div>
            <JitsiMeeting
                domain = { 'virtual-class.sparts.app' }
                roomName = {activeVirtualClassesOfStudent?.attributes?.meetingName.replace(/[-:.]/g, '') ?? 'NOT FOUND'}
                userInfo={{
                    displayName: `${profile?.activeStudent?.attributes?.firstName} ${profile?.activeStudent?.attributes?.lastName ?? ''}`,
                    email: profile.parentUserDetails?.attributes?.userInfo?.data?.attributes?.email,
                }}
                configOverwrite = {{
                    disableThirdPartyRequests: true,
                    disableLocalVideoFlip: true,
                    backgroundAlpha: 0.5,
                    disableInviteFunctions: true,
                    disableDeepLinking: true,
                    startWithAudioMuted: true,
                    startWithVideoMuted: true,
                    enableNoAudioDetection: false,
                    enableNoisyMicDetection: false,
                    disableModeratorIndicator: true,
                    disableAudioLevels: true,
                    disableProfile: true,
                    disableRemoteMute: true,
                    welcomePage: {
                        disabled: true,
                    },
                    toolbarButtons: [
                        'camera',
                        'chat',
                        'closedcaptions',
                        'desktop',
                        'download',
                        'embedmeeting',
                        'etherpad',
                        'feedback',
                        'filmstrip',
                        'fullscreen',
                        'hangup',
                        'help',
                        'highlight',
                        // 'invite',
                        // 'linktosalesforce',
                        'livestreaming',
                        'microphone',
                        'noisesuppression',
                        'participants-pane',
                        'profile',
                        'raisehand',
                        'recording',
                        'security',
                        'select-background',
                        // 'settings',
                        'shareaudio',
                        'sharedvideo',
                        'shortcuts',
                        'stats',
                        'tileview',
                        'toggle-camera',
                        'videoquality',
                        'whiteboard',
                    ]
                }}
                interfaceConfigOverwrite = {{
                    APP_NAME: 'Sparts Virtual Classes',
                    PROVIDER_NAME: 'Sparts',
                    VIDEO_LAYOUT_FIT: 'nocrop',
                    MOBILE_APP_PROMO: false,
                    TILE_VIEW_MAX_COLUMNS: 4,
                }}
                getIFrameRef = { (iframeRef) => { iframeRef.style.height = '100vh'; } }
                spinner = {InlineSpinner}
                onReadyToClose={handleEndMeeting}
                // onApiReady = { (externalApi) => { ... } }
            />
        </div>
    )
}

export default VirtualClassRoom