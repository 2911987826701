export const NoClassInfoTexts: {title: string,subTitle: string}[] = [
    {
        title: 'No Classes Found! Time to Channel Your Inner Detective 🕵️‍♂️',
        subTitle: 'Looks like your student is currently enrolled in the "School of Unstructured Time." 🤷‍♂️ Time to crack the case and enroll them in a real class! Click below to unlock their academic potential.'
    },
    {
        title: 'No Classes Found! Are We on Summer Break Already? 🌞📚',
        subTitle: 'Seems like your student is enjoying a little too much "Extra-Curricular Napping." Time to wake them up and enroll them in a class! Click below to bring back the school vibes.'
    },
    {
        title: 'Oops! Your Student\'s Schedule is Lighter Than a Feather 🕊️📆',
        subTitle: 'Looks like they\'re enrolled in the "Art of Snoozing 101." Let\'s add some real subjects to their timetable! Click below to refresh their class schedule.'
    },
    {
        title: 'No Classes Found! Looks Like Someone\'s Mastering the Art of Free Time 🎨⏰',
        subTitle: 'Seems like your student is embracing their inner "Chief Relaxation Officer." Let\'s swap relaxation for education! Click below to get them back on track.'
    }
];