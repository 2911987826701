import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { NOTIFICATION_LIST_QUERY, STUDENTS_CLASSES_QUERY } from '../../utils/constants/globals';
import { NotificationService } from '../../services/notification-service';


const NotificationViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const parent = useSelector((state: RootState) => state.activeProfile);
    const allClassId = parent.allClassesOfProfile.map(item => item.id);
    const allStudentId = parent.allStudentsData.map(item => item.id);
    const allUsersId = parent.allStudentsData.flatMap(
        student => student?.attributes?.users?.data?.map(user => user.id) || [],
    );

    const {
        isSuccess: isNotificationsFetchSuccess,
        isLoading: isNotificationsFetching,
        data: notificationsData,
    } = useQuery({
        queryKey: [NOTIFICATION_LIST_QUERY],
        queryFn: () => NotificationService.instance.getAllNotificationsOfParent(allStudentId),
        refetchOnWindowFocus: false,
        enabled: true,
    });
    
    // console.log(notificationsData?.data?.data);

     const [isExpanded, setIsExpanded] = useState<number>(-1);
     const handleViewClick = (id: number) => {
         if (isExpanded === id) {
             setIsExpanded(-1);
         } else {
             setIsExpanded(id);
         }
     };
  return {
    isLoading: false,
    notificationsData: [...new Set(notificationsData?.data?.data)],
    isExpanded,
    handleViewClick,
    
  }
}

export default NotificationViewModel
