import React from 'react'
import ForgotPasswordViewModel from '../../view-models/forgot-password-viewmodel'
import FullScreenLoader from '../../components/common/full-sreen-loader';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';

const ForgotPasswordPage = () => {
    const {
        isLoading,
        handleResetPassword,
        email,
        setEmail,
        isLinkSent,
        isValidEmail
    } = ForgotPasswordViewModel();

    const navgate = useNavigate();

    return (
        <div className="sm:flex w-full">
            {isLoading && <FullScreenLoader />}
            <div className="sm:block hidden bg-[#3AB7FC] w-[40%] h-svh"></div>
            <div className="bg-white sm:w-[60%] w-full h-svh">
                <div className="w-full sm:h-[15%] h-[10%] bg-[#F6B40A]">
                    <div className="w-full h-[35%]"></div>
                    <div className="flex justify-center w-full h-[50%]">
                        <img
                            className=" "
                            src="/assets/images/logo.svg"
                            alt="link"
                        />
                    </div>
                </div>
                <div className='flex h-[calc(100vh-128px)] items-center justify-center p-4'>
                    {isLinkSent ? 
                        <div className="container mx-auto">
                            <div className="flex justify-center">
                                <div className="w-full md:w-1/2">
                                    <div className="bg-white flex flex-col gap-4 items-center justify-center shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                        <div className='text-lg font-semibold text-center'>Link to reset password has been sent to {email} successfully!</div>
                                        <div onClick={() => navgate(Routes.Login)} className='w-[50%] cursor-pointer text-center bg-[#f4b921] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                                            Login
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="container mx-auto">
                            <div className="flex justify-center">
                                <div className="w-full md:w-1/2">
                                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                        <div className="text-center">
                                            <h2 className="text-center text-xl font-bold mb-2">Forgot Password?</h2>
                                            <p className="text-sm mb-4">You can reset your password here.</p>
                                        </div>
                                        <div className="mb-4">
                                            <form id="register-form" role="form" onSubmit={handleResetPassword} autoComplete="off" className="form">
                                                <div className="mb-4">
                                                    <div className="flex items-center bg-gray-200 rounded-lg">
                                                        <span className="inline-block py-2 px-3 text-gray-700">
                                                            <img 
                                                                src='/assets/images/email.png'
                                                            />
                                                        </span>
                                                        <input 
                                                            onChange={e => {
                                                                setEmail(e.target.value)
                                                            }}
                                                            value={email} 
                                                            id="email" 
                                                            name="email" 
                                                            required
                                                            placeholder="Email address" 
                                                            className="w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                                            type="email"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-6">
                                                    <input name="recover-submit" disabled={!isValidEmail} className="w-full bg-blue-500 hover:bg-blue-700 disabled:opacity-30 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                                    value={isValidEmail ? "Reset Password" : 'Email not registered' }
                                                    type="submit"
                                                />
                                                </div>
                                                <div onClick={() => navgate(Routes.Login)} className='w-full text-center bg-[#f4b921] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                                                    Go back
                                                </div>
                                                <input type="hidden" className="hidden" name="token" id="token" value="" /> 
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage