const synth: SpeechSynthesis = window.speechSynthesis;

/**
 * Converts text to speech using the browser's built-in speech synthesis API.
 * @param {string} text - The text to be converted to speech.
 * @param {number} [speed=0.75] - The speed of speech. Defaults to 0.75.
 */
function t2s(text: string, speed: number = 0.35, pitch = 1.2): void {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = speed;
    utterance.voice = synth.getVoices()[4];
    utterance.pitch = pitch;
    synth.speak(utterance);
}

function stopSpeech() {
    synth.cancel();
}

export { t2s, stopSpeech };